import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'luxon';
import 'chartjs-adapter-luxon';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import Annotation from 'chartjs-plugin-annotation';
import { debounce } from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  Annotation
);

export const PowerChart = ({
  dataSource,
  chartRef,
  updateTouState,
  minXState,
  maxXState,
  annotations,
  style,
  perspective,
  meterIds = false,
  siteId = false,
  timezone,
  energyStream = false,
  showActual = true,
  showProjected = false
}) => {
  const [updateTOU, setUpdateTOU] = updateTouState;
  const [minX, setMinX] = minXState;
  const [maxX, setMaxX] = maxXState;
  const [solarKw, setSolarKw] = useState([]);
  const [batteryKw, setBatteryKw] = useState([]);
  const [gridKw, setGridKw] = useState([]);
  const [combinedLoadKw, setCombinedLoadKw] = useState([]);
  const [batteryDist, setBatteryDist] = useState([]);
  const [dischargeTargets, setDischargeTargets] = useState([]);
  const [dischargeTargetsFuture, setDischargeTargetsFuture] = useState([]);
  const [chargeTargets, setChargeTargets] = useState([]);
  const [chargeTargetsFuture, setChargeTargetsFuture] = useState([]);
  const [forecastPcc, setForecastPcc] = useState([]);
  const [forecastSolar, setForecastSolar] = useState([]);
  const [forecastSoc, setForecastSoc] = useState([]);
  const [batterySoc, setBatterySoc] = useState([]);
  let maxPowerChartSize = 5259600000; // two months in milliseconds

  // chart to end of forecast
  const setMaxXinit = (data) => {
    let load = data['load_kw'];
    let x = load[load.length - 1]['x'];
    setMaxX(x);
  };

  // const findMax = (item, max) => {
  //   if (item['y'] > max) {
  //     return item['y'];
  //   }
  //   return max;
  // };

  const getSuggestedMax = () => {
    return 0.05;
  };

  useEffect(() => {
    const start = new Date();
    if (!energyStream) {
      dataSource.fetchForecast(perspective, start, null, setMaxXinit);
    }
  }, []);

  const responseHandler = (data) => {
    setSolarKw(data['solar']);
    setGridKw(data['pcc']);
    setBatteryKw(data['battery']);
    if (!energyStream) {
      setBatterySoc(data['battery_soc']);
      setDischargeTargets(data['discharge_target']);
      setChargeTargets(data['charge_target']);
    } else {
      setCombinedLoadKw(data['combined_load']);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const batterySOCHandler = (data) => {
    setBatterySoc(data);
  };

  const forecastHandler = (data) => {
    let now = new Date();
    setBatteryDist(data['battery_dist_kw']);
    setForecastPcc(data['new_pcc_kw_customer_view']);
    setForecastSolar(data['forecast_solar_kw']);
    setForecastSoc(data['forecast_soc']);

    // chain the power targets to get end date
    const DISCHARGE_TARGET_MODE = 2;
    const CHARGE_TARGET_MODE = 3;
    let start = now;
    let load = data['load_kw'];
    if (load.length > 0) {
      let x = load[load.length - 1]['x'];
      let end = new Date(x);
      dataSource.fetchTargets(
        powerTargetsHandler,
        start.toISOString(),
        end.toISOString(),
        DISCHARGE_TARGET_MODE
      );
      dataSource.fetchTargets(
        powerTargetsHandler,
        start.toISOString(),
        end.toISOString(),
        CHARGE_TARGET_MODE
      );
    }
  };

  const powerTargetsHandler = (powerTargets, mode) => {
    // eslint-disable-next-line no-unused-vars
    let [label, points] = Object.entries(powerTargets)[0];

    if (mode == 2) {
      setDischargeTargetsFuture(points);
    } else {
      setChargeTargetsFuture(points);
    }
  };

  const fetchData = () => {
    if (maxX.getTime() - minX.getTime() < maxPowerChartSize) {
      if (siteId) {
        dataSource.fetchInstantInfo(
          minX,
          maxX,
          perspective,
          siteId,
          timezone,
          responseHandler
        );
        dataSource.fetchBatterySOC(minX, maxX, siteId, batterySOCHandler);
      } else {
        dataSource.fetchInstantInfo(
          minX,
          maxX,
          perspective,
          meterIds,
          responseHandler
        );
      }
      if (!energyStream) {
        dataSource.fetchForecast(
          perspective,
          new Date(minX),
          new Date(maxX),
          forecastHandler
        );
      }
    }
  };

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchData();
    }, 200);
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [minX, maxX]);

  const options = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    plugins: {
      annotation: {
        annotations: [
          ...annotations.now,
          ...annotations.month,
          ...annotations.touRegions,
          ...annotations.startOfMonth
        ]
      },
      legend: {
        position: 'right',
        padding: 60,
        labels: {
          font: { size: 13 },
          color: style.color
        },
        usePointStyle: true
      },
      customBackgroundColor: {
        color: style.backgroundColor
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          async onPanComplete({ chart }) {
            setMinX(new Date(chart.scales.x.min));
            setMaxX(new Date(chart.scales.x.max));
          }
        },
        zoom: {
          wheel: {
            enabled: true
          },
          mode: 'x',
          async onZoomComplete({ chart }) {
            setMinX(new Date(chart.scales.x.min));
            setMaxX(new Date(chart.scales.x.max));
          }
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              let unit =
                context.dataset.label === 'Battery SOC' ||
                context.dataset.label === 'Projected SOC'
                  ? '%'
                  : ' kW';
              label +=
                new Number(context.parsed.y.toFixed(3)).toLocaleString(
                  'en-US'
                ) + unit;
            }
            return label;
          }
        }
      }
    },
    responsive: true,
    transitions: {
      zoom: {
        animation: { duration: 0 }
      }
    },
    animation: false,
    onResize: () => {
      setTimeout(() => {
        setUpdateTOU(!updateTOU);
      }, 100);
    },
    scales: {
      x: {
        display: 'auto',
        min: minX,
        max: maxX,
        type: 'time',
        time: {
          unit: () =>
            maxX.getTime() - minX.getTime() > 24 * 60 * 60 * 1000
              ? 'day'
              : 'hour',
          displayFormats: {
            day: 'EEE - d',
            hour: 'h a'
          },
          stepSize: 1
        },
        adapters: {
          date: {
            zone: timezone
          }
        },
        grid: {
          display: false,
          color: style.gridColor
        },
        ticks: {
          // https://stackoverflow.com/questions/71733581/how-to-introduce-newline-character-in-xaxes-label-with-chart-js
          callback: (tick) => {
            return tick.includes('-')
              ? tick.split('-')
              : tick == '12 PM'
              ? 'Noon'
              : tick;
          },
          maxTicksLimit: 8,
          font: { size: 12 },
          color: style.color,
          includeBounds: false,
          align: 'center'
        }
      },
      y: {
        display: 'auto',
        grid: {
          display: true,
          color: style.gridColor,
          drawTicks: false
        },
        suggestedMax: getSuggestedMax(),
        position: 'left',
        ticks: {
          callback: (value) => {
            return new Number(value.toFixed(2)).toLocaleString('en-US') + ' kW';
          },
          font: { size: 13 },
          color: style.color
        },
        type: 'linear'
      },
      y2: {
        display: 'auto',
        title: {
          display: true,
          text: 'State of Charge',
          align: 'end',
          color: style.color,
          font: { size: 13 }
        },
        grid: {
          display: false
        },
        position: 'right',
        ticks: {
          callback: (value) => {
            return new Number(value.toFixed(0)).toLocaleString('en-US') + '%';
          },
          font: { size: 13 },
          color: style.color
        },
        beginAtZero: true,
        max: 100,
        type: 'linear'
      }
      //     }
      //   }
      // }
    }
  };

  const getProjected = () => {
    if (!energyStream && showProjected) {
      return [
        {
          label: 'Projected Battery',
          data: batteryDist,
          borderColor: 'rgb(255,105,180)',
          borderWidth: style.borderWidth,
          backgroundColor: 'rgb(255,105,180,0.2)',
          fill: 'origin',
          pointBorderWidth: 0,
          borderDash: [5, 2],
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgb(255,105,180,0.2)',
          pointHoverBorderColor: 'rgb(255,105,180)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 0,
          spanGaps: spanGaps
        },
        {
          label: 'Projected PCC',
          data: forecastPcc,
          borderWidth: style.borderWidth,
          borderColor: 'rgb(192,192,192)',
          backgroundColor: 'rgb(192,192,192,0.2)',
          fill: 'origin',
          pointBorderWidth: 0,
          borderDash: [5, 2],
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: 'rgb(192,192,192)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 0,
          yAxisID: 'y',
          spanGaps: spanGaps
        },
        {
          label: 'Projected Solar',
          data: forecastSolar,
          borderColor: 'rgb(255,255,0)',
          borderWidth: style.borderWidth,
          backgroundColor: 'rgb(255,255,0, 0.5)',
          fill: 'origin',
          pointBorderWidth: 0,
          borderDash: [5, 2],
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgb(255,255,0, 0.5)',
          pointHoverBorderColor: 'rgb(255,255,0)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 0,
          yAxisID: 'y',
          spanGaps: spanGaps
        },
        {
          label: 'Projected SOC',
          data: forecastSoc,
          fill: false,
          borderWidth: style.borderWidth,
          borderColor: 'rgb(225,0,218)',
          backgroundColor: 'rgb(225,0,218,0.2)',
          pointBorderWidth: 0,
          borderDash: [5, 2],
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: 'rgb(225,0,218)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 0,
          yAxisID: 'y2',
          spanGaps: spanGaps
        }
      ];
    } else {
      return [];
    }
  };

  const getTargets = () => {
    if (!energyStream) {
      let dTargets = [];
      let cTargets = [];
      if (showActual && showProjected) {
        dTargets = dischargeTargets.concat(
          dischargeTargetsFuture.filter(
            (entry) =>
              !dischargeTargets
                .map((a) => a.x.getTime())
                .includes(entry['x'].getTime())
          )
        );
        cTargets = chargeTargets.concat(
          chargeTargetsFuture.filter(
            (entry) =>
              !chargeTargets
                .map((a) => a.x.getTime())
                .includes(entry['x'].getTime())
          )
        );
      } else if (showActual) {
        dTargets = dischargeTargets;
        cTargets = chargeTargets;
      } else if (showProjected) {
        dTargets = dischargeTargetsFuture;
        cTargets = chargeTargetsFuture;
      }
      return [
        {
          label: 'Discharge Target',
          data: dTargets,
          borderColor: 'red',
          borderWidth: style.borderWidth,
          backgroundColor: 'rgb(255, 0, 0, 0.2)',
          lineTension: 0.1,
          pointBackgroundColor: 'red',
          pointBorderColor: 'red',
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: 'red',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 0,
          stepped: true,
          spanGaps: spanGaps * 24
        },
        {
          label: 'Charge Target',
          data: cTargets,
          borderColor: 'blue',
          borderWidth: style.borderWidth,
          backgroundColor: 'rgb(0, 0, 255, 0.2)',
          lineTension: 0.1,
          pointBackgroundColor: 'blue',
          pointBorderColor: 'blue',
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: 'blue',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 0,
          stepped: true,
          spanGaps: spanGaps * 24
        }
      ];
    }
    return [];
  };

  const getActual = () => {
    if (showActual) {
      let common_datasets = [
        {
          label: 'Solar',
          data: solarKw,
          borderColor: 'rgb(255,255,0)',
          borderWidth: style.borderWidth,
          backgroundColor: 'rgb(255,255,0, 0.5)',
          fill: 'origin',
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgb(255,255,0, 0.5)',
          pointHoverBorderColor: 'rgb(255,255,0)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 1,
          yAxisID: 'y',
          spanGaps: spanGaps
        },
        {
          label: 'Battery',
          data: batteryKw,
          borderColor: 'rgb(255,105,180)',
          borderWidth: style.borderWidth,
          backgroundColor: 'rgb(255,105,180, 0.5)',
          fill: 'origin',
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'rgb(255,105,180, 0.5)',
          pointHoverBorderColor: 'rgb(255,105,180)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 1,
          yAxisID: 'y',
          spanGaps: spanGaps
        },
        {
          label: 'Grid',
          data: gridKw,
          borderColor: 'rgb(192,192,192)',
          borderWidth: style.borderWidth,
          backgroundColor: 'rgb(192,192,192, 0.5)',
          fill: 'origin',
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: 'rgb(192,192,192)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 1,
          yAxisID: 'y',
          spanGaps: spanGaps
        },
        {
          label: 'Battery SOC',
          data: batterySoc,
          fill: false,
          borderWidth: style.borderWidth,
          borderColor: 'rgb(225,0,218)',
          backgroundColor: 'rgb(225,0,218,0.2)',
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: 'rgb(225,0,218)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 1,
          yAxisID: 'y2',
          spanGaps: spanGaps
        }
      ];
      if (energyStream) {
        return common_datasets.concat({
          label: 'Total Demand',
          data: combinedLoadKw,
          fill: false,
          borderWidth: style.borderWidth,
          borderColor: 'rgb(192,192,192)',
          backgroundColor: 'rgb(192,192,192,0.2)',
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: 'white',
          pointHoverBorderColor: 'rgb(192,192,192)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 1,
          borderDash: [5, 2],
          yAxisID: 'y',
          spanGaps: spanGaps
        });
      } else {
        return common_datasets;
      }
    } else {
      return [];
    }
  };

  const spanGaps = 60 * 60 * 1000; // 1-hour
  const data = {
    datasets: getActual().concat(getProjected()).concat(getTargets())
  };
  return <Line ref={chartRef} options={options} data={data} />;
};

PowerChart.propTypes = {
  dataSource: PropTypes.object.isRequired,
  chartRef: PropTypes.object.isRequired,
  updateTouState: PropTypes.array.isRequired,
  minXState: PropTypes.array.isRequired,
  maxXState: PropTypes.array.isRequired,
  annotations: PropTypes.object,
  style: PropTypes.object.isRequired,
  perspective: PropTypes.number.isRequired,
  meterIds: PropTypes.any,
  siteId: PropTypes.string,
  energyStream: PropTypes.bool,
  timezone: PropTypes.string,
  showActual: PropTypes.bool,
  showProjected: PropTypes.bool
};
