/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React from 'react';
import PropTypes from 'prop-types';

const SocBar = React.memo(function SocBar({
  column_name,
  soc,
  onlineStatus,
  height = 3
}) {
  const warningLowSocVal = 20;
  const veryLowSocVal = 10;
  const green = '#00FF00';
  let socBarBackgroundColor = green;

  // Decide soc bar color depending on soc value.
  if (soc <= warningLowSocVal && soc > veryLowSocVal) {
    socBarBackgroundColor = 'yellow';
  } else if (soc <= veryLowSocVal) {
    socBarBackgroundColor = 'red';
  } else {
    // soc > warningLowSocVal.
    socBarBackgroundColor = green;
  }

  const customSocBarStyle = {
    width: `${Math.min(soc, 100)}%`,
    backgroundColor: `${socBarBackgroundColor}`,
    height: `${height}px`
  };

  return (
    <div
      className={`soc-bar soc soc-${column_name}`}
      title={`${soc}%`}
      style={{ height: `${height}px` }}
    >
      <div
        className={`soc-bar soc-percent ${onlineStatus}`}
        style={customSocBarStyle}
      />
    </div>
  );
});

SocBar.propTypes = {
  column_name: PropTypes.string,
  soc: PropTypes.string,
  onlineStatus: PropTypes.string,
  height: PropTypes.number
};

export default SocBar;
