/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { EssRackContext } from './essRackContext.js';
import SocBar from './socBar.js';
import { BmuCellInfo } from './bmuCellInfo.js';
import { BmuReadingsSelector } from './bmuReadingsSelector.js';
import { StatusCircle } from './statusCircle.js';
import { useInterval } from '../helpers/useInterval.js';
import { isOnline } from '../helpers/isOnline.js';

/* BMU readings from the props are in the following stringified format of this example:
  "readings": {
      "state": "Discharged rtc",
      "current": "0.000",
      "voltage": "0.000",
      "soc": "0.000",
      "soh": "0.000",
      "remaining_capacity": "0.000",
      "full_charged_capacity": "0.000",
      "design_capacity": "0.000",
      "cycle_count": "0",
      "cell_voltages": ["0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000","0.000"],
      "cell_temperatures": ["0.000","0.000","0.000","0.000"],
      "mosfet_temperature": "0.000",
      "environment_temperature": "0.000",
      "timestamp": "2021-06-18 14:34:33",
      "state_reason": "Breached minimum cell voltage hard-limit on BMU 23.  Battery min cell voltage:0.0 <= 3.0 (hard-limit)",
  }
*/

const Bmu = React.memo(function Bmu({
  bmu_id,
  row,
  column_name,
  readings,
  selectedReadingName,
  setSelectedReadingName,
  selectedReadingIndex,
  setSelectedReadingIndex
}) {
  const [onlineStatus, setOnlineStatus] = useState(
    readings ? (isOnline(readings.timestamp) ? 'online' : 'offline') : 'offline'
  );
  const { essConfig } = useContext(EssRackContext);

  // use ref to ensure current timestamp is used rather than stale closure value
  const timestampRef = useRef(readings.timestamp);

  // update offline when we get new BMU readings
  useEffect(() => {
    setOnlineStatus(isOnline(readings.timestamp) ? 'online' : 'offline');
  }, [readings]);

  // check every minute if BMU is online
  useInterval(() => {
    setOnlineStatus(isOnline(timestampRef.current) ? 'online' : 'offline');
  }, 60000);

  const EVE_BMU_CHARGED_RTD_STATE = 'Charged rtd';
  const EVE_BMU_DISCHARGED_RTC_STATE = 'Discharged rtc';
  const EVE_BMU_MAINTENANCE_STATE = 'Maintenance';
  const EVE_BMU_FAULT_STATE = 'Fault';

  // Depending on the BMU's state, return the class that will render the symbol that represents the state of the BMU.
  const getBmuStateIcon = (this_state) => {
    if (this_state != null) {
      // Return a symbol to represent certain different states of the BMU.
      if (this_state === EVE_BMU_CHARGED_RTD_STATE) {
        return 'fa-long-arrow-up';
      } else if (this_state === EVE_BMU_DISCHARGED_RTC_STATE) {
        return 'fa-long-arrow-down';
      } else if (this_state === EVE_BMU_MAINTENANCE_STATE) {
        return 'fa-ban';
      } else if (this_state === EVE_BMU_FAULT_STATE) {
        return 'fa-exclamation-triangle';
      } else {
        return '';
      }
    }
  };

  // Some pixel calculation constants.
  // These are based off of the .ess-rack parent div since the BMUs are relative to the .ess-rack div.
  const columnWidth = 215;
  const bmuLeftOffset = 76;
  const rowHeight = 84;
  const bmuTopOffset = 36;

  // Calculate the bmu position based on bmu colName, row, and total number of rows.
  const bmuLeftPx =
    (column_name.charCodeAt() - 'A'.charCodeAt()) * columnWidth + bmuLeftOffset;
  const bmuTopPx = Math.abs(row - essConfig.numRows) * rowHeight + bmuTopOffset;
  const bmuBlockPosition = {
    left: `${bmuLeftPx}px`,
    top: `${bmuTopPx}px`
  };

  return (
    <div className={`bmu-block ${onlineStatus}`} style={bmuBlockPosition}>
      <div className='bmu-block-inner'>
        <div className={`bmu-dot-container bmu-dot-container-${column_name}`}>
          <StatusCircle
            className={`bmu-status-circle ${onlineStatus}`}
            title={onlineStatus}
          />
        </div>

        <div
          className={`bmu-state bmu-state-${column_name}`}
          title={`State: ${readings.state}`}
        >
          <i
            className={`fa ${getBmuStateIcon(
              readings.state
            )} fa-sm bmu-state-icon-${column_name}`}
            aria-hidden='true'
          />
        </div>

        <BmuCellInfo
          column_name={column_name}
          cell_voltages={readings.cell_voltages}
          onlineStatus={onlineStatus}
          bmu_id={bmu_id}
        />

        <div className={`bmu-block-number bmu-block-number-${column_name}`}>
          {row}
        </div>

        <BmuReadingsSelector
          readings={readings}
          onlineStatus={onlineStatus}
          selectedReadingName={selectedReadingName}
          setSelectedReadingName={setSelectedReadingName}
          selectedReadingIndex={selectedReadingIndex}
          setSelectedReadingIndex={setSelectedReadingIndex}
          column_name={column_name}
        />

        <SocBar
          column_name={column_name}
          soc={readings.soc}
          onlineStatus={onlineStatus}
        />
      </div>
    </div>
  );
});

Bmu.propTypes = {
  bmu_id: PropTypes.string,
  row: PropTypes.string,
  column_name: PropTypes.string,
  readings: PropTypes.object,
  selectedReadingName: PropTypes.string,
  setSelectedReadingName: PropTypes.func,
  selectedReadingIndex: PropTypes.number,
  setSelectedReadingIndex: PropTypes.func
};

export default Bmu;
