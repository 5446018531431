/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HorizontalConnector } from './horizontalConnector.js';
import { VerticalConnector } from './verticalConnector.js';

export function PowerGrid(props) {
  // Some constants for displaying the grid component as off-grid if props.offGrid is true.
  const defaultWireRightX = 2;
  const defaultWireLeftX = -3.5;
  const defaultWireVerticalX = 1;
  const defaultWireHorizontalY = 2;
  const defaultWireBottomY = 3;
  const defaultWireTopY = -2;
  const defaultWireLength = 3;
  const gray = '#474747';

  // The off grid props (props.offGrid, props.offGridWireDirection, and props.offGridWireLength)
  // are used to display the Grid component as offGrid.
  const [offGrid, setOffGrid] = useState(setOffGridHelper(props.offGrid));
  const [offGridWireDirection, setOffGridWireDirection] = useState(
    setOffGridWireDirectionHelper(props.offGridWireDirection)
  );
  const [offGridWireLength, setOffGridWireLength] = useState(
    setOffGridWireLengthHelper(props.offGridWireLength)
  );
  const [offGridWireXAxis, setOffGridWireXAxis] = useState(
    setOffGridWireXAxisHelper(props.offGridWireXAxis)
  );
  const [offGridWireYAxis, setOffGridWireYAxis] = useState(
    setOffGridWireYAxisHelper(props.offGridWireYAxis)
  );

  // Set the state of all of the offGrid states any time any of the props change.
  useEffect(() => {
    setOffGrid(setOffGridHelper(props.offGrid));
    setOffGridWireDirection(
      setOffGridWireDirectionHelper(props.offGridWireDirection)
    );
    setOffGridWireLength(setOffGridWireLengthHelper(props.offGridWireLength));
    setOffGridWireXAxis(setOffGridWireXAxisHelper(props.offGridWireXAxis));
    setOffGridWireYAxis(setOffGridWireYAxisHelper(props.offGridWireYAxis));
  }, [
    props.offGrid,
    props.offGridWireDirection,
    props.offGridWireLength,
    props.offGridWireXAxis,
    props.offGridWireYAxis
  ]);

  // Calculate what the value of the offGrid state should be based on the props.offGrid value.
  function setOffGridHelper(value) {
    return value === undefined ? false : value === 'true';
  }

  // Calculate what the value of the offGridWireDirection state should be based on the props.offGridWireDirection value.
  function setOffGridWireDirectionHelper(value) {
    return value === undefined
      ? false
      : ['left', 'right', 'top', 'bottom'].includes(value)
      ? value
      : false;
  }

  // Calculate what the value of the offGridWireLength state should be based on the props.offGridWireLength value.
  function setOffGridWireLengthHelper(value) {
    return value === undefined ? defaultWireLength : parseFloat(value);
  }

  // Calculate the xaxis value for the gray wire connector that will be placed next to the grid component if it is offline.
  function setOffGridWireXAxisHelper(value) {
    if (value) {
      // Set wire xaxis to what the user set if they provided a value.
      return parseFloat(value);
    } else {
      // Set to a default value if the user did not provide one.
      if (offGridWireDirection === 'left') {
        return defaultWireLeftX;
      } else if (offGridWireDirection === 'right') {
        return defaultWireRightX;
      } else if (
        offGridWireDirection === 'top' ||
        offGridWireDirection === 'bottom'
      ) {
        return defaultWireVerticalX;
      } else {
        // If no valid wire direction, set wire xaxis to false.
        return false;
      }
    }
  }

  // Calculate the yaxis value for the gray wire connector that will be placed next to the grid component if it is offline.
  function setOffGridWireYAxisHelper(value) {
    if (value) {
      // Set wire yaxis to what the user set if they provided a value.
      return parseFloat(value);
    } else {
      // Set to a default value if the user did not provide one.
      if (offGridWireDirection === 'top') {
        return defaultWireTopY;
      } else if (offGridWireDirection === 'bottom') {
        return defaultWireBottomY;
      } else if (
        offGridWireDirection === 'left' ||
        offGridWireDirection === 'right'
      ) {
        return defaultWireHorizontalY;
      } else {
        // If no valid wire direction, set wire yaxis to false.
        return false;
      }
    }
  }

  const customStyle = {
    left: `${props.xaxis * 40}px`,
    top: `${props.yaxis * 40}px`
  };

  return (
    <div className='apparent-module' style={customStyle}>
      <div
        className={`${offGrid ? 'offline' : ''}`}
        style={{ marginTop: '13px' }}
      >
        {offGrid ? 'Off-' : ''}
        Grid
      </div>

      <div className={`power-grid ${offGrid ? 'offline-border' : ''}`}>
        <div className='grid-tower'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            version='1.1'
            x='0px'
            y='0px'
            style={{ width: '100px' }}
          >
            <path
              fill={`${offGrid ? gray : 'white'}`}
              d='M96.3,48.795L62.514,34.122V19.816l19.974-0.021v4.999h2v-5.001l3.916-0.004L62.514,9.211V7.794h-0.025L50.014,0.643  L37.526,7.794h-0.013v1.329L11.401,19.792l4.086,0.002v5.001h2v-5l20.027,0.011V34.11L3.7,48.795h3.787v5h2v-5h28.027v18.81  L25.047,99.357l24.967-20.43l24.966,20.43L62.514,67.604v-18.81h2h25.972v5h2v-5H96.3z M60.514,33.767h-7.88l7.88-4.55V33.767z   M49.17,35.767h1.352l8.944,5.164l-9.62,5.555l-9.62-5.555L49.17,35.767z M39.514,39.032v-3.266h5.656L39.514,39.032z   M54.522,35.767h5.991v3.459L54.522,35.767z M60.514,25.521l-7.494-5.707l7.494,0.003V25.521z M52.621,17.813l7.893-6.002v6.005  L52.621,17.813z M49.855,17.403l-8.678-6.608h17.368L49.855,17.403z M39.514,12.042l7.574,5.769l-7.574-0.004V12.042z   M59.671,27.394l-9.825,5.673l-9.824-5.673l9.833-7.477L59.671,27.394z M47.058,33.767h-7.544V29.41L47.058,33.767z M39.514,42.829  l6.869,3.966h-6.869V42.829z M49.846,48.795l9.62,5.554l-9.62,5.555l-9.62-5.555L49.846,48.795L49.846,48.795z M47.846,61.058  l-8.332,4.811v-9.621L47.846,61.058z M49.846,62.213l10.223,5.901l-10.055,8.228L39.82,68.001L49.846,62.213z M60.514,66.062  l-8.668-5.004l8.668-5.004V66.062z M53.848,48.795h6.666v3.849L53.848,48.795z M53.31,46.795l7.204-4.159v4.159H53.31z M78.247,17.8  l-15.733,0.017v-6.444L78.247,17.8z M60.214,8.795h-0.916H39.804l10.209-5.848L60.214,8.795z M21.572,17.798l15.942-6.514v6.521  L21.572,17.798z M46.691,19.81l-7.177,5.458v-5.461L46.691,19.81z M13.327,46.795l24.187-10.504v10.504H13.327z M45.845,48.795  l-6.331,3.655v-3.655H45.845z M29.708,92.959l9.107-23.195l9.619,7.871L29.708,92.959z M70.319,92.959L51.593,77.635l9.619-7.871  L70.319,92.959z M64.514,46.795h-2V36.303l24.159,10.492H64.514z'
            />
          </svg>
        </div>
      </div>

      {/* Only display the gray offline wire next to the grid component of the user supplied the necessary props in the ES layout. */}
      {offGrid &&
        (offGridWireDirection === 'right' ||
          offGridWireDirection === 'left') && (
          <HorizontalConnector
            online={!offGrid}
            xaxis={offGridWireXAxis}
            yaxis={offGridWireYAxis}
            length={offGridWireLength}
          />
        )}
      {offGrid &&
        (offGridWireDirection === 'bottom' ||
          offGridWireDirection === 'top') && (
          // eslint-disable-next-line no-undef
          <div style={wireCustomStyle}>
            <VerticalConnector
              online={!offGrid}
              xaxis={offGridWireXAxis}
              yaxis={offGridWireYAxis}
              length={offGridWireLength}
            />
          </div>
        )}
    </div>
  );
}

PowerGrid.propTypes = {
  offGrid: PropTypes.bool,
  offGridWireDirection: PropTypes.string,
  offGridWireLength: PropTypes.number,
  offGridWireXAxis: PropTypes.number,
  offGridWireYAxis: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number
};
