// get scale factor to fit contents into container
export const getScale = (contents, container) => {
  let scale = 1.0;
  if (contents.width !== undefined) {
    const heightRatio = contents.height / container.height;
    const widthRatio = contents.width / container.width;
    if (heightRatio > widthRatio) {
      scale = Math.min(container.height / contents.height, 1);
    } else {
      scale = Math.min(container.width / contents.width, 1);
    }
  }
  return scale;
};
