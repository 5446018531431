/* eslint-disable no-empty-pattern */
import React, { useContext } from 'react';

import { EssRackMap } from './essRackMap.js';
import { EssRackContext } from './essRackContext.js';

export const EssRackSelector = ({}) => {
  // eslint-disable-next-line no-unused-vars
  const { selectedRack, essConfig } = useContext(EssRackContext);

  return (
    <div className='rack-selector'>
      {essConfig.numRacks > 1 && (
        <EssRackMap numRacks={essConfig.numRacks} name={essConfig.name} />
      )}
    </div>
  );
};
