import React from 'react';
import PropTypes from 'prop-types';

export function Connector(props) {
  const width = 10;
  const lengthPx = props.length * 40;
  const customStyle = {
    left: `${props.xaxis * 40}px`,
    top: `${props.yaxis * 40}px`,
    width: `${lengthPx}px`
  };

  if (props.deg) {
    customStyle.borderTop = `5px solid transparent`;
    customStyle.borderBottom = `5px solid transparent`;
    const length = lengthPx - width;
    if (props.deg > 0) {
      customStyle.borderRight = `${length}px solid #32CD32`;
      customStyle.webkitTransform = `rotate(${props.deg}deg)`;
      customStyle.webkitTransformOrigin = 'left top';
    } else if (props.deg < 0) {
      customStyle.borderLeft = `${length}px solid #32CD32`;
      customStyle.webkitTransform = `rotate(${props.deg}deg)`;
      customStyle.webkitTransformOrigin = 'right top';
    }
  }

  return <div className='connector' style={customStyle} />;
}

Connector.propTypes = {
  length: PropTypes.number,
  deg: PropTypes.number,
  yaxis: PropTypes.number,
  xaxis: PropTypes.number
};
