/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { addDays, subDays } from 'date-fns';
import { clipArea, unclipArea } from 'chart.js/helpers/helpers.mjs';
ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement
);

// React Component that graphs the energy usage over a 24 hour span

export const SchedulerView = ({ dataSource }) => {
  const POWER_REGULATOR_MODE = 0;
  const ESS_UNIT_MODE = 1;
  const PRODUCTION = 0;
  const DEFAULT_TIMEFRAME = 1;
  const WEEK_TIMEFRAME = 7;
  const [selectedTimeframe, setSelectedTimeframe] = useState(DEFAULT_TIMEFRAME);
  const [startDate, setStartDate] = useState(
    Date.parse(new Date().toLocaleDateString())
  );
  const [powerRegulatorTargets, setPowerRegulatorTargets] = useState({});
  const [essUnitTargets, setEssUnitTargets] = useState({});
  const [perspective, setPerspective] = useState(PRODUCTION);
  const [updateBattery, setUpdateBattery] = useState(false);

  useEffect(() => {
    dataSource.fetchPerspective(perspectiveHandler);
    dataSource.fetchTargets(
      powerRegulatorHandler,
      subDays(startDate, 0),
      addDays(startDate, selectedTimeframe + 2),
      POWER_REGULATOR_MODE
    );
    dataSource.fetchTargets(
      essUnitHandler,
      subDays(startDate, 0),
      addDays(startDate, selectedTimeframe + 2),
      ESS_UNIT_MODE
    );
  }, []);

  useEffect(() => {
    dataSource.fetchTargets(
      powerRegulatorHandler,
      subDays(startDate, 0),
      addDays(startDate, selectedTimeframe + 2),
      POWER_REGULATOR_MODE
    );
    dataSource.fetchTargets(
      essUnitHandler,
      subDays(startDate, 0),
      addDays(startDate, selectedTimeframe + 2),
      ESS_UNIT_MODE
    );
  }, [selectedTimeframe, startDate]);

  const chartRefPowerRegulator = React.useRef(null);
  const chartRefEssUnit = React.useRef(null);
  const perspectiveHandler = (data) => {
    setPerspective(data['perspective']);
  };

  const triggerBatteryRender = () => {
    setTimeout(() => {
      setUpdateBattery(!updateBattery);
    }, 100);
  };

  const powerRegulatorHandler = (data) => {
    const colorList = [
      'red',
      'orange',
      'yellow',
      'green',
      'blue',
      'indigo',
      'violet'
    ];
    let targets = [];
    let i = 0;
    for (const [key, value] of Object.entries(data)) {
      let nextDataset = {
        label: key,
        data: value,
        borderColor: colorList[i],
        backgroundColor: colorList[i],
        lineTension: 0.1,
        pointBackgroundColor: colorList[i],
        pointBorderColor: colorList[i],
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: colorList[i],
        pointHoverBorderColor: colorList[i],
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        stepped: true
      };
      targets.push(nextDataset);
      i += 1;
    }
    if (chartRefPowerRegulator && chartRefPowerRegulator.current) {
      chartRefPowerRegulator.current.data.datasets = targets;
      chartRefPowerRegulator.current.update();
    } else {
      setPowerRegulatorTargets({ datasets: targets });
    }
  };

  const essUnitHandler = (data) => {
    const colorList = [
      'violet',
      'indigo',
      'blue',
      'green',
      'yellow',
      'orange',
      'red'
    ];
    let targets = [];
    let i = 0;
    for (const [key, value] of Object.entries(data)) {
      let nextDataset = {
        label: key,
        data: value,
        borderColor: colorList[i],
        backgroundColor: colorList[i],
        lineTension: 0.1,
        pointBackgroundColor: colorList[i],
        pointBorderColor: colorList[i],
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: colorList[i],
        pointHoverBorderColor: colorList[i],
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        stepped: true
      };
      targets.push(nextDataset);
      i += 1;
    }
    if (chartRefEssUnit && chartRefEssUnit.current) {
      chartRefEssUnit.current.data.datasets = targets;
      chartRefEssUnit.current.update();
    } else {
      setEssUnitTargets({ datasets: targets });
    }
  };

  var clipper = {
    id: 'clipper',
    beforeDatasetsDraw: (chart) => {
      clipArea(chart.ctx, chart.chartArea);
    },
    afterDatasetsDraw: (chart) => {
      unclipArea(chart.ctx);
    }
  };

  const power_regulator_options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        padding: 60,
        labels: {
          font: {
            size: 13
          },
          color: 'black'
        }
      },
      clipper,
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y.toFixed(2) + ' kW';
            }
            return label;
          }
        },
        interaction: {
          mode: 'point'
        }
      }
    },
    animation: false,
    responsive: true,
    clip: false,
    onResize: triggerBatteryRender,
    scales: {
      x: {
        min: startDate,
        max:
          selectedTimeframe == DEFAULT_TIMEFRAME
            ? addDays(startDate, 1)
            : addDays(startDate, 7),
        offset: false,
        stacked: true,
        grid: {
          drawTicks: false,
          drawOnChartArea: false
        },
        type: 'time',
        time: {
          unit: () => {
            return selectedTimeframe == DEFAULT_TIMEFRAME ? 'hour' : 'day';
          },
          displayFormats: {
            day: 'EEE MMM d'
          }
        },
        ticks: {
          display: true,
          autoSkip: true,
          font: {
            size: 15
          },
          maxTicksLimit: 8,
          color: 'black'
        }
      },
      x2: {
        min: startDate,
        max:
          selectedTimeframe == DEFAULT_TIMEFRAME
            ? addDays(startDate, 1)
            : addDays(startDate, 7),
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'EEE MMM d'
          }
        },
        stacked: true,
        offset: false,
        grid: {
          display: true,
          color: 'rgb(0, 0, 0, 0.3)',
          drawTicks: false,
          offset: false,
          drawOnChartArea: true
        },
        ticks: {
          display: false,
          font: {
            size: 15
          }
        }
      },
      y: {
        grid: {
          color: 'rgb(0, 0, 0, 0.7)',
          drawTicks: false,
          drawOnChartArea: true,
          display: true
        },
        offset: true,
        display: true,
        position: 'left',
        ticks: {
          callback: function (value) {
            return value.toFixed(2) + ' kW';
          },
          font: {
            size: 15
          },
          color: 'black',
          maxTicksLimit: 3
        },
        type: 'linear'
      }
    }
  };

  ChartJS.register(clipper);

  const ess_unit_options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          font: {
            size: 13
          },
          color: 'black'
        }
      },
      clipper,
      tooltip: {
        interaction: {
          mode: 'x'
        }
      }
    },
    animation: false,
    responsive: true,
    clip: false,
    scales: {
      x: {
        min: startDate,
        max:
          selectedTimeframe == DEFAULT_TIMEFRAME
            ? addDays(startDate, 1)
            : addDays(startDate, 7),
        offset: false,
        stacked: true,
        grid: {
          drawTicks: false,
          drawOnChartArea: false
        },
        type: 'time',
        time: {
          unit: () => {
            return selectedTimeframe == DEFAULT_TIMEFRAME ? 'hour' : 'day';
          },
          displayFormats: {
            day: 'EEE MMM d'
          }
        },
        ticks: {
          display: true,
          autoSkip: true,
          font: {
            size: 15
          },
          maxTicksLimit: 8,
          color: 'black'
        }
      },
      x2: {
        min: startDate,
        max:
          selectedTimeframe == DEFAULT_TIMEFRAME
            ? addDays(startDate, 1)
            : addDays(startDate, 7),
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'EEE MMM d'
          }
        },
        stacked: true,
        offset: false,
        grid: {
          display: true,
          color: 'rgb(0, 0, 0, 0.3)',
          drawTicks: false,
          offset: false,
          drawOnChartArea: true
        },
        ticks: {
          display: false,
          font: {
            size: 15
          }
        }
      },
      y: {
        type: 'category',
        offset: true,
        ticks: {
          display: true,
          font: {
            size: 15
          },
          color: 'black',
          maxTicksLimit: 3,
          stepSize: 1,
          autoSkip: false
        },
        grid: {
          color: 'rgb(0, 0, 0, 0.7)',
          drawTicks: false,
          drawOnChartArea: true,
          display: true
        },
        display: true,
        position: 'left',
        afterFit: function (scale) {
          scale.width = 140;
        }
      }
    }
  };

  const returnPerspective = () => {
    const text =
      perspective == PRODUCTION ? '+export/-import' : '+import/-export';
    return (
      <div style={{ float: 'right', margin: '0.5vw', position: 'relative' }}>
        {text}
      </div>
    );
  };

  const getWidth = () => {
    let width = '95%';
    if (chartRefPowerRegulator && chartRefPowerRegulator.current) {
      width = chartRefPowerRegulator.current.chartArea.width + 270;
    }
    return width;
  };

  const getLeft = () => {
    let left = 30;
    if (chartRefPowerRegulator && chartRefPowerRegulator.current) {
      left = chartRefPowerRegulator.current.chartArea.left - 70;
    }
    return left;
  };

  const renderer = () => {
    if (
      Object.keys(powerRegulatorTargets).length == 0 ||
      Object.keys(essUnitTargets).length == 0
    ) {
      return <div>Loading...</div>;
    } else {
      return (
        <div
          style={{
            marginBottom: '-10px',
            marginLeft: '20px',
            overflow: 'auto'
          }}
        >
          <div
            style={{
              display: 'inline-block',
              marginLeft: '30px',
              marginBottom: '-1vh',
              position: 'relative',
              height: '10vh',
              width: '95%'
            }}
          >
            <div style={{ width: '30%', position: 'relative', float: 'left' }}>
              <Button
                style={{
                  float: 'left',
                  margin: '0.5vw',
                  zIndex: '5',
                  position: 'relative'
                }}
                onClick={() => setSelectedTimeframe(WEEK_TIMEFRAME)}
              >
                1 W
              </Button>
              <Button
                style={{
                  float: 'left',
                  margin: '0.5vw',
                  zIndex: '5',
                  position: 'relative'
                }}
                onClick={() => setSelectedTimeframe(DEFAULT_TIMEFRAME)}
              >
                1 D
              </Button>
            </div>
            <h3
              style={{
                position: 'relative',
                float: 'left',
                width: '15%',
                marginTop: '2vh',
                font: 'Roboto',
                zIndex: '5',
                textAlign: 'center'
              }}
            />
            <div
              style={{
                width: '55%',
                position: 'relative',
                float: 'right',
                textAlign: 'center',
                zIndex: '5'
              }}
            >
              <div>{returnPerspective()}</div>
              <div
                style={{
                  position: 'relative',
                  float: 'right',
                  margin: '0.5vw'
                }}
              >
                <DatePicker.default
                  selected={startDate}
                  showPopperArrow={false}
                  onChange={(date) => setStartDate(date)}
                  minDate={Date.parse(new Date().toLocaleDateString())}
                  portalId='root-portal'
                  withPortal
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '70px',
              position: 'relative',
              height: '30vh',
              width: '95%'
            }}
          >
            <Line
              ref={chartRefPowerRegulator}
              options={power_regulator_options}
              data={powerRegulatorTargets}
            />
          </div>
          <div
            style={{
              marginLeft: getLeft(),
              position: 'relative',
              height: '30vh',
              width: getWidth()
            }}
          >
            <Line
              ref={chartRefEssUnit}
              options={ess_unit_options}
              data={essUnitTargets}
            />
          </div>
        </div>
      );
    }
  };

  return <div>{renderer()}</div>;
};

SchedulerView.propTypes = {
  dataSource: PropTypes.func
};
