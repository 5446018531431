/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'moment-timezone';
import 'chartjs-adapter-luxon';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

import ZoomPlugin from 'chartjs-plugin-zoom';
import ChartStreaming from 'chartjs-plugin-streaming';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ZoomPlugin,
  ChartStreaming
);

import { white } from '../helpers/colors.js';
import { PerformanceView } from './performanceView.js';

ChartJS.defaults.color = white;
ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.weight = 600;

/* The ChartView encapsulates a chart rendered using chartjs (encapsulated
 * by react-chartjs-2).
 * re-renders that occur if you make use of any state variables.
 */
export const MobileHistoricChartView = ({
  dataSource,
  selectedLayout,
  onlineState
}) => {
  const [jsonLayout, setJsonLayout] = useState({});

  const layoutHandler = (_jsonLayout) => {
    setJsonLayout(_jsonLayout);
    const _ulids = _jsonLayout.layout
      .filter((c) => c.props.ulid !== undefined && c.props.name !== undefined)
      .map((c) => c.props);
  };

  useEffect(() => {
    dataSource.fetchLayout(selectedLayout, layoutHandler);
  }, [selectedLayout]);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', height: '83vh' }}>
      <div style={{ height: '100%', marginTop: '3.7vh', width: '100%' }}>
        <PerformanceView
          dataSource={dataSource}
          darkMode
          site_id={selectedLayout.split('.')[0]}
          energyStream
          timezone={jsonLayout.timezone}
          mobile
          costKWH={jsonLayout.costKWH}
        />
      </div>
    </div>
  );
};

MobileHistoricChartView.propTypes = {
  dataSource: PropTypes.object,
  selectedLayout: PropTypes.string,
  onlineState: PropTypes.array
};
