import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EssRack from './essRack.js';
import { EssRackProvider } from './essRackContext.js';
import { EssRackSelector } from './essRackSelector.js';

export const EssRackBlock = ({
  essConfig,
  dataSource,
  // eslint-disable-next-line no-unused-vars
  viewModeSwitch,
  viewMode,
  selectedRackStart = 1
}) => {
  const [selectedRack, setSelectedRack] = useState(Number(selectedRackStart));

  return (
    <div className='rack-block'>
      <EssRackProvider
        selectedRack={selectedRack}
        setSelectedRack={setSelectedRack}
        essConfig={essConfig}
      >
        <div className='rack-headings'>
          <EssRackSelector />
        </div>
        <EssRack
          dataSource={dataSource}
          // eslint-disable-next-line @shopify/jsx-no-complex-expressions
          viewMode={viewMode ? viewMode : 'Monitor'}
        />
      </EssRackProvider>
    </div>
  );
};

EssRackBlock.propTypes = {
  essConfig: PropTypes.object,
  dataSource: PropTypes.object,
  viewModeSwitch: PropTypes.func,
  viewMode: PropTypes.string,
  selectedRackStart: PropTypes.string
};
