// Accepts timestamps in: integer milliseconds or string "YYYY-MM-DD HH:mm:ss" format.
//    NOTE: If a timestamp is passed in seconds, the wrong result will return so make sure to
//    convert any timestamp in seconds to millisenconds when passing it to this method.
// Accepts timeout in: milliseconds. Default timeout = 60000 ms = 60 seconds.
//    NOTE: Timeout must be converterted to milliseconds before being passed to this method.
// NOTE: Date() can accept a timestamp in integer milliseconds or string "YYYY-MM-DD HH:mm:ss" format.
// Compare the passed-in timestamp's date object with the current time, get difference in milliseconds.
// Returns: true or false, true if is Online and false if is Offline.
export const isOnline = (timestamp, timeout = 5 * 60 * 1000) => {
  // If timestamp exists, determine if online or offline.
  let time = 0;

  if (timestamp !== null && timestamp !== undefined) {
    // If timestamp comes in as "YYYY-MM-DD HH:mm:ss" format, set date object differently.
    if (!/^\d+$/.test(timestamp)) {
      // Checks if the timestamp has any non-digit characters in it.
      const timePieces = timestamp.toString().split(/[- :]/);
      time = new Date(
        timePieces[0],
        timePieces[1] - 1,
        timePieces[2],
        timePieces[3],
        timePieces[4],
        timePieces[5]
      ); // Convert timestamp into a Date object.
    } else {
      // Timestamp is in milliseconds format instead of "YYYY-MM-DD HH:mm:ss" format, so just enter into js Date.
      time = new Date(timestamp); // Convert timestamp from milliseconds into a Date object.
    }

    const now = new Date(); // Get current timestamp.
    const timeDifference = Math.floor(now - time); // Difference in milliseconds.
    return timeDifference < timeout; // Default timeout is 60000 milliseconds/60 seconds. Is offline if difference is greater than the timeout.
  } else {
    // Just say that it is offline if the timestamp passed in was NULL or undefined.
    return false;
  }
};
