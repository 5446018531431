import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const Arrow = ({ location, direction, component, online }) => {
  const [arrowType, setArrowType] = useState('');
  const [animationType, setAnimationType] = useState('');
  const [defaultVerticalOffset, setDefaultVerticalOffset] = useState(0);
  const [defaultHorizontalOffset, setDefaultHorizontalOffset] = useState(0);
  const [componentVerticalOffset, setComponentVerticalOffset] = useState(0);
  const [componentHorizontalOffset, setComponentHorizontalOffset] = useState(0);

  useEffect(() => {
    setDefaultHorizontalOffset(0);
    setDefaultVerticalOffset(0);
    if (!online) {
      setArrowType('None');
      return;
    }
    if (location == 'up') {
      setDefaultVerticalOffset(-35);
      if (direction == 'into') {
        setArrowType('fa-arrow-down');
        componentOffset('fa-arrow-down');
      } else {
        setArrowType('fa-arrow-up');
        componentOffset('fa-arrow-up');
      }
    } else if (location == 'down') {
      setDefaultVerticalOffset(125);
      if (direction == 'into') {
        setArrowType('fa-arrow-up');
        componentOffset('fa-arrow-up');
      } else {
        setArrowType('fa-arrow-down');
        componentOffset('fa-arrow-down');
      }
    } else if (location == 'left') {
      setDefaultHorizontalOffset(-20);
      if (direction == 'into') {
        setArrowType('fa-arrow-right');
        componentOffset('fa-arrow-right');
      } else {
        setArrowType('fa-arrow-left');
        componentOffset('fa-arrow-left');
      }
    } else if (location == 'right') {
      setDefaultHorizontalOffset(175);
      if (direction == 'into') {
        setArrowType('fa-arrow-left');
        componentOffset('fa-arrow-left');
      } else {
        setArrowType('fa-arrow-right');
        componentOffset('fa-arrow-right');
      }
    } else {
      setArrowType('none');
    }
  }, [location, direction, component, online]);

  const componentOffset = (arrow) => {
    setComponentHorizontalOffset(0);
    setComponentVerticalOffset(0);
    if (component == 'solar') {
      if (arrow == 'fa-arrow-right' || arrow == 'fa-arrow-left') {
        setComponentVerticalOffset(41);
      } else if (arrow == 'fa-arrow-up' || arrow == 'fa-arrow-down') {
        setComponentHorizontalOffset(84);
      }
    }
    if (component == 'battery') {
      if (arrow == 'fa-arrow-right' || arrow == 'fa-arrow-left') {
        setComponentVerticalOffset(73);
      } else if (arrow == 'fa-arrow-up' || arrow == 'fa-arrow-down') {
        setComponentHorizontalOffset(84);
        if (
          (arrow == 'fa-arrow-up' && direction == 'into') ||
          (arrow == 'fa-arrow-down' && direction == 'away')
        ) {
          setComponentVerticalOffset(40);
        }
      }
    }
    if (component == 'meter') {
      if (arrow == 'fa-arrow-right' || arrow == 'fa-arrow-left') {
        setComponentVerticalOffset(81);
        if (
          (arrow == 'fa-arrow-right' && direction == 'into') ||
          (arrow == 'fa-arrow-left' && direction == 'away')
        ) {
          setComponentHorizontalOffset(-20);
        } else {
          setComponentHorizontalOffset(-35);
        }
      } else if (arrow == 'fa-arrow-up' || arrow == 'fa-arrow-down') {
        setComponentHorizontalOffset(44);
      }
    }
    if (component == 'transformer') {
      if (arrow == 'fa-arrow-right' || arrow == 'fa-arrow-left') {
        setComponentVerticalOffset(73);
      } else if (arrow == 'fa-arrow-up' || arrow == 'fa-arrow-down') {
        setComponentHorizontalOffset(84);
        if (arrow == 'fa-arrow-down') {
          setComponentVerticalOffset(50);
        }
      }
    }
    if (component == 'busDispenser') {
      if (arrow == 'fa-arrow-right' || arrow == 'fa-arrow-left') {
        setComponentVerticalOffset(73);
      } else if (arrow == 'fa-arrow-up' || arrow == 'fa-arrow-down') {
        setComponentHorizontalOffset(56);
        if (
          (arrow == 'fa-arrow-up' && direction == 'into') ||
          (arrow == 'fa-arrow-down' && direction == 'away')
        ) {
          setComponentVerticalOffset(40);
        }
      }
    }
    if (component == 'truck') {
      if (arrow == 'fa-arrow-right' || arrow == 'fa-arrow-left') {
        setComponentVerticalOffset(73);
      } else if (arrow == 'fa-arrow-up' || arrow == 'fa-arrow-down') {
        setComponentHorizontalOffset(56);
        if (
          (arrow == 'fa-arrow-up' && direction == 'into') ||
          (arrow == 'fa-arrow-down' && direction == 'away')
        ) {
          setComponentVerticalOffset(42);
        }
      }
    }
    if (component == 'pdu') {
      if (arrow == 'fa-arrow-up' || arrow == 'fa-arrow-down') {
        setComponentHorizontalOffset(26);
        if (
          (arrow == 'fa-arrow-up' && direction == 'into') ||
          (arrow == 'fa-arrow-down' && direction == 'away')
        ) {
          setComponentVerticalOffset(242);
        }
      } else if (arrow == 'fa-arrow-right' || arrow == 'fa-arrow-left') {
        setComponentVerticalOffset(20);
        if (
          (arrow == 'fa-arrow-right' && direction == 'away') ||
          (arrow == 'fa-arrow-left' && direction == 'into')
        ) {
          setComponentHorizontalOffset(40);
        } else {
          setComponentHorizontalOffset(-26);
        }
      }
    }
    if (component == 'server') {
      if (arrow == 'fa-arrow-up' || arrow == 'fa-arrow-down') {
        setComponentHorizontalOffset(44);
        if (
          (arrow == 'fa-arrow-up' && direction == 'into') ||
          (arrow == 'fa-arrow-down' && direction == 'away')
        ) {
          setComponentVerticalOffset(32);
        }
      } else if (arrow == 'fa-arrow-right' || arrow == 'fa-arrow-left') {
        setComponentVerticalOffset(20);
        if (
          (arrow == 'fa-arrow-right' && direction == 'away') ||
          (arrow == 'fa-arrow-left' && direction == 'into')
        ) {
          setComponentHorizontalOffset(40);
        } else {
          setComponentHorizontalOffset(-26);
        }
      }
    }
  };

  useEffect(() => {
    switch (arrowType) {
      case 'fa-arrow-up':
        setAnimationType('upArrowSliding');
        break;
      case 'fa-arrow-down':
        setAnimationType('downArrowSliding');
        break;
      case 'fa-arrow-left':
        setAnimationType('leftArrowSliding');
        break;
      case 'fa-arrow-right':
        setAnimationType('rightArrowSliding');
        break;
      default:
        setAnimationType('None');
    }
  }, [arrowType]);

  const arrowCustomStyle = {
    position: 'absolute',
    left: `${defaultHorizontalOffset + componentHorizontalOffset}px`,
    top: `${defaultVerticalOffset + componentVerticalOffset}px`,
    fontSize: '30px',
    color: '#32CD32',
    zIndex: '-5'
  };

  return (
    // eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers
    <i
      className={`fa ${arrowType} ${animationType}`}
      style={arrowCustomStyle}
    />
  );
};

Arrow.propTypes = {
  location: PropTypes.string,
  direction: PropTypes.string,
  component: PropTypes.string,
  online: PropTypes.bool
};
