import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export function Selector({ xaxis, yaxis, selections }) {
  const customStyle = {
    position: 'absolute',
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  return (
    <div style={customStyle}>
      <Form>
        <Form.Group>
          {selections.map((selection, index) => (
            <Form.Check
              key={selection}
              type='radio'
              id={selection}
              name='management'
              label={selection}
              defaultChecked={index === 0}
              style={{
                fontFamily: '"Arial", sans-serif',
                fontSize: '18px',
                fontWeight: 'bold'
              }}
            />
          ))}
        </Form.Group>
      </Form>
    </div>
  );
}

Selector.propTypes = {
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  selections: PropTypes.array
};
