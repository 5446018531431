/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';
import { renderReading } from '../readings.js';
import { isOnline } from '../helpers/isOnline.js';
import { Arrow } from './arrow.js';

const TransformerCoils = ({ color }) => {
  return (
    <div className='transformer-coils'>
      <div
        className='transformer-coil left-coil'
        style={{ backgroundColor: `${color}`, borderColor: `${color}` }}
      >
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
      </div>
      <div
        className='transformer-coil middle-coil'
        style={{ backgroundColor: `${color}`, borderColor: `${color}` }}
      >
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
      </div>
      <div
        className='transformer-coil right-coil'
        style={{ backgroundColor: `${color}`, borderColor: `${color}` }}
      >
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
        <TransformerCoil color={color} />
      </div>
    </div>
  );
};

TransformerCoils.propTypes = {
  color: PropTypes.string
};

const TransformerCoil = ({ color }) => {
  return (
    <div
      className='coil-disc'
      style={{ backgroundColor: `${color}`, borderColor: `${color}` }}
    />
  );
};

TransformerCoil.propTypes = {
  color: PropTypes.string
};

export const Transformer = ({
  name,
  ulid,
  xaxis,
  yaxis,
  titlePosition,
  viewProps,
  overrideName,
  arrowLocation,
  timezone,
  isTabletOrMobile
}) => {
  const [readings, setReadings] = useState({});
  const [transformerName, setTransformerName] = useState(overrideName || name);
  const [meterReading, setMeterReading] = useState('');
  const [selectedReading, setSelectedReading] = useState(() => {
    if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
      return localStorage.getItem('selectedReading'.concat(ulid));
    }
    return 'kw_reading';
  });
  const [online, setOnline] = useState(false);
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const [views, setViews] = viewProps.viewsState;
  const viewMap = viewProps.viewMap;
  const [color, setColor] = useState('white');
  const [arrowDirection, setArrowDirection] = useState('away');

  const directionReading = 'kw_reading';

  useEffect(() => {
    subscribe(meterReadingsEventName, (e) => {
      setReadings(e.detail?.[ulid] || {});
    });
    return () => unsubscribe(meterReadingsEventName);
  }, [ulid]);

  useEffect(() => {
    localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    let _online = false;
    if (Object.keys(readings).length !== 0) {
      _online =
        'read_at' in readings
          ? isOnline(parseInt(readings.read_at) * 1000)
          : true;
      if (!overrideName && 'name' in readings) {
        setTransformerName(readings?.name || transformerName);
      } else if (overrideName) {
        setTransformerName(overrideName);
      }
      if (selectedReading in readings)
        setMeterReading(
          renderReading(
            selectedReading,
            setSelectedReading,
            readings,
            _online,
            timezone,
            isTabletOrMobile
          )
        );
      if (directionReading in readings) {
        if (readings[directionReading] > 0) {
          setArrowDirection('away');
        } else {
          setArrowDirection('into');
        }
      }
    }
    setOnline(_online);
  }, [readings, selectedReading]);

  function offlineClass(className) {
    return !online ? className : '';
  }

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  const onClick = (e, ulid) => {
    if (!isTabletOrMobile) {
      if (selectedUlids.includes(ulid)) {
        const ulids = selectedUlids.filter((u) => u !== ulid);
        setSelectedUlids(ulids);
        if (ulids.length === 0) {
          setViews([viewMap.site]);
        }
      } else {
        setViews([viewMap.site, viewMap.meter, viewMap.chart]);
        setSelectedUlids([ulid, ...selectedUlids]);
      }
    }
    e.stopPropagation();
  };

  const gray = '#474747';

  useEffect(() => {
    if (selectedUlids.includes(ulid)) {
      setColor('yellow');
    } else if (!online) {
      setColor(gray);
    } else {
      setColor('white');
    }
  }, [online, selectedUlids]);

  const onMouseOver = () => {
    if (!selectedUlids.includes(ulid)) setColor('yellow');
  };

  const onMouseOut = () => {
    if (!selectedUlids.includes(ulid))
      online ? setColor('white') : setColor(gray);
  };

  return (
    <div
      className='apparent-module transformer'
      id={ulid}
      style={customStyle}
      onClick={(e) => onClick(e, ulid)}
      onMouseOut={(e) => onMouseOut(e)}
      onMouseOver={(e) => onMouseOver(e)}
    >
      <div className='transformer-container'>
        <TransformerCoils color={color} />
        <div className='transformer-block' style={{ borderColor: `${color}` }}>
          <div className='transformer-readings' style={{ color: `${color}` }}>
            {meterReading}
          </div>
        </div>
      </div>
      <div className='transformer-name' style={{ color: `${color}` }}>
        {transformerName}
      </div>
      <Arrow
        location={arrowLocation}
        direction={arrowDirection}
        component='transformer'
        online={online}
      />
    </div>
  );
};

Transformer.propTypes = {
  name: PropTypes.string,
  ulid: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  titlePosition: PropTypes.string,
  viewProps: PropTypes.object,
  overrideName: PropTypes.string,
  arrowLocation: PropTypes.string,
  timezone: PropTypes.string,
  isTabletOrMobile: PropTypes.bool
};
