/* eslint-disable @shopify/jsx-prefer-fragment-wrappers */
/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';

// import { EssRackContext } from './essRackContext.js';
import SocBar from './socBar.js';
import { isOnline } from '../helpers/isOnline.js';
import { BmuReadingsSelector } from './bmuReadingsSelector.js';
import { StatusCircle } from './statusCircle.js';
import { Button } from 'react-bootstrap';
import { bmuDataUpdate, inverterDataUpdate } from '../helpers/essHandlers.js';

export const EssOverviewRack = React.memo(function EssOverviewRack({
  dataSource,
  setOverviewSelectedRack,
  selectedRack,
  selectedRackID,
  essConfig,
  reload
}) {
  const [bmus, setBmus] = useState([]);
  const bmusRef = useRef(bmus);
  const [inverters, setInverters] = useState([]);
  const invertersRef = useRef(inverters);
  const [rackReadings, setRackReadings] = useState({});
  const [socColA, setSocColA] = useState(0);
  const [socColB, setSocColB] = useState(0);
  const [selectedReadingName, setSelectedReadingName] = useState('current');
  const [selectedReadingIndex, setSelectedReadingIndex] = useState(0);
  const [xStart, setXStart] = useState(0);
  const [yStart, setYStart] = useState(0);
  const [transformXStart, setTransformXStart] = useState(-90);
  const [transformYStart, setTransformYStart] = useState(90);
  const [transformX, setTransformX] = useState(-90);
  const [transformY, setTransformY] = useState(90);
  const [mousePressed, setMousePressed] = useState(false);
  const [reloadState, setReloadState] = reload;

  const ESS_SOC_HEIGHT = 7;

  let calcLeft = selectedRack % 2 == 0 ? 75 : -175;
  let calcTop = Math.floor((selectedRack - 1) / 2) * 250;

  const bmuDataHandler = (data) => {
    bmuDataUpdate(data, bmusRef, setBmus);
  };

  const inverterDataHandler = (data) => {
    inverterDataUpdate(data, invertersRef, setInverters);
  };

  const rackDataHandler = () => {
    return;
  };

  useEffect(() => {
    if (reloadState) {
      dataSource.registerMultipleEssReadingsHandler(
        essConfig,
        selectedRack,
        bmuDataHandler,
        inverterDataHandler,
        rackDataHandler
      );
      setTransformX(-90);
      setTransformY(90);
      setReloadState(false);
    } else {
      return function cleanup() {
        dataSource.closeAll?.();
      };
    }
  }, [essConfig.ulid, essConfig.essId, selectedRack, reloadState]);

  const closeDataSource = (e) => {
    e.preventDefault();
    dataSource.closeAll?.();
  };

  // Make sure we close the data source when the window is closed
  useEffect(() => {
    window.addEventListener('beforeunload', closeDataSource);
    return () => {
      window.removeEventListener('beforeunload', closeDataSource);
    };
  }, [dataSource]);

  useEffect(() => {
    let summedValues = {
      current: 0,
      soc: 0,
      soh: 0,
      voltage: 0,
      cycle_count: 0,
      design_capacity: 0,
      environment_temperature: 0,
      full_charged_capacity: 0,
      mosfet_temperature: 0,
      remaining_capacity: 0,
      timestamp: 0
    };
    let socA = 0;
    let countA = 0;
    let socB = 0;
    let countB = 0;
    bmus.forEach((bmu) => {
      summedValues['current'] =
        summedValues['current'] + Number(bmu['readings']['current']);
      summedValues['soc'] =
        summedValues['soc'] + Number(bmu['readings']['soc']);
      if (bmu.column_name == 'A') {
        socA = socA + Number(bmu['readings']['soc']);
        countA++;
      } else {
        socB = socB + Number(bmu['readings']['soc']);
        countB++;
      }
      summedValues['soh'] =
        summedValues['soh'] + Number(bmu['readings']['soh']);
      summedValues['voltage'] =
        summedValues['voltage'] + Number(bmu['readings']['voltage']);
      summedValues['cycle_count'] =
        summedValues['cycle_count'] + Number(bmu['readings']['cycle_count']);
      summedValues['design_capacity'] =
        summedValues['design_capacity'] +
        Number(bmu['readings']['design_capacity']);
      summedValues['environment_temperature'] =
        summedValues['environment_temperature'] +
        Number(bmu['readings']['environment_temperature']);
      summedValues['full_charged_capacity'] =
        summedValues['full_charged_capacity'] +
        Number(bmu['readings']['full_charged_capacity']);
      summedValues['mosfet_temperature'] =
        summedValues['mosfet_temperature'] +
        Number(bmu['readings']['mosfet_temperature']);
      summedValues['remaining_capacity'] =
        summedValues['remaining_capacity'] +
        Number(bmu['readings']['remaining_capacity']);
      let timestamp = Date.parse(bmu['readings']['timestamp']);
      if (timestamp > summedValues['timestamp']) {
        summedValues['timestamp'] = timestamp;
      }
    });
    summedValues['current'] = (summedValues['current'] / bmus.length).toFixed(
      3
    );
    summedValues['soc'] = (summedValues['soc'] / bmus.length).toFixed(3);
    summedValues['soh'] = (summedValues['soh'] / bmus.length).toFixed(3);
    summedValues['voltage'] = (summedValues['voltage'] / bmus.length).toFixed(
      3
    );
    summedValues['cycle_count'] = (
      summedValues['cycle_count'] / bmus.length
    ).toFixed(3);
    summedValues['design_capacity'] = (
      summedValues['design_capacity'] / bmus.length
    ).toFixed(3);
    summedValues['environment_temperature'] = (
      summedValues['environment_temperature'] / bmus.length
    ).toFixed(3);
    summedValues['full_charged_capacity'] = (
      summedValues['full_charged_capacity'] / bmus.length
    ).toFixed(3);
    summedValues['mosfet_temperature'] = (
      summedValues['mosfet_temperature'] / bmus.length
    ).toFixed(3);
    summedValues['remaining_capacity'] = (
      summedValues['remaining_capacity'] / bmus.length
    ).toFixed(3);
    summedValues['timestamp'] = new Date(
      summedValues['timestamp']
    ).toLocaleString('en-us');
    setSocColA((socA / countA).toFixed(3));
    setSocColB((socB / countB).toFixed(3));
    setRackReadings(summedValues);
  }, [bmus]);

  const getBmuSocFace = () => {
    return essConfig.numCols > 1 ? (
      <div>
        <h3
          style={{
            margin: '1vh',
            top: 0,
            left: 55,
            position: 'absolute',
            color: 'white'
          }}
        >
          A
        </h3>
        <h3
          style={{
            margin: '1vh',
            top: 0,
            left: 161,
            position: 'absolute',
            color: 'white'
          }}
        >
          B
        </h3>
        {bmus.map((bmu) => {
          return (
            <div
              style={{
                top: 100 - (bmu.rack_slot_number * 12 - 27),
                left: bmu.column_name == 'A' ? 0 : 125,
                width: '45%',
                position: 'absolute'
              }}
              key={`${bmu.column_name}-${bmu.rack_slot_number}`}
            >
              <SocBar
                column_name={bmu['column_name']}
                soc={bmu['readings']['soc']}
                onlineStatus={
                  isOnline(bmu['readings']['timestamp']) ? 'online' : 'offline'
                }
                height={ESS_SOC_HEIGHT}
              />
            </div>
          );
        })}
        <Button
          style={{ position: 'absolute', top: 200, left: 95 }}
          variant='primary'
          onClick={handleClick}
        >
          Details
        </Button>
      </div>
    ) : (
      <div>
        <div
          style={{
            margin: '1vh',
            top: 0,
            left: 55,
            position: 'absolute',
            color: 'white'
          }}
        />
        {bmus.map((bmu) => {
          return (
            <div
              style={{
                top: 100 - (bmu.rack_slot_number * 12 - 27),
                left: 0,
                width: '95%',
                position: 'absolute'
              }}
              key={`${bmu.column_name}-${bmu.rack_slot_number}`}
            >
              <SocBar
                column_name={bmu['column_name']}
                soc={bmu['readings']['soc']}
                onlineStatus={
                  isOnline(bmu['readings']['timestamp']) ? 'online' : 'offline'
                }
                height={ESS_SOC_HEIGHT}
              />
            </div>
          );
        })}
        <Button
          style={{ position: 'absolute', top: 200, left: 95 }}
          variant='primary'
          onClick={handleClick}
        >
          Details
        </Button>
      </div>
    );
  };

  const getAvgSocBar = () => {
    if (isNaN(socColA) && isNaN(socColB)) {
      return <div />;
    } else if (!isNaN(socColA) && !isNaN(socColB)) {
      return (
        <>
          <div
            style={{
              position: 'absolute',
              left: '0px',
              width: '45%',
              top: '0px'
            }}
          >
            <SocBar
              column_name='A'
              soc={socColA}
              onlineStatus={
                isOnline(new Date(rackReadings['timestamp']).getTime())
                  ? 'online'
                  : 'offline'
              }
              height={ESS_SOC_HEIGHT}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: '125px',
              width: '45%',
              top: '0px'
            }}
          >
            <SocBar
              column_name='B'
              soc={socColB}
              onlineStatus={
                isOnline(new Date(rackReadings['timestamp']).getTime())
                  ? 'online'
                  : 'offline'
              }
              height={ESS_SOC_HEIGHT}
            />
          </div>
        </>
      );
    } else if (!isNaN(socColA)) {
      return (
        <div
          style={{
            position: 'absolute',
            left: '0px',
            width: '95%',
            top: '0px'
          }}
        >
          <SocBar
            column_name='A'
            soc={socColA}
            onlineStatus={
              isOnline(new Date(rackReadings['timestamp']).getTime())
                ? 'online'
                : 'offline'
            }
            height={ESS_SOC_HEIGHT}
          />
        </div>
      );
    } else if (!isNaN(socColB)) {
      return (
        <div
          style={{
            position: 'absolute',
            left: '0px',
            width: '95%',
            top: '0px'
          }}
        >
          <SocBar
            column_name='B'
            soc={socColB}
            onlineStatus={
              isOnline(new Date(rackReadings['timestamp']).getTime())
                ? 'online'
                : 'offline'
            }
            height={ESS_SOC_HEIGHT}
          />
        </div>
      );
    }
  };

  const handleDragStart = (e) => {
    setXStart(e.pageX);
    setYStart(e.pageY);
    setMousePressed(true);
    document.body.style.cursor = 'grabbing';
  };

  const handleDragEnd = () => {
    setMousePressed(false);
    setTransformXStart(transformX);
    setTransformYStart(transformY);
    document.body.style.cursor = 'grab';
  };

  const handleDrag = (e) => {
    if (!mousePressed) return;
    e.preventDefault();
    let x =
      ((e.pageX - xStart) / document.getElementById('container').clientWidth) *
      360;
    let y =
      ((yStart - e.pageY) / document.getElementById('container').clientHeight) *
      360;
    setTransformX(y + transformXStart);
    setTransformY(x + transformYStart);
  };

  const handleHover = () => {
    document.body.style.cursor = 'grab';
  };

  const handleLeave = () => {
    if (mousePressed) {
      handleDragEnd();
    }
    document.body.style.cursor = 'default';
  };

  const handleClick = (e) => {
    e.preventDefault();
    document.body.style.cursor = 'default';
    setOverviewSelectedRack(selectedRack);
  };

  return (
    <div
      id='container'
      className='overview-container'
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      style={{
        position: 'absolute',
        left: `${calcLeft}px`,
        top: `${calcTop}px`,
        transformStyle: 'preserve-3d'
      }}
    >
      <div
        className='overview-rack'
        style={{
          transform: `translateZ(213.5px) rotateX(${transformX}deg) rotateY(${transformY}deg)`
        }}
      >
        <div className='face overview-readings-face'>
          <h3 style={{ color: 'white', position: 'absolute', top: '-5px' }}>
            Rack {selectedRack}
            {isNaN(selectedRackID) ? `` : `: ID ${selectedRackID}`}
          </h3>
          {Object.keys(rackReadings).length > 0 &&
          !isNaN(rackReadings['current']) ? (
            <>
              <BmuReadingsSelector
                readings={rackReadings}
                onlineStatus={
                  isOnline(new Date(rackReadings['timestamp']).getTime())
                    ? 'online'
                    : 'offline'
                }
                selectedReadingName={selectedReadingName}
                setSelectedReadingName={setSelectedReadingName}
                selectedReadingIndex={selectedReadingIndex}
                setSelectedReadingIndex={setSelectedReadingIndex}
                column_name='A'
              />
              {getAvgSocBar()}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className='long-face overview-bmu-soc-face'>{getBmuSocFace()}</div>
        <div className='long-face back-face' />
        <div className='face bottom-face' />
        <div className='short-face left-face'>
          {inverters.map((inverter) => {
            if (inverter.info.rack_column_name == 'A')
              return (
                <div
                  style={{
                    top: 208 - (inverter.info.rack_slot_number * 12 + 27),
                    left: '32px',
                    position: 'absolute',
                    transform: 'scale(0.75)'
                  }}
                  key={inverter.info.rack_slot_number}
                >
                  <StatusCircle
                    className={`bmu-status-circle ${
                      isOnline(
                        parseInt(inverter.status.updatedTime) * 1000,
                        900 * 1000
                      )
                        ? 'online'
                        : 'offline'
                    }`}
                    title={
                      isOnline(
                        parseInt(inverter.status.updatedTime) * 1000,
                        900 * 1000
                      )
                        ? 'online'
                        : 'offline'
                    }
                  />
                </div>
              );
          })}
        </div>
        <div className='short-face right-face'>
          {inverters.map((inverter) => {
            if (inverter.info.rack_column_name == 'B')
              return (
                <div
                  style={{
                    top: 208 - (inverter.info.rack_slot_number * 12 + 27),
                    left: '32px',
                    position: 'absolute',
                    transform: 'scale(0.75)'
                  }}
                >
                  <StatusCircle
                    className={`bmu-status-circle ${
                      isOnline(
                        parseInt(inverter.status.updatedTime) * 1000,
                        900 * 1000
                      )
                        ? 'online'
                        : 'offline'
                    }`}
                    title={
                      isOnline(
                        parseInt(inverter.status.updatedTime) * 1000,
                        900 * 1000
                      )
                        ? 'online'
                        : 'offline'
                    }
                  />
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
});

EssOverviewRack.propTypes = {
  dataSource: PropTypes.object,
  setOverviewSelectedRack: PropTypes.any,
  selectedRack: PropTypes.string,
  selectedRackID: PropTypes.number,
  essConfig: PropTypes.object,
  reload: PropTypes.array
};
