export const TIME_FRAME_DAY = '1d';
export const TIME_FRAME_WEEK = '1w';
export const TIME_FRAME_MONTH = '1m';
export const TIME_FRAME_YEAR = '1y';
export const TIME_FRAME_MAX = 'all';
export const TIME_FRAME_BILLING = 'billing period';
export const ENERGY_CHART = 'Energy Use';
export const POWER_CHART = 'Power Use';
export const COST_CHART = 'Energy Cost';
export const TOTAL_USAGE_CHART = 'Total Usage';
export const PROJECTED_GRID_CHART = 'Projected Grid';

export const intervalMap = {
  '1d': 1,
  '1w': 4,
  '1m': 96,
  '1y': 365,
  all: 96,
  'billing period': 96
};
