import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { EssRackContext } from './essRackContext.js';
import { StatusCircle } from './statusCircle.js';
import { InverterReadingsSelector } from './inverterReadingsSelector.js';
import { useInterval } from '../helpers/useInterval.js';
import { isOnline } from '../helpers/isOnline.js';

/*
  Inverter JSON data is provided in the following format
  though both info and status are stringified in the props:

   {
     "info": {
       "inverter_id": 375,
       "serial_number": "512036001083",
       "mac_address": "e0:1f:0a:01:64:6d",
       "ip_address": "10.252.8.238",
       "device_id": 3,
       "pcs_unit_id": 7,
       "group_id": 3,
       "rack_slot_number": 1,
       "rack_column_name": "A",
     },
     "status": {
       "updatedTime": 1620629612,
       "DcVsolar": 51.668,
       "DcIsolar": 14.124,
       "DcWatt": 732,
       "AcVout": 212.666,
       "AcIout": 3.16464,
       "phase": 0,
       "var": 0,
       "temp": 34.4175,
       "wattsCons": 0,
       "AcFrequency": 59.9977,
       "AcIlineRms": 3.027,
       "operational_status": "enabled|disabled, ... uninit,diag_fail,temp_stby,bob,dcovp,ocp,trov2,no_ign,
                              high_temp,low_temp,dcin_p,dcin_v,dcin_i,acov_1,acov_2,acov_3,pll_lol,pll_nolok,
                              pll_island,bad_profile"
       "power_status": "mpp,max,limited,gw_curt",
       "admin_status": "unlocked | locked | locked_lp"
     }
   }

*/

const Inverter = React.memo(function Inverter({
  info,
  status,
  selectedInverterReadingName,
  setSelectedInverterReadingName,
  selectedInverterReadingIndex,
  setSelectedInverterReadingIndex
}) {
  const { essConfig } = useContext(EssRackContext);

  // convert time to milliseconds; use 15 minute timeout (900s == 900000ms).
  const [onlineStatus, setOnlineStatus] = useState(
    isOnline(parseInt(status.updatedTime) * 1000, 900 * 1000)
      ? 'online'
      : 'offline'
  );

  // mark inverters offline after 3 minutes with no updates
  const onlineTimeout = 180;

  useEffect(() => {
    setOnlineStatus(
      isOnline(parseInt(status.updatedTime) * 1000, onlineTimeout * 1000)
        ? 'online'
        : 'offline'
    );
  }, [status]);

  // update online every 60 seconds
  useInterval(() => {
    setOnlineStatus(
      isOnline(parseInt(status.updatedTime) * 1000, onlineTimeout * 1000)
        ? 'online'
        : 'offline'
    );
  }, 60000);

  // display a D for disabled inverters
  const getOpStatusSymbol = (operational_status) =>
    operational_status.indexOf('disabled') > -1 ? 'D' : '';

  const adminStatusIcon = (admin_status) => {
    if (admin_status === 'locked') {
      return 'fa-lock';
    } else if (admin_status === 'unlocked') {
      return ''; // We don't want to display an unlock symbol if it is unlocked.
    } else if (admin_status === 'locked_lp') {
      return 'fa-lock locked_lp';
    } else {
      return '';
    }
  };

  // inverter offsets relative to .ess-rack parent div
  const columnWidth = 507;
  const inverterLeftOffset = 0;
  const rowHeight = 84;
  const inverterTopOffset = 36;

  // inverter position based on bmu colName, row, and number of rows
  const inverterLeftPx =
    (info.rack_column_name.charCodeAt() - 'A'.charCodeAt()) * columnWidth +
    inverterLeftOffset;
  const inverterTopPx =
    Math.abs(info.rack_slot_number - essConfig.numRows) * rowHeight +
    inverterTopOffset;
  const customInverterBlockAStyle = {
    left: `${inverterLeftPx}px`,
    top: `${inverterTopPx}px`
  };

  return (
    <div
      className={`inverter-block ${onlineStatus}`}
      style={customInverterBlockAStyle}
    >
      <div
        className={`inverter-op-status ${onlineStatus}`}
        title={status.operational_status}
      >
        {getOpStatusSymbol(status.operational_status)}
      </div>

      <StatusCircle
        className={`inverter-status-circle ${onlineStatus}`}
        title={onlineStatus}
      />

      <div className='inverter-lock-icon' title={status.admin_status}>
        <i
          className={`fa ${adminStatusIcon(
            status.admin_status
          )} fa-lg ${onlineStatus}`}
          aria-hidden='true'
        />
      </div>

      <InverterReadingsSelector
        status={status}
        info={info}
        onlineStatus={onlineStatus}
        selectedInverterReadingName={selectedInverterReadingName}
        setSelectedInverterReadingName={setSelectedInverterReadingName}
        selectedInverterReadingIndex={selectedInverterReadingIndex}
        setSelectedInverterReadingIndex={setSelectedInverterReadingIndex}
      />
    </div>
  );
});

Inverter.propTypes = {
  info: PropTypes.object,
  status: PropTypes.object,
  selectedInverterReadingName: PropTypes.string,
  setSelectedInverterReadingName: PropTypes.func,
  selectedInverterReadingIndex: PropTypes.number,
  setSelectedInverterReadingIndex: PropTypes.func
};

export default Inverter;
