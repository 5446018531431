import React, { useContext } from 'react';

import { EssRackContext } from './essRackContext.js';

// eslint-disable-next-line no-empty-pattern
export default React.memo(function EssRackColumnNames({}) {
  const { essConfig } = useContext(EssRackContext);

  // Get alphabetic column name using colNum, starting from A.
  function getColumnName(colNum) {
    return String.fromCharCode(colNum + 'A'.charCodeAt());
  }

  return (
    <div className='rack-column-names'>
      {Array.from(Array(essConfig.numCols).keys()).map((col) => {
        return (
          <div
            className={`rack-column-name rack-column-name-${getColumnName(
              col
            )}`}
            key={getColumnName(col)}
          >
            {getColumnName(col)}
          </div>
        );
      })}
    </div>
  );
});
