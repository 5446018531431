import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ElectricTruck = ({ truckSOC }) => {
  const [online] = useState(true);

  const getBatteryCharge = () => {
    const cells = [];
    let className = 'truck-battery-soc-cell';
    if (!online) className += ' battery-soc-offline';

    for (let cell = 0; cell < 10; cell++) {
      if (truckSOC === null || truckSOC < cell * 10 + 1) {
        cells.push(
          <div className={`${className} battery-soc-cell-grey`} key={cell} />
        );
      } else {
        if (truckSOC <= 10) {
          cells.push(
            <div className={`${className} battery-soc-cell-red`} key={cell} />
          );
        } else if (truckSOC <= 20) {
          cells.push(
            <div
              className={`${className} battery-soc-cell-yellow`}
              key={cell}
            />
          );
        } else {
          cells.push(
            <div className={`${className} battery-soc-cell-full`} key={cell} />
          );
        }
      }
    }

    return <div>{cells}</div>;
  };

  return (
    <span className='electric-truck'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        version='1.1'
        x='0px'
        y='0px'
        preserveAspectRatio='xMidYMid meet'
      >
        <path
          className='truck-icon-1'
          d='M55.2,0.01h-2c-4.58,0-10.98-0.3-14.66,2.81C32.26,7.29,27.4,15.21,22.1,20.38 c-4.3,0.56-14.26,2.03-16.55,4.07C2.9,26.81,2.93,34.4,2.97,37.62c-4.92-0.1-2.93,11.81,0.26,12.49h6.85 c-4.4-26.18,32.92-22.94,27.3,0h38.19c-5.76-21.96,31.01-27.57,27.47-0.21c6.53-0.02,10.06-0.1,16.89,0 c2.71-0.62,2.97-2.13,2.97-5.75l-2.66-0.33l0.08-1.5c0.03-0.89,0.06-1.77,0.09-2.65c0.16-5.81,0.14-11.43-0.19-16.74H59.77V5.58 C59.87,1.86,58.24,0.12,55.2,0.01L55.2,0.01z M89.87,41.17c3.02,0,5.46,2.45,5.46,5.46s-2.45,5.46-5.46,5.46 c-3.02,0-5.46-2.45-5.46-5.46S86.85,41.17,89.87,41.17L89.87,41.17z M54.4,4.74h-8.8c-4.54,0-10.59,6.56-14.02,13.01 c-0.35,0.65-3.08,5.18-1.25,5.18H54.4v-0.69V5.44V4.74L54.4,4.74z M23.5,41.17c3.02,0,5.46,2.45,5.46,5.46s-2.45,5.46-5.46,5.46 c-3.02,0-5.46-2.45-5.46-5.46S20.48,41.17,23.5,41.17L23.5,41.17z M23.5,35.52c6.14,0,11.11,4.98,11.11,11.11 S29.64,57.75,23.5,57.75c-6.14,0-11.11-4.98-11.11-11.11S17.36,35.52,23.5,35.52L23.5,35.52z M89.87,35.52 c6.14,0,11.11,4.98,11.11,11.11s-4.98,11.11-11.11,11.11c-6.14,0-11.11-4.98-11.11-11.11S83.73,35.52,89.87,35.52L89.87,35.52z'
        />
      </svg>
      <div className='truck-soc'>{getBatteryCharge()}</div>
    </span>
  );
};

ElectricTruck.propTypes = {
  truckSOC: PropTypes.number
};
