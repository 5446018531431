import './ApiHost'

import axios from 'axios'
import React, { useState, useEffect, Fragment } from 'react'
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate
} from 'react-router-dom'

import Login from './Login'
import Home from './Home'
import ES_API_HOST from './ApiHost'
// import withClearCache from './ClearCache'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'apparent-react/dist/index.css'
import './App.css'

// import withClearCache from './ClearCache'
// const ClearCacheComponent = withClearCache(MainApp)
// const App = () => <ClearCacheComponent/>

const App = () => {
    const [layouts, setLayouts] = useState(JSON.parse(localStorage.getItem("layouts")) || undefined)
    const selectedLayoutState = useState(localStorage.getItem('selectedLayout') || "")    
    const apiHost = ES_API_HOST
    const loggedInState = useState(localStorage.getItem('loggedIn') === 'true' || false)
    const [loggedIn, setLoggedIn] = loggedInState
    const [selectedLayout, setSelectedLayout] = selectedLayoutState

    // on refresh, check if we're logged in by requesting the selected layout
    useEffect(() => {
        if (selectedLayout === "" || !(selectedLayout in Object.keys(layouts)))
            return

        const url = [apiHost, 'api/layouts', selectedLayout].join('/')
        const config = {headers: {'Content-Type': 'application/json'}, timeout: 2000}
        axios
        .get(url, config).then((response) => {
            setLoggedIn(response.status === 200)
        })
        .catch((error) => {
            setLoggedIn(false)
        })
    }, [])

    useEffect(() => {
        localStorage.setItem("loggedIn", loggedIn)
    }, [loggedIn])

    return (
        <BrowserRouter>
            <Fragment>
                <Routes>
                    <Route exact path="/login" element={
                        <Login apiHost={apiHost} 
                               loggedInState={loggedInState}
                               setLayouts={setLayouts}
                               setSelectedLayout={setSelectedLayout} />
                    }/>
                    <Route exact path="/" 
                            element={
                                loggedIn ? 
                                    <Home apiHost={apiHost} loggedInState={loggedInState} layouts={layouts} selectedLayoutState={selectedLayoutState}/> :
                                    <Navigate to="/login"/>
                            }/>
                    <Route path="/logout" element={<Logout/>} />
                </Routes>
            </Fragment>
        </BrowserRouter>
    )
}

const Logout = () => {
    return (
        <Navigate to={"/login"} />
    )
}

export default App
