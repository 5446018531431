/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { View } from './view.js';
import { SiteView } from './siteView.js';
import { BatteryView } from './batteryView.js';
import { MeterView } from './meterView.js';
import { ChartView } from './chartView.js';
import { useInterval } from '../helpers/useInterval.js';
import { Button } from 'react-bootstrap';
import {
  defaultLayout,
  batteryOnlyLayout,
  solarOnlyLayout,
  gridOnlyLayout
} from '../helpers/overviewLayouts.js';
import { meterReadingsEventName, publish } from '../helpers/events.js';

const viewMap = {
  site: SiteView,
  battery: BatteryView,
  meter: MeterView,
  chart: ChartView
};

export const MobileOverview = ({
  dataSource,
  selectedLayout,
  onlineState,
  viewStateMenu,
  mobileViewState
}) => {
  const [online, setOnline] = onlineState;
  const viewsState = useState([SiteView]);
  const [views, setViews] = viewsState;
  const [viewState, setViewState] = viewStateMenu;
  const [mobileView, setMobileView] = mobileViewState;
  // viewMode could be used for regulator config in addition to ESS config
  const viewModeState = useState('Monitor');
  const [ulidMap, setUlidMap] = useState([]);
  const [ulids, setUlids] = useState([]);
  const [allUlids, setAllUlids] = useState([]);
  const [mainPCC, setMainPCC] = useState(null);
  const [mainBattery, setMainBattery] = useState(null);
  const [mainSolar, setMainSolar] = useState(null);
  const selectedUlidsState = useState([]);
  const selectedReadingsState = useState(['kw_reading']);
  const setSelectedUlids = selectedUlidsState[1];
  const [jsonLayout, setJsonLayout] = useState({});
  const [overviewLayout, setOverviewLayout] = useState({});
  const [viewSizes, setViewSizes] = useState([
    { width: 1.0, height: 1.0 },
    { width: 0, height: 1.0 },
    { width: 1.0, height: 0 },
    { width: 0, height: 0 }
  ]);
  const containerSize = {
    width: window.innerWidth * 0.95,
    height: window.innerHeight * 0.81
  };

  const miniContainerSize = {
    width: window.innerWidth * 0.2,
    height: window.innerHeight * 0.2
  };

  useEffect(() => {
    setViews([viewMap.site]);
  }, [viewState]);

  useInterval(() => {
    if (ulids.length !== 0) {
      dataSource.fetch(ulids, (response) => {
        if (response.status === 'success') {
          setOnline(true);
          publish(meterReadingsEventName, response.data);
        } else {
          console.log(response.data);
          setOnline(false);
          if (response.status === 'logout') {
            dataSource?.auth?.setToken(null);
          }
        }
      });
    }
  }, dataSource.interval);

  const getOverviewLayout = () => {
    if (mainBattery != null && mainSolar != null) {
      defaultLayout.layout.forEach((component) => {
        if (component.component == 'Battery') {
          component.props.ulid = mainBattery;
        }
        if (component.component == 'Solar') {
          component.props.ulid = mainSolar;
        }
        if (component.component == 'Meter') {
          component.props.ulid = mainPCC;
        }
      });
      setOverviewLayout(defaultLayout);
    } else if (mainBattery != null) {
      batteryOnlyLayout.layout.forEach((component) => {
        if (component.component == 'Battery') {
          component.props.ulid = mainBattery;
        }
        if (component.component == 'Meter') {
          component.props.ulid = mainPCC;
        }
      });
      setOverviewLayout(batteryOnlyLayout);
    } else if (mainSolar != null) {
      solarOnlyLayout.layout.forEach((component) => {
        if (component.component == 'Solar') {
          component.props.ulid = mainSolar;
        }
        if (component.component == 'Meter') {
          component.props.ulid = mainPCC;
        }
      });
      setOverviewLayout(solarOnlyLayout);
    } else {
      gridOnlyLayout.layout.forEach((component) => {
        if (component.component == 'Meter') {
          component.props.ulid = mainPCC;
        }
      });
      setOverviewLayout(gridOnlyLayout);
    }
  };

  const mainMetersHandler = (_mainMeters) => {
    if (_mainMeters) {
      setMainPCC(
        _mainMeters['pcc']
          ? Number(_mainMeters['pcc'])
            ? Number(_mainMeters['pcc'])
            : _mainMeters['pcc']
          : null
      );
      setMainBattery(
        _mainMeters['battery']
          ? Number(_mainMeters['battery'])
            ? Number(_mainMeters['battery'])
            : _mainMeters['battery']
          : null
      );
      setMainSolar(
        _mainMeters['solar']
          ? Number(_mainMeters['solar'])
            ? Number(_mainMeters['solar'])
            : _mainMeters['solar']
          : null
      );
    } else {
      setMobileView('layout');
    }
  };

  const layoutHandler = (_jsonLayout) => {
    setJsonLayout(_jsonLayout);
    const _ulids = _jsonLayout.layout
      .filter((c) => c.props.ulid !== undefined)
      .map((c) => c.props.ulid);
    setAllUlids(_ulids);
  };

  useEffect(() => {
    setViews([viewMap.site]);
    setSelectedUlids([]);
    setUlidMap([]);
    dataSource.fetchMainMeters(selectedLayout.split('.')[0], mainMetersHandler);
    dataSource.fetchLayout(selectedLayout, layoutHandler);
  }, [selectedLayout]);

  useEffect(() => {
    setViewSizes([
      { width: 1.0, height: 1.0 },
      { width: 0, height: 1.0 },
      { width: 1.0, height: 0 },
      { width: 0, height: 0 }
    ]);
  }, [views]);

  useEffect(() => {
    let ulidList = [];
    getOverviewLayout();
    if (mainBattery != null) {
      if (typeof mainBattery == 'object') {
        ulidList = ulidList.concat(
          Object.keys(mainBattery).map((e) => Number(e))
        );
      } else {
        ulidList.push(mainBattery);
      }
    }
    if (mainSolar != null) {
      if (typeof mainSolar == 'object') {
        ulidList = ulidList.concat(
          Object.keys(mainSolar).map((e) => Number(e))
        );
      } else {
        ulidList.push(mainSolar);
      }
    }
    if (mainPCC != null) {
      if (typeof mainPCC == 'object') {
        ulidList = ulidList.concat(Object.keys(mainPCC).map((e) => Number(e)));
      } else {
        ulidList.push(mainPCC);
      }
    }
    setUlids(ulidList);
  }, [mainBattery, mainSolar, mainPCC]);

  const handleClick = (e) => {
    e.stopPropagation();
    setMobileView('layout');
  };

  // maybe all 4 views need to be there all the
  // time for react-spring to work??
  const overviewElements = views.map((view, index) => (
    <View
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      width={viewSizes[index].width}
      height={viewSizes[index].height}
      containerSize={containerSize}
    >
      {React.createElement(view, {
        dataSource: dataSource,
        selectedLayout: selectedLayout,
        jsonLayout: overviewLayout,
        ulids: ulids,
        online: online,
        containerSize: containerSize,
        viewProps: {
          viewMap: viewMap,
          viewsState: viewsState,
          viewModeState: viewModeState,
          selectedUlidsState: selectedUlidsState,
          selectedReadingsState: selectedReadingsState,
          siteId: selectedLayout.split('.')[0],
          timezone: jsonLayout.timezone
            ? jsonLayout.timezone
            : 'America/Los_Angeles'
        },
        isTabletOrMobile: true
      })}
    </View>
  ));

  const viewElements = views.map((view, index) => (
    <View
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      width={viewSizes[index].width}
      height={viewSizes[index].height}
      containerSize={miniContainerSize}
      isMini
    >
      {React.createElement(view, {
        dataSource: dataSource,
        selectedLayout: selectedLayout,
        jsonLayout: jsonLayout,
        ulids: allUlids,
        online: online,
        containerSize: containerSize,
        viewProps: {
          viewMap: viewMap,
          viewsState: viewsState,
          viewModeState: viewModeState,
          selectedUlidsState: selectedUlidsState,
          selectedReadingsState: selectedReadingsState,
          siteId: selectedLayout.split('.')[0],
          timezone: jsonLayout.timezone
            ? jsonLayout.timezone
            : 'America/Los_Angeles'
        },
        isTabletOrMobile: true
      })}
    </View>
  ));
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', height: '83vh' }}>
      {overviewElements}
      <Button
        style={{
          position: 'absolute',
          width: '20vw',
          height: '20vh',
          marginTop: '60vh',
          marginLeft: '70vw',
          padding: '5px',
          backgroundColor: 'black'
        }}
        onClick={(e) => handleClick(e)}
      >
        {viewElements}
      </Button>
    </div>
  );
};

MobileOverview.propTypes = {
  dataSource: PropTypes.object,
  selectedLayout: PropTypes.string,
  onlineState: PropTypes.array,
  viewStateMenu: PropTypes.array,
  mobileViewState: PropTypes.array
};
