/* eslint-disable no-unused-vars */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes, { elementType } from 'prop-types';
import { renderReading } from '../readings.js';
import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';
import { isOnline } from '../helpers/isOnline.js';
import { Arrow } from './arrow.js';

export const Battery = ({
  name,
  ulid,
  xaxis,
  yaxis,
  titlePosition,
  dataSource,
  viewProps,
  overrideName,
  arrowLocation,
  timezone,
  isTabletOrMobile
}) => {
  const [batteryName, setBatteryName] = useState(overrideName || name);
  const [reading, setReading] = useState('');
  const [readings, setReadings] = useState({});
  const [soc, setSoc] = useState(null);
  const [regulate, setRegulate] = useState('');
  const [selectedReading, setSelectedReading] = useState(() => {
    if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
      return localStorage.getItem('selectedReading'.concat(ulid));
    }
    return 'kw_reading';
  });
  const [online, setOnline] = useState(false);
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const [views, setViews] = viewProps.viewsState;
  const viewMap = viewProps.viewMap;
  const [color, setColor] = useState('white');
  const [type, setType] = useState('meter');
  const [arrowDirection, setArrowDirection] = useState('away');

  const directionReading = 'kw_reading';

  useEffect(() => {
    if (typeof ulid != 'object') {
      dataSource.fetchEssConfig(
        ulid,
        (ulid, numRacks, numRows, numCols, name) => setType('battery')
      );
      subscribe(meterReadingsEventName, (e) =>
        setReadings(e.detail?.[ulid] || {})
      );
      return () => unsubscribe(meterReadingsEventName);
    } else {
      const ulidList = Object.keys(ulid);
      subscribe(meterReadingsEventName, (e) => {
        let tempReadings = {};
        ulidList.forEach((id) => {
          const tempVals = e.detail?.[id];
          tempReadings[id] = tempVals;
        });
        setReadings(tempReadings);
      });

      return () => unsubscribe(meterReadingsEventName);
    }
  }, [ulid]);

  useEffect(() => {
    localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    let _online = false;
    if (typeof ulid != 'object') {
      if (Object.keys(readings).length !== 0) {
        _online =
          'read_at' in readings
            ? isOnline(parseInt(readings.read_at) * 1000)
            : true;
        if (!overrideName && 'name' in readings) {
          setBatteryName(readings.name);
        } else if (overrideName) {
          setBatteryName(overrideName);
        }
        if (selectedReading in readings)
          setReading(
            renderReading(
              selectedReading,
              setSelectedReading,
              readings,
              _online,
              timezone,
              isTabletOrMobile
            )
          );
        if (directionReading in readings) {
          if (readings[directionReading] > 0) {
            setArrowDirection('away');
          } else {
            setArrowDirection('into');
          }
        }
        if ('battery_regulate' in readings) {
          let value = Number.parseFloat(readings.battery_regulate).toFixed(1);
          value = value !== 'NaN' ? value + ' kW' : '';
          setRegulate(value);
        }
        if ('battery_soc' in readings) setSoc(readings.battery_soc);
      }
      setOnline(_online);
    } else {
      let tempReadings = {};
      let x = 0;
      Object.keys(ulid).forEach((element) => {
        if (readings[element] && Object.keys(readings[element]).length !== 0) {
          _online =
            'read_at' in readings[element]
              ? isOnline(parseInt(readings[element].read_at) * 1000)
              : true;
          if (_online) {
            setOnline(_online);
          }
          Object.keys(readings[element]).forEach((key) => {
            let sum =
              tempReadings[key] + readings[element][key] ||
              readings[element][key];
            tempReadings[key] = sum;
          });
          x += 1;
        }
      });
      Object.keys(tempReadings).forEach((key) => {
        tempReadings[key] = tempReadings[key] / x;
      });
      if (selectedReading in tempReadings) {
        setReading(
          renderReading(
            selectedReading,
            setSelectedReading,
            tempReadings,
            _online,
            timezone,
            isTabletOrMobile
          )
        );
      }
      if ('battery_regulate' in tempReadings) {
        let value = Number.parseFloat(tempReadings.battery_regulate).toFixed(1);
        value = value !== 'NaN' ? value + ' kW' : '';
        setRegulate(value);
      }
      if ('battery_soc' in tempReadings) {
        setSoc(tempReadings.battery_soc);
      }
    }
  }, [readings, selectedReading]);

  const getBatteryCharge = () => {
    const cells = [];
    let className = 'battery-soc-cell';
    if (!online) className += ' battery-soc-offline';

    for (let cell = 0; cell < 10; cell++) {
      if (soc === null || soc < cell * 10 + 1) {
        cells.push(
          <div className={`${className} battery-soc-cell-grey`} key={cell} />
        );
      } else {
        if (soc <= 10) {
          cells.push(
            <div className={`${className} battery-soc-cell-red`} key={cell} />
          );
        } else if (soc <= 20) {
          cells.push(
            <div
              className={`${className} battery-soc-cell-yellow`}
              key={cell}
            />
          );
        } else {
          cells.push(
            <div className={`${className} battery-soc-cell-full`} key={cell} />
          );
        }
      }
    }

    return <div>{cells}</div>;
  };

  const getReadingsColor = () => {
    if (Number.parseFloat(reading) >= 0) {
      return <div className='positive-readings'>{reading}</div>;
    } else {
      return <div className='negative-readings'>{reading}</div>;
    }
  };

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  const offlineClass = (className) => (!online ? className : '');

  const onClick = (e, ulid) => {
    if (!isTabletOrMobile) {
      if (selectedUlids.includes(ulid)) {
        const ulids = selectedUlids.filter((u) => u !== ulid);
        setSelectedUlids(ulids);
        if (ulids.length === 0) {
          setViews([viewMap.site]);
        }
      } else {
        setSelectedUlids([ulid, ...selectedUlids]);
        type === 'battery' && selectedUlids.length == 0
          ? setViews([viewMap.site, viewMap.battery])
          : setViews([viewMap.site, viewMap.meter, viewMap.chart]);
      }
    }
    e.stopPropagation(); // stop SiteView from receiving click event
  };

  const gray = '#474747';

  useEffect(() => {
    if (selectedUlids.includes(ulid)) {
      setColor('yellow');
    } else if (!online) {
      setColor(gray);
    } else {
      setColor('white');
    }
  }, [online, selectedUlids]);

  const onMouseOver = () => {
    if (!selectedUlids.includes(ulid)) setColor('yellow');
  };
  const onMouseOut = () => {
    if (!selectedUlids.includes(ulid))
      online ? setColor('white') : setColor(gray);
  };

  return (
    <div
      className='apparent-module-battery'
      onClick={(e) => onClick(e, ulid)}
      id={ulid}
      style={customStyle}
      onMouseOut={(e) => onMouseOut(e)}
      onMouseOver={(e) => onMouseOver(e)}
    >
      <div className='battery-outer-container'>
        {(titlePosition === undefined || titlePosition === 'top') && (
          <div className='battery-name' style={{ color: `${color}` }}>
            {batteryName}
          </div>
        )}
        <div className='battery-inner-container'>
          <div
            className='battery-terminal left-terminal'
            style={{ backgroundColor: `${color}`, borderColor: `${color}` }}
          />
          <div
            className='battery-terminal right-terminal'
            style={{ backgroundColor: `${color}`, borderColor: `${color}` }}
          />
          <div className='battery-block' style={{ borderColor: `${color}` }}>
            <div className='battery-soc' title={`${soc}%`}>
              {getBatteryCharge(online)}
            </div>
            <div className='readings'>
              {getReadingsColor()}
              <div className={`regulate-readings ${offlineClass('offline')}`}>
                {regulate}
              </div>
            </div>
          </div>
        </div>
        {titlePosition === 'bottom' && (
          <div
            className='battery-name battery-name-bottom'
            style={{ color: `${color}` }}
          >
            {batteryName}
          </div>
        )}
      </div>
      <Arrow
        location={arrowLocation}
        direction={arrowDirection}
        component='battery'
        online={online}
      />
    </div>
  );
};

Battery.propTypes = {
  name: PropTypes.string,
  ulid: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  titlePosition: PropTypes.string,
  dataSource: PropTypes.object,
  viewProps: PropTypes.object,
  overrideName: PropTypes.string,
  arrowLocation: PropTypes.string,
  timezone: PropTypes.string,
  isTabletOrMobile: PropTypes.bool
};
