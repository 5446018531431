export const lightGray = '#959595';
export const gray = '#474747';
export const teal = 'rgba(0,255,255,0.8)';
export const purple = '#ff11f6';
export const bright_green = 'rgb(60, 251, 62, 0.8)';
export const bright_green_fill = 'rgb(60, 251, 62, 0.15)';
export const la_salle_green = 'rgba(10, 105, 33,0.8)';
export const la_salle_green_fill = 'rgba(10, 105, 33,0.15)';
export const web_forest_green = 'rgba(26, 136, 40,0.8)';
export const web_forest_green_fill = 'rgba(26, 136, 40,0.15)';
export const may_green = 'rgba(66, 155, 70,0.8)';
export const may_green_fill = 'rgba(66, 155, 70,0.15)';
export const crayola_green = 'rgba(100, 173, 98,0.8)';
export const crayola_green_fill = 'rgba(100, 173, 98,0.15)';
export const dark_sea_green = 'rgba(148, 197, 140, 0.8)';
export const dark_sea_green_fill = 'rgba(148, 197, 140, 0.15)';
export const mikado_yellow = 'rgba(255, 199, 17, 0.8)';
export const mikado_yellow_fill = 'rgba(255, 199, 17, 0.15)';
export const sunglow = 'rgba(255, 213, 45,0.8)';
export const sunglow_fill = 'rgba(255, 213, 45,0.15)';
export const gargoyle_gas = 'rgba(255, 228, 73,0.8)';
export const gargoyle_gas_fill = 'rgba(255, 228, 73,0.15)';
export const crayola_yellow = 'rgba(255, 238, 126,0.8)';
export const crayola_yellow_fill = 'rgba(255, 238, 126,0.15)';
export const yellow = 'rgba(255, 253, 14,0.8)';
export const yellow_fill = 'rgba(255, 253, 14,0.15)';
export const vivid_yellow = 'rgba(255, 236, 23,0.8)';
export const vivid_yellow_fill = 'rgba(255, 236, 23,0.15)';
export const orange = 'rgba(255,128,0,0.9)';
export const orange_fill = 'rgba(255,128,0,0.15)';
export const blue = 'rgba(0,0,255,0.9)';
export const blue_fill = 'rgba(0,0,255,0.15)';
export const white = 'rgba(255,255,255,0.8)';
export const white_fill = 'rgba(255,255,255,0.15)';
export const bright_gray = 'rgba(238, 238, 238,0.8)';
export const bright_gray_fill = 'rgba(238, 238, 238,0.15)';
export const silver = 'rgba(204, 204, 204,0.8)';
export const silver_fill = 'rgba(204, 204, 204,0.15)';
export const spanish_gray = 'rgba(153, 153, 153,0.8)';
export const spanish_gray_fill = 'rgba(153, 153, 153,0.15)';
export const granite_gray = 'rgba(102, 102, 102,0.8)';
export const granite_gray_fill = 'rgba(102, 102, 102,0.15)';
export const dark_charcoal = 'rgba(51, 51, 51,0.8)';
export const dark_charcoal_fill = 'rgba(51, 51, 51,0.15)';

export const colorMap = {
  solar: {
    kW: {
      color: mikado_yellow,
      fill: mikado_yellow_fill
    },
    kVAR: {
      color: sunglow,
      fill: sunglow_fill
    },
    kVA: {
      color: gargoyle_gas,
      fill: gargoyle_gas_fill
    },
    VLN: {
      color: crayola_yellow,
      fill: crayola_yellow_fill
    },
    VLL: {
      color: yellow,
      fill: yellow_fill
    },
    A: {
      color: vivid_yellow,
      fill: vivid_yellow_fill
    }
  },
  meter: {
    kW: {
      color: white,
      fill: white_fill
    },
    kVAR: {
      color: bright_gray,
      fill: bright_gray_fill
    },
    kVA: {
      color: silver,
      fill: silver_fill
    },
    VLN: {
      color: spanish_gray,
      fill: spanish_gray_fill
    },
    VLL: {
      color: granite_gray,
      fill: granite_gray_fill
    },
    A: {
      color: dark_charcoal,
      fill: dark_charcoal_fill
    }
  },
  battery: {
    kW: {
      color: bright_green,
      fill: bright_green_fill
    },
    kVAR: {
      color: la_salle_green,
      fill: la_salle_green_fill
    },
    kVA: {
      color: web_forest_green,
      fill: web_forest_green_fill
    },
    VLN: {
      color: may_green,
      fill: may_green_fill
    },
    VLL: {
      color: crayola_green,
      fill: crayola_green_fill
    },
    A: {
      color: dark_sea_green,
      fill: dark_sea_green_fill
    }
  },
  busdispenser: {
    kW: {
      color: bright_green,
      fill: bright_green_fill
    },
    kVAR: {
      color: la_salle_green,
      fill: la_salle_green_fill
    },
    kVA: {
      color: web_forest_green,
      fill: web_forest_green_fill
    },
    VLN: {
      color: may_green,
      fill: may_green_fill
    },
    VLL: {
      color: crayola_green,
      fill: crayola_green_fill
    },
    A: {
      color: dark_sea_green,
      fill: dark_sea_green_fill
    }
  },
  bus: { color: 'blue', fill: '#FFFF00' },
  transformer: {
    kW: {
      color: white,
      fill: white_fill
    },
    kVAR: {
      color: bright_gray,
      fill: bright_gray_fill
    },
    kVA: {
      color: silver,
      fill: silver_fill
    },
    VLN: {
      color: spanish_gray,
      fill: spanish_gray_fill
    },
    VLL: {
      color: granite_gray,
      fill: granite_gray_fill
    },
    A: {
      color: dark_charcoal,
      fill: dark_charcoal_fill
    }
  },
  minitransformer: {
    kW: {
      color: white,
      fill: white_fill
    },
    kVAR: {
      color: bright_gray,
      fill: bright_gray_fill
    },
    kVA: {
      color: silver,
      fill: silver_fill
    },
    VLN: {
      color: spanish_gray,
      fill: spanish_gray_fill
    },
    VLL: {
      color: granite_gray,
      fill: granite_gray_fill
    },
    A: {
      color: dark_charcoal,
      fill: dark_charcoal_fill
    }
  },
  irradiance: {
    kW: {
      color: orange,
      fill: orange_fill
    },
    kVAR: {
      color: orange,
      fill: orange_fill
    },
    kVA: {
      color: orange,
      fill: orange_fill
    },
    VLN: {
      color: orange,
      fill: orange_fill
    },
    VLL: {
      color: orange,
      fill: orange_fill
    },
    A: {
      color: orange,
      fill: orange_fill
    },
    color: {
      orange
    }
  },
  cooler: {
    kW: {
      color: blue,
      fill: blue_fill
    },
    kVAR: {
      color: blue,
      fill: blue_fill
    },
    kVA: {
      color: blue,
      fill: blue_fill
    },
    VLN: {
      color: blue,
      fill: blue_fill
    },
    VLL: {
      color: blue,
      fill: blue_fill
    },
    A: {
      color: blue,
      fill: blue_fill
    },
    color: {
      blue
    }
  },
  pdu: {
    kW: {
      color: white,
      fill: white_fill
    },
    kVAR: {
      color: bright_gray,
      fill: bright_gray_fill
    },
    kVA: {
      color: silver,
      fill: silver_fill
    },
    VLN: {
      color: spanish_gray,
      fill: spanish_gray_fill
    },
    VLL: {
      color: granite_gray,
      fill: granite_gray_fill
    },
    A: {
      color: dark_charcoal,
      fill: dark_charcoal_fill
    }
  },
  load: { color: 'orange', fill: '#FFFF00' },
  regulate: { color: teal, fill: 'rgba(0,255,255,0.1)' }
};
