import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { EssRackContext } from './essRackContext.js';

// eslint-disable-next-line no-empty-pattern
export default React.memo(function BmuMetalRack({}) {
  const { essConfig } = useContext(EssRackContext);

  const MetalRackTableCol = ({ row }) => (
    <div className='bmu-metal-rack-row'>
      {Array.from(Array(essConfig.numCols + 1).keys())
        .slice(1)
        .map((col) => {
          return (
            <div className='bmu-rack-slot' key={col}>
              <span className='ess-metal-rack-slot-label'>{row}</span>
            </div>
          );
        })}
    </div>
  );

  MetalRackTableCol.propTypes = {
    row: PropTypes.number
  };

  const MetalRackTable = () => (
    <div className='bmu-metal-rack-table-layout-fixed'>
      {Array.from(Array(essConfig.numRows + 1).keys())
        .slice(1)
        .sort((a, b) => {
          return b - a;
        })
        .map((row) => {
          return <MetalRackTableCol row={row} key={row} />;
        })}
    </div>
  );

  return (
    <div className='bmu-metal-rack'>
      <MetalRackTable />
    </div>
  );
});
