import React from 'react';
import PropTypes from 'prop-types';

export function Label({ xaxis, yaxis, name }) {
  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  return (
    <div className='apparent-module-label' style={customStyle}>
      {name}
    </div>
  );
}

Label.propTypes = {
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  name: PropTypes.string
};
