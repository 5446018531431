import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { EssOverview } from '../ess/essOverview.js';

export const BatteryView = ({
  dataSource, // data source object
  containerSize,
  essProps, // e.g. {ulid: x, name: '', numRacks, ...} can be undefined or null
  viewProps
}) => {
  const selectedUlids = viewProps.selectedUlidsState?.[0];
  const [viewMode, setViewMode] = viewProps.viewModeState;
  const selectedUlid = selectedUlids?.[0];
  const [essConfig, setEssConfig] = useState(
    essProps || {
      ulid: selectedUlid,
      name: '',
      numRacks: 0,
      numCols: 0,
      numRows: 0,
      configurable: false
    }
  );

  const rackConfigHandler = (ulid, numRacks, numRows, numCols, name) => {
    const newName = name != null ? name : essConfig.name;
    setEssConfig({
      ulid: ulid,
      name: newName,
      numRacks: numRacks,
      numRows: numRows,
      numCols: numCols
    });
  };

  useEffect(() => {
    if (selectedUlids?.length > 0)
      dataSource.fetchEssConfig(selectedUlids[0], rackConfigHandler);
  }, [selectedUlids]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <div className='flex-container'>
        <EssOverview
          essProps={essConfig}
          dataSource={dataSource}
          viewMode={viewMode}
          setViewMode={setViewMode}
          containerSize={containerSize}
        />
      </div>
    </div>
  );
};

BatteryView.propTypes = {
  dataSource: PropTypes.object, // data source object
  containerSize: PropTypes.object,
  essProps: PropTypes.object, // e.g. {ulid: x, name: '', numRacks, ...} can be undefined or null
  viewProps: PropTypes.object
};
