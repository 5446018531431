import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'luxon';
import 'chartjs-adapter-luxon';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement
} from 'chart.js';

import {
  intervalMap,
  TIME_FRAME_BILLING,
  TIME_FRAME_MAX,
  TIME_FRAME_MONTH,
  TIME_FRAME_YEAR
} from './constants';
import { Bar } from 'react-chartjs-2';
import Annotation from 'chartjs-plugin-annotation';
import { debounce } from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  Annotation
);

export const EnergyChart = ({
  dataSource,
  chartRef,
  selectedTimeFrame,
  updateTouState,
  minXState,
  maxXState,
  annotations,
  style,
  perspective,
  meterIds,
  timezone,
  energyStream = false,
  siteId = false
}) => {
  const [updateTOU, setUpdateTOU] = updateTouState;
  const [minX, setMinX] = minXState;
  const [maxX, setMaxX] = maxXState;
  const [selectedSolar, setSelectedSolar] = useState([]);
  const [selectedGrid, setSelectedGrid] = useState([]);
  const [selectedBatteryCharge, setSelectedBatteryCharge] = useState([]);
  const [selectedBatteryDischarge, setSelectedBatteryDischarge] = useState([]);
  const [solarTotal, setSolarTotal] = useState(0);
  const [pccTotal, setPccTotal] = useState(0);
  const [batteryChargeTotal, setBatteryChargeTotal] = useState(0);
  const [batteryDischargeTotal, setBatteryDischargeTotal] = useState(0);
  const [loadFactor, setLoadFactor] = useState(0);

  const responseHandler = (data, totals = false, loadFactor = false) => {
    if (totals) {
      setSolarTotal(data['solarTotal']);
      setPccTotal(data['pccTotal']);
      setBatteryChargeTotal(data['batteryChargeTotal']);
      setBatteryDischargeTotal(data['batteryDischargeTotal']);
    } else if (loadFactor) {
      setLoadFactor(data['loadFactor']);
    } else {
      setSelectedSolar(data['solar']);
      setSelectedGrid(data['pcc']);
      setSelectedBatteryCharge(data['batteryCharge']);
      setSelectedBatteryDischarge(data['batteryDischarge']);
    }
  };

  const totalsHandler = (data) => {
    setSolarTotal(data['solar']);
    setPccTotal(data['pcc']);
    setBatteryChargeTotal(data['batteryCharge']);
    setBatteryDischargeTotal(data['batteryDischarge']);
  };

  const loadFactorHandler = (data) => {
    setLoadFactor(data['loadFactor']);
  };

  const getSuggestedMax = () => {
    return 0.05;
  };

  const fetchData = () => {
    if (selectedTimeFrame == TIME_FRAME_YEAR) {
      dataSource.fetchIntervalYearly(
        minX,
        maxX,
        perspective,
        siteId,
        timezone,
        responseHandler
      );
    } else if (siteId) {
      dataSource.fetchIntervalInfo(
        minX,
        maxX,
        intervalMap[selectedTimeFrame],
        perspective,
        siteId,
        timezone,
        responseHandler
      );
    } else {
      dataSource.fetchIntervalInfo(
        minX,
        maxX,
        intervalMap[selectedTimeFrame],
        perspective,
        meterIds,
        responseHandler
      );
    }

    if (!energyStream) {
      dataSource.fetchTotalInfo(
        minX,
        maxX,
        perspective,
        meterIds,
        totalsHandler
      );
      dataSource.fetchLoadFactor(minX, maxX, meterIds, loadFactorHandler);
    }
  };

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchData();
    }, 200);
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, [minX, maxX]);

  const kwhData = {
    datasets: [
      {
        label: 'Solar',
        data: selectedSolar,
        borderColor: 'rgb(255,255,0)',
        backgroundColor: 'rgb(255,255,0, 0.5)',
        borderWidth: style.borderWidth,
        borderSkipped: false,
        barPercentage: 1,
        stack: 'y-axis-1',
        yAxisID: 'y',
        order: 2
      },
      {
        label: 'Battery Discharge',
        data: selectedBatteryDischarge,
        borderColor: 'rgb(124,252,0)',
        backgroundColor: 'rgb(124,252,0,0.5)',
        barPercentage: 1,
        borderWidth: style.borderWidth,
        borderSkipped: false,
        stack:
          selectedTimeFrame == TIME_FRAME_YEAR ||
          selectedTimeFrame == TIME_FRAME_MONTH
            ? 'y-axis-2'
            : 'y-axis-1',
        yAxisID: 'y',
        order: 2
      },
      {
        label: 'Battery Charge',
        data: selectedBatteryCharge,
        borderColor: 'rgb(255,105,180)',
        backgroundColor: 'rgb(255,105,180, 0.5)',
        borderWidth: style.borderWidth,
        borderSkipped: false,
        barPercentage: 1,
        stack:
          selectedTimeFrame == TIME_FRAME_YEAR ||
          selectedTimeFrame == TIME_FRAME_MONTH
            ? 'y-axis-2'
            : 'y-axis-1',
        yAxisID: 'y',
        order: 2
      },
      {
        label: 'Grid',
        data: selectedGrid,
        borderColor: 'rgb(192,192,192)',
        backgroundColor: 'rgb(192,192,192, 0.5)',
        borderWidth: style.borderWidth,
        borderSkipped: false,
        barPercentage: 1,
        stack: 'y-axis-1',
        yAxisID: 'y',
        order: 2
      }
    ]
  };

  const kwhOptions = {
    maintainAspectRatio: false,
    plugins: {
      annotation: {
        annotations: annotations
          ? [
              ...annotations.now,
              ...annotations.month,
              ...annotations.touRegions,
              ...annotations.startOfMonth
            ]
          : []
      },
      legend: {
        title: {
          display: true,
          padding: { top: -40, bottom: 80 },
          position: 'center',
          text: () => {
            if (chartRef && chartRef.current) {
              return [
                'Totals',
                'Load Factor: ' +
                  new Number((loadFactor * 100).toFixed(3)).toLocaleString(
                    'en-US'
                  ) +
                  '%',
                'Solar: ' +
                  new Number(solarTotal.toFixed(3)).toLocaleString('en-US') +
                  ' kWh',
                'Discharge: ' +
                  new Number(batteryDischargeTotal.toFixed(3)).toLocaleString(
                    'en-US'
                  ) +
                  ' kWh',
                'Charge: ' +
                  new Number(batteryChargeTotal.toFixed(3)).toLocaleString(
                    'en-US'
                  ) +
                  ' kWh',
                'Grid: ' +
                  new Number(pccTotal.toFixed(3)).toLocaleString('en-US') +
                  ' kWh'
              ];
            }
          },
          color: style.color,
          font: {
            size: 13
          }
        },
        position: 'right',
        labels: {
          padding: 5,
          font: { size: 13 },
          color: style.color
        }
      },
      customBackgroundColor: {
        color: style.backgroundColor
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          onPanComplete({ chart }) {
            setMinX(new Date(chart.scales.x.min));
            setMaxX(new Date(chart.scales.x.max));
          }
        },
        zoom: {
          wheel: {
            enabled: true
          },
          mode: 'x',
          async onZoomComplete({ chart }) {
            setMinX(new Date(chart.scales.x.min));
            setMaxX(new Date(chart.scales.x.max));
          }
        }
      },
      tooltip: {
        interaction: {
          mode: 'index'
        },
        titleAlign: 'center',
        callbacks: {
          title: (item) => {
            if (selectedTimeFrame == TIME_FRAME_YEAR) {
              let date = item[0].parsed['x'];
              return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long'
              });
            }
            if (
              selectedTimeFrame == TIME_FRAME_BILLING ||
              selectedTimeFrame == TIME_FRAME_MONTH ||
              selectedTimeFrame == TIME_FRAME_MAX
            ) {
              let date = item[0].parsed['x'];
              return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              });
            }
            return item[0].label;
          },
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              if (context.dataset.label === 'State of Charge') {
                label +=
                  new Number(context.parsed.y.toFixed(3)).toLocaleString(
                    'en-US'
                  ) + '%';
              } else {
                label +=
                  new Number(context.parsed.y.toFixed(3)).toLocaleString(
                    'en-US'
                  ) + ' kWh';
              }
            }
            return label;
          }
        }
      }
    },
    onResize: () => {
      setTimeout(() => {
        setUpdateTOU(!updateTOU);
      }, 100);
    },
    responsive: true,
    transitions: {
      zoom: {
        animation: {
          duration: 0
        }
      }
    },
    animation: false,
    scales: {
      x: {
        stacked: true,
        offset: false,
        min: minX,
        max: maxX,
        type: 'time',
        time: {
          unit: () => {
            if (maxX.getTime() - minX.getTime() > 24 * 60 * 60 * 1000) {
              if (selectedTimeFrame == TIME_FRAME_YEAR) {
                return 'month';
              } else {
                return 'day';
              }
            } else {
              return 'hour';
            }
          },
          displayFormats: {
            month: 'MMM, y',
            day: 'EEE - d',
            hour: 'h a'
          },
          stepSize: 1
        },
        adapters: {
          date: {
            zone: timezone
          }
        },
        grid: {
          display: false,
          drawTicks: false,
          offset: false,
          drawOnChartArea: true,
          color: style.gridColor
        },
        ticks: {
          // https://stackoverflow.com/questions/71733581/how-to-introduce-newline-character-in-xaxes-label-with-chart-js
          callback: (tick) => {
            return tick.includes('-')
              ? tick.split('-')
              : tick == '12 PM'
              ? 'Noon'
              : tick;
          },
          maxTicksLimit: selectedTimeFrame == TIME_FRAME_YEAR ? 13 : 8,
          font: { size: 12 },
          color: style.color,
          includeBounds: false,
          align: 'center'
        }
      },
      y: {
        stacked: true,
        suggestedMax: getSuggestedMax(),
        grid: {
          color: style.gridColor,
          drawTicks: false
        },
        display: true,
        position: 'left',
        ticks: {
          callback: (value) => {
            return (
              new Number(value.toFixed(2)).toLocaleString('en-US') + ' kWh'
            );
          },
          font: {
            size: 12
          },
          color: style.color
        },
        type: 'linear'
      }
    }
  };

  return <Bar ref={chartRef} options={kwhOptions} data={kwhData} />;
};

EnergyChart.propTypes = {
  dataSource: PropTypes.object.isRequired,
  chartRef: PropTypes.object.isRequired,
  selectedTimeFrame: PropTypes.string.isRequired,
  updateTouState: PropTypes.array.isRequired,
  minXState: PropTypes.array.isRequired,
  maxXState: PropTypes.array.isRequired,
  annotations: PropTypes.object,
  style: PropTypes.object.isRequired,
  perspective: PropTypes.number.isRequired,
  meterIds: PropTypes.array,
  timezone: PropTypes.string,
  siteId: PropTypes.string,
  energyStream: PropTypes.bool
};
