import axios from 'axios';

// used for loading a local layout when creating new layouts
export const fetchLocalLayout = (selectedLayout, layoutHandler) => {
  const url = [window.location, 'layouts', selectedLayout].join('/');
  const config = {
    headers: { 'Content-Type': 'application/json' },
    timeout: 2000
  };
  axios
    .get(url, config)
    .then((response) => {
      if (response.status === 200) {
        console.log('layout retrieval success');
      } else {
        console.log('layout retrieval failure');
        return;
      }

      layoutHandler(response.data);
    })
    .catch((e) => {
      if (e.response) {
        console.log(e.response);
      } else if (e.request) {
        console.log(e.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', e.message);
      }
      console.log(e.config);
    });
};

export class EnergyStreamDataSource {
  constructor(apiHost, setLoggedIn) {
    this.interval = 1000;
    this.weatherInterval = this.interval * 30;
    this.host = apiHost;
    this.intervalID = 0;
    this.intervalIDMap = {};
    this.fetch = (ulids, responseHandler) => {
      if (ulids.length !== 0) {
        const url = this.host + '/api/readings?ulids=' + ulids.join(',');
        const config = { timeout: 2000 };
        axios
          .get(url, config)
          .then((response) => {
            if (response.status === 200) {
              responseHandler({ status: 'success', data: response.data });
            } else {
              responseHandler({ status: 'logout', data: response.message });
            }
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status === 401) setLoggedIn(false);
              console.log(e.response);
            } else if (e.request) {
              console.log(e.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', e.message);
            }
            console.log(e.config);
          });
      }
    };
    this.fetchWeather = (ulids, responseHandler) => {
      if (ulids.length !== 0) {
        const url = this.host + '/api/weather-data?ulids=' + ulids.join(',');
        const config = { timeout: 2000 };
        axios
          .get(url, config)
          .then((response) => {
            if (response.status === 200) {
              responseHandler({ status: 'success', data: response.data });
            } else {
              responseHandler({ status: 'logout', data: response.message });
            }
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status === 401) setLoggedIn(false);
              console.log(e.response);
            } else if (e.request) {
              console.log(e.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', e.message);
            }
            console.log(e.config);
          });
      }
    };
    this.fetchLayout = (selectedLayout, layoutHandler) => {
      const url = [this.host, 'api/layouts', selectedLayout].join('/');
      const config = {
        headers: { 'Content-Type': 'application/json' },
        timeout: 2000
      };
      axios
        .get(url, config)
        .then((response) => {
          if (response.data.success) {
            layoutHandler(JSON.parse(response.data.layout));
          } else {
            alert(response.data.message);
          }
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) setLoggedIn(false);
            console.log(e.response);
          } else if (e.request) {
            console.log(e.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', e.message);
          }
          console.log(e.config);
        });
    };

    this.fetchEssReadings = (
      ulid,
      rackNumber,
      bmuDataHandler,
      inverterDataHandler
    ) => {
      const url =
        this.host + `/api/ess-readings?ulid=${ulid}&rack_number=${rackNumber}`;

      const config = { headers: { 'Content-Type': 'application/json' } };
      axios
        .get(url, config)
        .then((response) => {
          if (response.data.success) {
            // make sure the json actually has data then call the
            // data handlers
            if (response.data.json) {
              bmuDataHandler(JSON.stringify(response.data.json.bmu));
              inverterDataHandler(JSON.stringify(response.data.json.inverter));
            } else {
              console.log(response.data.message);
            }
          } else {
            alert(response.data.message);
          }
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) setLoggedIn(false);
            console.log(e.response);
          } else if (e.request) {
            console.log(e.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error:', e.message);
          }
          console.log(e.config);
        });
    };

    this.registerEssReadingsHandler = (
      essConfig,
      rackNumber,
      bmuDataHandler,
      inverterDataHandler
    ) => {
      clearInterval(this.intervalID);
      this.fetchEssReadings(
        essConfig.ulid,
        rackNumber,
        bmuDataHandler,
        inverterDataHandler
      );
      this.intervalID = setInterval(
        this.fetchEssReadings.bind(this),
        10000,
        essConfig.ulid,
        rackNumber,
        bmuDataHandler,
        inverterDataHandler
      );
    };

    this.registerMultipleEssReadingsHandler = (
      essConfig,
      rackNumber,
      bmuDataHandler,
      inverterDataHandler
    ) => {
      clearInterval(this.intervalIDMap[rackNumber]);
      this.fetchEssReadings(
        essConfig.ulid,
        rackNumber,
        bmuDataHandler,
        inverterDataHandler
      );
      this.intervalIDMap[rackNumber] = setInterval(
        this.fetchEssReadings.bind(this),
        10000,
        essConfig.ulid,
        rackNumber,
        bmuDataHandler,
        inverterDataHandler
      );
    };

    this.closeAll = () => {
      Object.keys(this.intervalIDMap).forEach((key) => {
        clearInterval(this.intervalIDMap[key]);
      });
    };

    this.close = () => {
      clearInterval(this.intervalID);
    };

    this.fetchEssConfig = (ulid, essConfigHandler) => {
      const url = this.host + `/api/ess-rack-config?ulid=${ulid}`;
      const config = { headers: { 'Content-Type': 'application/json' } };
      axios
        .get(url, config)
        .then((response) => {
          if (response.status === 200) {
            // make sure there is data
            if (
              response.data.num_racks &&
              response.data.num_rows &&
              response.data.num_cols
            ) {
              let name = null;
              if (response.data.name) {
                name = response.data.name;
              }
              essConfigHandler(
                ulid,
                response.data.num_racks,
                response.data.num_rows,
                response.data.num_cols,
                name
              );
            } else {
              console.log(response.data.message);
            }
          } else {
            console.log(response.data.message);
          }
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 401) setLoggedIn(false);
            console.log(e.response);
          } else if (e.request) {
            console.log(e.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', e.message);
          }
          console.log(e.config);
        });
    };

    this.fetchTruckSOC = (vin, responseHandler) => {
      const url = this.host + '/api/fetch-truck-soc';
      axios
        .get(url, {
          params: {
            vin: vin
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        })
        .then((response) => {
          if (response.status === 200) {
            responseHandler(
              response.data['signals'][0]['xev_battery_state_of_charge'][
                'value'
              ]
            );
          } else {
            responseHandler({ status: 'failed', data: response.message });
          }
        })
        .catch((e) => {
          responseHandler({ status: 'error', data: e.response });
        });
    };

    this.fetchBatterySOC = (start, end, siteId, responseHandler) => {
      const url = this.host + '/api/fetch-battery-soc';
      axios
        .get(url, {
          params: {
            start: new Date(start).toLocaleString('en-US', { timeZone: 'UTC' }),
            end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
            siteId: siteId
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 20000
        })
        .then((response) => {
          if (response.status === 200) {
            let soc_data = response.data;
            let battery_soc = soc_data.map((r) => {
              return { ...r, x: new Date(r['x'] + 'Z') };
            });
            responseHandler(battery_soc);
          } else {
            responseHandler({ status: 'failed', data: response.message });
          }
        })
        .catch((e) => {
          responseHandler({ status: 'error', data: e.response });
        });
    };
    // gets accumulated data and takes the difference to get kW/h produced/used over given interval
    this.fetchIntervalInfo = (
      start,
      end,
      interval,
      perspective,
      siteId,
      timezone,
      responseHandler,
      total = false
    ) => {
      const url = this.host + '/api/fetch-interval-info';
      axios
        .get(url, {
          params: {
            start: new Date(start).toLocaleString('en-US', { timeZone: 'UTC' }),
            end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
            siteId: siteId,
            interval: interval
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 20000
        })
        .then((response) => {
          if (response.status === 200) {
            let solar_data =
              'solar' in response.data ? response.data['solar']['map'] : [];
            let battery_charge_data =
              'batteryCharge' in response.data
                ? response.data['batteryCharge']['map']
                : [];
            let battery_discharge_data =
              'batteryDischarge' in response.data
                ? response.data['batteryDischarge']['map']
                : [];
            let pcc_data =
              'pcc' in response.data ? response.data['pcc']['map'] : [];
            let solar = solar_data.map((r) => {
              return {
                ...r,
                x: new Date(r['x'] + 'Z'),
                y:
                  Math.abs(r['y']) < 100000
                    ? total
                      ? Math.abs(r['y'])
                      : r['y'] * -1
                    : 0
              };
            });
            let batteryCharge = battery_charge_data.map((r) => {
              return {
                ...r,
                x: new Date(r['x'] + 'Z'),
                y:
                  Math.abs(r['y']) < 100000
                    ? total
                      ? Math.abs(r['y'])
                      : r['y'] * -1
                    : 0
              };
            });
            let batteryDischarge = battery_discharge_data.map((r) => {
              return {
                ...r,
                x: new Date(r['x'] + 'Z'),
                y:
                  Math.abs(r['y']) < 100000
                    ? total
                      ? Math.abs(r['y'])
                      : r['y'] * -1
                    : 0
              };
            });
            let pcc = pcc_data.map((r) => {
              return {
                ...r,
                x: new Date(r['x'] + 'Z'),
                y:
                  Math.abs(r['y']) < 100000
                    ? total
                      ? Math.abs(r['y'])
                      : r['y'] * -1
                    : 0
              };
            });
            let data = {
              solar: solar,
              batteryCharge: batteryCharge,
              batteryDischarge: batteryDischarge,
              pcc: pcc
            };
            let totalData = {
              solarTotal: response.data['solar']
                ? response.data['solar']['total'] * -1
                : 0,
              batteryChargeTotal: response.data['batteryCharge']
                ? response.data['batteryCharge']['total'] * -1
                : 0,
              batteryDischargeTotal: response.data['batteryDischarge']
                ? response.data['batteryDischarge']['total'] * -1
                : 0,
              pccTotal: response.data['pcc']
                ? response.data['pcc']['total'] * -1
                : 0
            };
            let loadFactorData = {
              loadFactor: response.data['pcc']
                ? response.data['pcc']['loadFactor']
                : 0
            };
            if (total) {
              let total_use = [];
              let i = 0;
              while (
                i <
                Math.max(
                  solar_data.length,
                  pcc_data.length,
                  battery_charge_data.length,
                  battery_discharge_data.length
                )
              ) {
                let value = 0;
                let date = null;
                if (i < solar_data.length) {
                  date = solar_data[i]['x'];
                  value += Math.abs(solar_data[i]['y']);
                }
                if (i < pcc_data.length) {
                  date = pcc_data[i]['x'];
                  value += Math.abs(pcc_data[i]['y']);
                }
                if (i < battery_charge_data.length) {
                  date = battery_charge_data[i]['x'];
                  value += Math.abs(battery_charge_data[i]['y']);
                }
                if (i < battery_discharge_data.length) {
                  date = battery_discharge_data[i]['x'];
                  value += Math.abs(battery_discharge_data[i]['y']);
                }
                total_use.push({ x: new Date(date + 'Z'), y: value });
                i += 1;
              }
              data['total'] = total_use;
              responseHandler(data);
            } else {
              responseHandler(data);
              responseHandler(totalData, true);
              responseHandler(loadFactorData, false, true);
            }
          } else {
            responseHandler({ status: 'failed', data: response.message });
          }
        })
        .catch((e) => {
          responseHandler({ status: 'error', data: e.response });
        });
    };

    this.fetchIntervalYearly = (
      start,
      end,
      perspective,
      siteId,
      timezone,
      responseHandler,
      total = false
    ) => {
      const url = this.host + '/api/fetch-interval-yearly';
      axios
        .get(url, {
          params: {
            start: new Date(start).toLocaleString('en-US', { timeZone: 'UTC' }),
            end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
            siteId: siteId,
            timezone: timezone
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 20000
        })
        .then((response) => {
          if (response.status === 200) {
            let solar_data =
              'solar' in response.data ? response.data['solar']['map'] : [];
            let battery_charge_data =
              'batteryCharge' in response.data
                ? response.data['batteryCharge']['map']
                : [];
            let battery_discharge_data =
              'batteryDischarge' in response.data
                ? response.data['batteryDischarge']['map']
                : [];
            let pcc_data =
              'pcc' in response.data ? response.data['pcc']['map'] : [];
            let solar = solar_data.map((r) => {
              return {
                ...r,
                x: new Date(r['x']),
                y: total ? Math.abs(r['y']) : r['y'] * -1
              };
            });
            let batteryCharge = battery_charge_data.map((r) => {
              return {
                ...r,
                x: new Date(r['x']),
                y: total ? Math.abs(r['y']) : r['y'] * -1
              };
            });
            let batteryDischarge = battery_discharge_data.map((r) => {
              return {
                ...r,
                x: new Date(r['x']),
                y: total ? Math.abs(r['y']) : r['y'] * -1
              };
            });
            let pcc = pcc_data.map((r) => {
              return {
                ...r,
                x: new Date(r['x']),
                y: total ? Math.abs(r['y']) : r['y'] * -1
              };
            });
            let data = {
              solar: solar,
              batteryCharge: batteryCharge,
              batteryDischarge: batteryDischarge,
              pcc: pcc
            };
            let totalData = {
              solarTotal: response.data['solar']
                ? response.data['solar']['total'] * -1
                : 0,
              batteryChargeTotal: response.data['batteryCharge']
                ? response.data['batteryCharge']['total'] * -1
                : 0,
              batteryDischargeTotal: response.data['batteryDischarge']
                ? response.data['batteryDischarge']['total'] * -1
                : 0,
              pccTotal: response.data['pcc']
                ? response.data['pcc']['total'] * -1
                : 0
            };
            let loadFactorData = {
              loadFactor: response.data['pcc']
                ? response.data['pcc']['loadFactor']
                : 0
            };
            if (total) {
              let total_use = [];
              let i = 0;
              while (
                i <
                Math.max(
                  solar_data.length,
                  pcc_data.length,
                  battery_charge_data.length,
                  battery_discharge_data.length
                )
              ) {
                let value = 0;
                let date = null;
                if (i < solar_data.length) {
                  date = solar_data[i]['x'];
                  value += Math.abs(solar_data[i]['y']);
                }
                if (i < pcc_data.length) {
                  date = pcc_data[i]['x'];
                  value += Math.abs(pcc_data[i]['y']);
                }
                if (i < battery_charge_data.length) {
                  date = battery_charge_data[i]['x'];
                  value += Math.abs(battery_charge_data[i]['y']);
                }
                if (i < battery_discharge_data.length) {
                  date = battery_discharge_data[i]['x'];
                  value += Math.abs(battery_discharge_data[i]['y']);
                }
                total_use.push({ x: new Date(date), y: value });
                i += 1;
              }
              data['total'] = total_use;
              responseHandler(data);
            } else {
              responseHandler(data);
              responseHandler(totalData, true);
              responseHandler(loadFactorData, false, true);
            }
          } else {
            responseHandler({ status: 'failed', data: response.message });
          }
        })
        .catch((e) => {
          responseHandler({ status: 'error', data: e.response });
        });
    };

    this.fetchProjectedGridInfo = (
      start,
      end,
      interval,
      siteId,
      responseHandler,
      costKWH
    ) => {
      const url = this.host + '/api/fetch-projected-grid-info';
      axios
        .get(url, {
          params: {
            start: new Date(start).toLocaleString('en-US', { timeZone: 'UTC' }),
            end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
            siteId: siteId,
            interval: interval,
            costKWH: costKWH
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 20000
        })
        .then((response) => {
          if (response.status === 200) {
            let projectedGridData = response.data['map'];
            let projectedGrid = projectedGridData.map((r) => {
              return {
                ...r,
                x: new Date(r['x'] + 'Z'),
                y: Math.abs(r['y']) < 100000 ? r['y'] * -1 : 0
              };
            });
            let data = {
              projectedGrid: projectedGrid,
              projectedGridTotal: response.data['total'] * -1,
              loadFactor: response.data['loadFactor'],
              projectedGridCost: response.data['totalCost']
            };
            responseHandler(data);
          } else {
            responseHandler({ status: 'failed', data: response.message });
          }
        })
        .catch((e) => {
          responseHandler({ status: 'error', data: e.response });
        });
    };

    this.fetchProjectedGridYearly = (
      start,
      end,
      siteId,
      responseHandler,
      costKWH
    ) => {
      const url = this.host + '/api/fetch-projected-grid-yearly';
      axios
        .get(url, {
          params: {
            start: new Date(start).toLocaleString('en-US', { timeZone: 'UTC' }),
            end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
            siteId: siteId,
            costKWH: costKWH
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 20000
        })
        .then((response) => {
          if (response.status === 200) {
            let projectedGridData = response.data['map'];
            let projectedGrid = projectedGridData.map((r) => {
              return {
                ...r,
                x: new Date(r['x']),
                y: r['y'] * -1
              };
            });
            let data = {
              projectedGrid: projectedGrid,
              projectedGridTotal: response.data['total'] * -1,
              loadFactor: response.data['loadFactor'],
              projectedGridCost: response.data['totalCost']
            };
            responseHandler(data);
          } else {
            responseHandler({ status: 'failed', data: response.message });
          }
        })
        .catch((e) => {
          responseHandler({ status: 'error', data: e.response });
        });
    };

    // gets instantaneous kW information for given time frame
    this.fetchInstantInfo = (
      start,
      end,
      perspective,
      siteId,
      timezone,
      responseHandler
    ) => {
      if (siteId !== false) {
        const url = this.host + '/api/fetch-instant-info';
        axios
          .get(url, {
            params: {
              start: new Date(start).toLocaleString('en-US', {
                timeZone: 'UTC'
              }),
              end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
              siteId: siteId
            },
            headers: { 'Content-Type': 'application/json' },
            timeout: 20000
          })
          .then((response) => {
            if (response.status === 200) {
              let solar_data =
                'solar' in response.data ? response.data['solar'] : [];
              let battery_data =
                'battery' in response.data ? response.data['battery'] : [];
              let pcc_data = 'pcc' in response.data ? response.data['pcc'] : [];
              let combined_load_data =
                'combinedLoad' in response.data
                  ? response.data['combinedLoad']
                  : [];
              let solar = solar_data.map((r) => {
                return {
                  ...r,
                  x: new Date(r['x'] + 'Z'),
                  y: Math.abs(r['y']) < 100000 ? r['y'] * -1 : 0
                };
              });
              let battery = battery_data.map((r) => {
                return {
                  ...r,
                  x: new Date(r['x'] + 'Z'),
                  y: Math.abs(r['y']) < 100000 ? r['y'] * -1 : 0
                };
              });
              let pcc = pcc_data.map((r) => {
                return {
                  ...r,
                  x: new Date(r['x'] + 'Z'),
                  y: Math.abs(r['y']) < 100000 ? r['y'] * -1 : 0
                };
              });
              let combined_load = combined_load_data.map((r) => {
                return {
                  ...r,
                  x: new Date(r['x'] + 'Z'),
                  y: Math.abs(r['y']) < 100000 ? r['y'] * -1 : 0
                };
              });
              let data = {
                solar: solar,
                battery: battery,
                pcc: pcc,
                combined_load: combined_load
              };
              responseHandler(data);
            } else {
              responseHandler({ status: 'failed', data: response.message });
            }
          })
          .catch((e) => {
            responseHandler({ status: 'error', data: e.response });
          });
      }
    };

    // gets the tou information to display
    this.fetchTOU = (start, end, siteId, responseHandler) => {
      let data = [];
      let peakStartWeekday = 0;
      let peakEndWeekday = 0;
      let peakStartWeekend = 0;
      let peakEndWeekend = 0;

      const url = this.host + '/api/fetch-tou-info';

      axios
        .get(url, {
          params: {
            start: new Date(start).toLocaleString('en-US', { timeZone: 'UTC' }),
            end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
            siteId: siteId
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        })
        .then((response) => {
          if (response.status === 200) {
            let days = response.data;
            for (let i = 0; i < days.length; i++) {
              let day = days[i];
              // 0=Monday, 1=Tuesday, ..., 5=Saturday, 6=Sunday
              if (parseInt(day['from_day_of_week']) == 0) {
                if (day['tou_name'] == 'peak') {
                  peakStartWeekday = parseInt(day['from_hour']);
                  peakEndWeekday = parseInt(day['to_hour']);
                }
              } else {
                if (day['tou_name'] == 'peak') {
                  peakStartWeekend = parseInt(day['from_hour']);
                  peakEndWeekend = parseInt(day['to_hour']);
                }
              }
            }

            data['peaks'] = [
              peakStartWeekday,
              peakEndWeekday,
              peakStartWeekend,
              peakEndWeekend
            ];
            responseHandler(data);
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    };

    // gets tou info and accumulated info to calculate amount spent/saved per kw/h
    this.fetchMoneyInfo = (
      start,
      end,
      interval,
      siteId,
      perspective,
      timezone,
      costKWH,
      responseHandler
    ) => {
      if (siteId.length !== 0) {
        const url = this.host + '/api/fetch-money-info';
        axios
          .get(url, {
            params: {
              start: new Date(start).toLocaleString('en-US', {
                timeZone: 'UTC'
              }),
              end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
              siteId: siteId,
              costKWH: costKWH,
              interval: interval
            },
            headers: { 'Content-Type': 'application/json' },
            timeout: 20000
          })
          .then((response) => {
            if (response.status === 200) {
              let solar_saved =
                'solar' in response.data ? response.data['solar']['map'] : [];
              let battery_saved =
                'battery' in response.data
                  ? response.data['battery']['map']
                  : [];
              let pcc_spent =
                'pcc' in response.data ? response.data['pcc']['map'] : [];

              let solar = solar_saved.map((r) => {
                return { ...r, x: new Date(r['x'] + 'Z') };
              });
              let battery = battery_saved.map((r) => {
                return { ...r, x: new Date(r['x'] + 'Z') };
              });
              let pcc = pcc_spent.map((r) => {
                return { ...r, x: new Date(r['x'] + 'Z') };
              });
              let data = {
                savedSolar: solar,
                savedBattery: battery,
                spent: pcc,
                totalSavedSolar:
                  'solar' in response.data
                    ? response.data['solar']['total']
                    : 0,
                totalSavedBattery:
                  'battery' in response.data
                    ? response.data['battery']['total']
                    : 0,
                totalSpent:
                  'pcc' in response.data ? response.data['pcc']['total'] : 0
              };
              responseHandler(data);
            } else {
              responseHandler({ status: 'failed', data: response.message });
            }
          })
          .catch((e) => {
            responseHandler({ status: 'error', data: e.response });
          });
      }
    };

    // gets tou info and accumulated info to calculate amount spent/saved per kw/h
    this.fetchMoneyInfoYearly = (
      start,
      end,
      siteId,
      perspective,
      timezone,
      costKWH,
      responseHandler
    ) => {
      if (siteId.length !== 0) {
        const url = this.host + '/api/fetch-money-info-yearly';
        axios
          .get(url, {
            params: {
              start: new Date(start).toLocaleString('en-US', {
                timeZone: 'UTC'
              }),
              end: new Date(end).toLocaleString('en-US', { timeZone: 'UTC' }),
              siteId: siteId,
              costKWH: costKWH
            },
            headers: { 'Content-Type': 'application/json' },
            timeout: 20000
          })
          .then((response) => {
            if (response.status === 200) {
              let solar_saved =
                'solar' in response.data ? response.data['solar']['map'] : [];
              let battery_saved =
                'battery' in response.data
                  ? response.data['battery']['map']
                  : [];
              let pcc_spent =
                'pcc' in response.data ? response.data['pcc']['map'] : [];

              let solar = solar_saved.map((r) => {
                return { ...r, x: new Date(r['x'] + 'Z') };
              });
              let battery = battery_saved.map((r) => {
                return { ...r, x: new Date(r['x'] + 'Z') };
              });
              let pcc = pcc_spent.map((r) => {
                return { ...r, x: new Date(r['x'] + 'Z') };
              });
              let data = {
                savedSolar: solar,
                savedBattery: battery,
                spent: pcc,
                totalSavedSolar:
                  'solar' in response.data
                    ? response.data['solar']['total']
                    : 0,
                totalSavedBattery:
                  'battery' in response.data
                    ? response.data['battery']['total']
                    : 0,
                totalSpent:
                  'pcc' in response.data ? response.data['pcc']['total'] : 0
              };
              responseHandler(data);
            } else {
              responseHandler({ status: 'failed', data: response.message });
            }
          })
          .catch((e) => {
            responseHandler({ status: 'error', data: e.response });
          });
      }
    };

    this.fetchIrradianceData = (ulid, responseHandler) => {
      const url = this.host + '/api/fetch-irradiance-data';

      axios
        .get(url, {
          params: {
            ulid: ulid
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        })
        .then((response) => {
          if (response.status === 200) {
            responseHandler(response.data);
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    };

    this.fetchMainMeters = (siteId, responseHandler) => {
      const url = this.host + '/api/fetch-main-meters';

      axios
        .get(url, {
          params: {
            siteId: siteId
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        })
        .then((response) => {
          if (response.status === 200) {
            responseHandler(response.data);
          } else {
            alert(response.data.message);
            responseHandler(null);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
          responseHandler(null);
        });
    };

    this.fetchBillingPeriod = (currentTime, siteId, responseHandler) => {
      const url = this.host + '/api/fetch-billing-period';

      axios
        .get(url, {
          params: {
            currentTime: new Date(currentTime).toLocaleString('en-US', {
              timeZone: 'UTC'
            }),
            siteId: siteId
          },
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        })
        .then((response) => {
          if (response.status === 200) {
            let data = [];
            data['start'] = response.data['start'];
            data['end'] = response.data['end'];
            responseHandler(data);
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    };
  }
}
