/* eslint-disable @shopify/jsx-no-complex-expressions */
import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const TimeFrameSelector = ({
  timeFrame,
  selectedTimeFrameState,
  backgroundColor,
  color
}) => {
  const [selectedTimeFrame, setSelectedTimeFrame] = selectedTimeFrameState;
  const getStyle = () => {
    return timeFrame == selectedTimeFrame
      ? {
          borderRadius: '25px',
          backgroundColor: '#337ab7',
          color: 'white',
          fontWeight: 'bold'
        }
      : {
          backgroundColor: backgroundColor,
          color: color
        };
  };

  return (
    <Button
      className='selector-btn'
      style={getStyle()}
      onClick={() => setSelectedTimeFrame(timeFrame)}
    >
      {timeFrame}
    </Button>
  );
};

TimeFrameSelector.propTypes = {
  timeFrame: PropTypes.string.isRequired,
  selectedTimeFrameState: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export const ChartSelector = ({
  chart,
  selectedChartState,
  backgroundColor,
  color,
  mobile
}) => {
  const chartMap = {
    'Energy Use': 'kWH',
    'Power Use': 'kW',
    'Total Usage': 'Total',
    'Energy Cost': '$',
    'Projected Grid': 'Proj.'
  };
  const [selectedChart, setSelectedChart] = selectedChartState;
  const getStyle = () => {
    return chart == selectedChart
      ? {
          borderRadius: '25px',
          backgroundColor: '#337ab7',
          color: 'white',
          fontWeight: 'bold',
          float: 'right'
        }
      : {
          backgroundColor: backgroundColor,
          color: color,
          float: 'right'
        };
  };

  return (
    <Button
      className='selector-btn'
      style={getStyle()}
      onClick={() => setSelectedChart(chart)}
    >
      {mobile ? chartMap[chart] : chart}
    </Button>
  );
};

ChartSelector.propTypes = {
  chart: PropTypes.string.isRequired,
  selectedChartState: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  mobile: PropTypes.bool
};
