import React, { useState } from 'react';
import PropTypes from 'prop-types';

export function VerticalConnector(props) {
  const [online] = useState(props.online === undefined ? true : props.online);

  // const width = 10
  const length = props.length * 40;
  const green = '#32CD32';
  const gray = '#474747';

  const customStyle = {
    left: `${props.xaxis * 40}px`,
    top: `${props.yaxis * 40}px`,
    height: `${length}px`,
    WebkitTransformOrigin: 'top left',
    background: `${online ? green : gray}`
  };

  if (props.deg) {
    customStyle.borderTop = `5px solid transparent`;
    customStyle.borderBottom = `5px solid transparent`;
    if (props.deg > 0) {
      customStyle.WebkitTransform = `rotate(${props.deg}deg)`;
      customStyle.WebkitTransformOrigin = '100% 0%';
    } else if (props.deg < 0) {
      customStyle.WebkitTransform = `rotate(${props.deg}deg)`;
      customStyle.WebkitTransformOrigin = '0% 0%';
    }
  }

  return <div className='vertical-connector' style={customStyle} />;
}

VerticalConnector.propTypes = {
  online: PropTypes.bool,
  length: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  deg: PropTypes.number
};
