import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const HorizontalConnector = ({ online, length, xaxis, yaxis }) => {
  const [bOnline] = useState(online === undefined ? true : online);
  const green = '#32CD32';
  const gray = '#474747';

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`,
    width: `${length * 40}px`,
    background: `${bOnline ? green : gray}`
  };

  return (
    // eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers
    <div className='horizontal-connector' style={customStyle} />
  );
};

HorizontalConnector.propTypes = {
  online: PropTypes.bool,
  length: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number
};
