/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React from 'react';
import PropTypes from 'prop-types';

import BmuCell from './bmuCell.js';

export const BmuCellInfo = ({
  column_name,
  cell_voltages,
  onlineStatus,
  bmu_id
}) => {
  return (
    <div className={`bmu-cell-info bmu-cell-info-${column_name}`}>
      <table className='bmu-cell-info-table'>
        <tbody>
          <tr className='bmu-cell-table-row bmu-cell-headers'>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header'>1</div>
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header'>4</div>
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header'>8</div>
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header'>12</div>
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header' />
            </td>
            <td className='bmu-cell-table-cell bmu-cell-table-header-td'>
              <div className='bmu-cell-header'>16</div>
            </td>
          </tr>
          <tr className='bmu-cell-table-row'>
            {Object.keys(cell_voltages).map((index) => {
              return (
                <BmuCell
                  cell_voltage={cell_voltages[index]}
                  cellNum={index + 1}
                  column_name={column_name}
                  onlineStatus={onlineStatus}
                  bmu_id={bmu_id}
                  key={index}
                />
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

BmuCellInfo.propTypes = {
  column_name: PropTypes.string,
  cell_voltages: PropTypes.array,
  onlineStatus: PropTypes.string,
  bmu_id: PropTypes.string
};
