/* eslint-disable no-unused-vars */
/* eslint-disable @shopify/jsx-prefer-fragment-wrappers */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useEffect, useState } from 'react';
import { EssOverviewRack } from './essOverviewRack';
import { EssRackBlock } from './essRackBlock';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getScale } from '../helpers/getScale';

export const EssOverview = ({
  essProps,
  dataSource,
  viewMode,
  setViewMode,
  containerSize
}) => {
  const [racks, setRacks] = useState({});
  const [overviewSelectedRack, setOverviewSelectedRack] = useState(0);
  const reloadState = useState(true);
  const [reload, setReload] = reloadState;
  const [containerHeight, setContainerHeight] = useState(250);
  const [zoom, setZoom] = useState(1);
  const [minZoom, setMinZoom] = useState(0.2);
  const [maxZoom, setMaxZoom] = useState(2.0);
  const [essRackBlockScale, setEssRackBlockScale] = useState(1);

  const ESS_COLUMN_WIDTH = 350;
  const ESS_ROW_HEIGHT = 100;
  const ESS_SDS_HEIGHT = 110;
  const [essSize, setEssSize] = useState({ width: 700, height: 1250 });

  useEffect(() => {
    const size = getSize(essProps);
    setEssSize(size);
    setEssRackBlockScale(getScale(size, containerSize));
  }, [essProps]);

  const getSize = (essProps) => {
    return {
      width:
        ESS_COLUMN_WIDTH * essProps.numCols + (essProps.configurable ? 15 : 0),
      height:
        ESS_ROW_HEIGHT * essProps.numRows +
        (essProps.configurable ? ESS_SDS_HEIGHT : 0)
    };
  };

  useEffect(() => {
    const newScale = getScale(essSize, containerSize);
    setEssRackBlockScale(newScale);
  }, [essSize, containerSize]);

  useEffect(() => {
    let rackIds = {};

    if (essProps) {
      // currently using numRacks to find rack ID
      // if rack number is ever skipped, need to find a better
      // way to determine how many/which racks to display!
      for (let i = 1; i <= essProps['numRacks']; i++) {
        if (essProps['rackIds']) {
          rackIds[i] = essProps['rackIds'][i - 1];
        } else {
          rackIds[i] = NaN;
        }
      }
    }
    setRacks(rackIds);
    let overviewHeight = Math.floor((essProps.numRacks + 1) / 2) * 250 + 100;
    setContainerHeight(overviewHeight);
    setZoom(Math.min(700 / overviewHeight, 1));
    setMinZoom(Math.min(700 / overviewHeight, 1));
    setMaxZoom(Math.min(700 / overviewHeight, 1) + 1);
  }, [essProps]);

  // useEffect(() => {
  //   if (overviewSelectedRack > 0) {
  //     dataSource.closeAll?.();
  //     setReload(false);
  //     setEssRackBlockScale(Math.min(7 / essProps.numRows, 1));
  //   }
  // }, [overviewSelectedRack]);

  const handleOverviewClick = (e) => {
    e.preventDefault();
    dataSource.close?.();
    setOverviewSelectedRack(0);
    setReload(true);
  };

  const zoomIn = () => {
    let nextZoom = Math.min(zoom + 0.2, maxZoom);
    setZoom(nextZoom);
  };

  const zoomOut = () => {
    let nextZoom = Math.max(zoom - 0.2, minZoom);
    setZoom(nextZoom);
  };

  const resetOverview = () => {
    setZoom(minZoom);
    setReload(true);
  };

  return racks ? (
    overviewSelectedRack > 0 ? (
      <div
        style={{
          transform: `scale(${essRackBlockScale})`,
          display: 'flex',
          alignItems: 'flex-start'
        }}
      >
        <Button
          style={{ position: 'absolute', margin: '20px', zIndex: 5 }}
          onClick={handleOverviewClick}
        >
          Overview
        </Button>
        {/* If we were going to add rack ID into individual rack blocks, would need to pass
            the value in here. Not sure how it would be displayed though. Do we need to 
            display it twice?
        */}
        <EssRackBlock
          essConfig={essProps}
          dataSource={dataSource}
          viewMode={viewMode}
          setViewMode={setViewMode}
          selectedRackStart={overviewSelectedRack}
        />
      </div>
    ) : (
      <div style={{ overflow: 'hidden' }}>
        <Button
          style={{
            margin: '5px',
            zIndex: 5
          }}
          onClick={zoomIn}
        >
          <i className='fa fa-search-plus' aria-hidden='true' />
        </Button>
        <Button
          style={{
            margin: '5px',
            zIndex: 5
          }}
          onClick={zoomOut}
        >
          <i className='fa fa-search-minus' aria-hidden='true' />
        </Button>
        <Button
          style={{
            margin: '5px',
            zIndex: 5
          }}
          onClick={resetOverview}
        >
          Reset
        </Button>
        <div
          style={{
            display: 'flex',
            width: '1100px',
            // height: '700px',
            perspective: '20000px',
            alignSelf: 'center',
            overflow: 'auto'
          }}
        >
          <div
            style={{
              transform: `scale(${zoom})`,
              transformOrigin: 'center top'
            }}
          >
            <div
              className='container-3d-box'
              style={{ height: `${containerHeight}px` }}
            >
              {Object.keys(racks).map((rackNum) => {
                return (
                  <EssOverviewRack
                    dataSource={dataSource}
                    setOverviewSelectedRack={setOverviewSelectedRack}
                    selectedRack={rackNum}
                    selectedRackID={Number(racks[rackNum])}
                    essConfig={essProps}
                    reload={reloadState}
                    key={rackNum}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <div>No racks to display</div>
  );
};

EssOverview.propTypes = {
  essProps: PropTypes.object,
  dataSource: PropTypes.object,
  viewMode: PropTypes.string,
  setViewMode: PropTypes.any,
  containerSize: PropTypes.object
};
