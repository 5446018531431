import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { renderReading } from '../readings.js';
import { ElectricTruck } from './electricTruck.js';
import { isOnline } from '../helpers/isOnline.js';
import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';
import { Arrow } from './arrow.js';

export const TruckCharger = ({
  ulid,
  name,
  xaxis,
  yaxis,
  truckIsPresent,
  truckSOC,
  vin,
  arrowLocation,
  dataSource,
  timezone,
  isTabletOrMobile
}) => {
  const [readings, setReadings] = useState({});
  const [truckName] = useState(name);
  const [truckReading, setDispenserReading] = useState('');
  const [batterySOC, setBatterySOC] = useState(null);
  const [selectedReading, setSelectedReading] = useState(() => {
    if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
      return localStorage.getItem('selectedReading'.concat(ulid));
    }
    return 'kw_reading';
  });
  const [online, setOnline] = useState(false);
  const [tTruckIsPresent] = useState(
    truckIsPresent === undefined ? false : truckIsPresent === 'true'
  );
  const [truckSoc, setTruckSOC] = useState(truckSOC);
  const [arrowDirection, setArrowDirection] = useState('away');

  const directionReading = 'kw_reading';

  useEffect(() => {
    fetchTruckSOC();
    subscribe(meterReadingsEventName, (e) => {
      setReadings(e.detail?.[ulid] || {});
    });
    return () => unsubscribe(meterReadingsEventName);
  }, []);

  const responseHandler = (data) => {
    setTruckSOC(data);
  };

  function fetchTruckSOC() {
    if (vin) {
      // eslint-disable-next-line react/prop-types
      dataSource.fetchTruckSOC(vin, responseHandler);
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      fetchTruckSOC();
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    let _online = false;
    if (Object.keys(readings).length !== 0) {
      _online =
        'read_at' in readings
          ? isOnline(parseInt(readings.read_at) * 1000)
          : true;
      if (selectedReading in readings)
        setDispenserReading(
          renderReading(
            selectedReading,
            setSelectedReading,
            readings,
            _online,
            timezone,
            isTabletOrMobile
          )
        );
      if (directionReading in readings) {
        if (readings[directionReading] > 0) {
          setArrowDirection('away');
        } else {
          setArrowDirection('into');
        }
      }
      if ('battery_soc' in readings) setBatterySOC(readings.battery_soc);
    }
    setOnline(_online);
  }, [readings, selectedReading]);

  function getReadingsColor() {
    if (Number.parseFloat(truckReading) >= 0) {
      return <div className='positive-readings'>{truckReading}</div>;
    } else {
      return <div className='negative-readings'>{truckReading}</div>;
    }
  }

  const getBatteryCharge = () => {
    const cells = [];
    let className = 'truck-charger-soc-cell';
    if (!online) className += ' battery-soc-offline';

    for (let cell = 0; cell < 10; cell++) {
      if (batterySOC === null || batterySOC < cell * 10 + 1) {
        cells.push(
          <div className={`${className} battery-soc-cell-grey`} key={cell} />
        );
      } else {
        if (batterySOC <= 10) {
          cells.push(
            <div className={`${className} battery-soc-cell-red`} key={cell} />
          );
        } else if (batterySOC <= 20) {
          cells.push(
            <div
              className={`${className} battery-soc-cell-yellow`}
              key={cell}
            />
          );
        } else {
          cells.push(
            <div className={`${className} battery-soc-cell-full`} key={cell} />
          );
        }
      }
    }
    return <div>{cells}</div>;
  };

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  const offlineClass = (className) => {
    return !online ? className : '';
  };

  const chargingClass = (className) => {
    return tTruckIsPresent ? className : '';
  };

  return (
    <div
      className='apparent-module-truck-charger'
      id={ulid}
      style={customStyle}
    >
      <div className={`truck-charger-name ${offlineClass('offline')}`}>
        {truckName}
      </div>
      <div
        className={`truck-charger-hbar ${offlineClass(
          'offline-border-background'
        )} ${chargingClass('charging-background')}`}
      />
      <div
        className={`truck-charger ${offlineClass(
          'offline-border-background'
        )} ${chargingClass('charging-background')}`}
      />
      <div
        className={`truck-charger-vbar ${offlineClass(
          'offline-border-background'
        )} ${chargingClass('charging-background')}`}
      />
      {/* <span className='ev-charger-svg'>
            <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-ev-station" viewBox="0 0 25 25">
                <path className='ev-charger-icon-1' d="M3.5 2a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5h-5Zm2.131 10.46H4.14v-.893h1.403v-.505H4.14v-.855h1.49v-.54H3.485V13h2.146v-.54Zm1.316.54h.794l1.106-3.333h-.733l-.74 2.615h-.031l-.747-2.615h-.764L6.947 13Z"/>
                <path className='ev-charger-icon-2' d="M3 0a2 2 0 0 0-2 2v13H.5a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1H11v-4a1 1 0 0 1 1 1v.5a1.5 1.5 0 0 0 3 0V4a.5.5 0 0 0-.146-.354l-.5-.5a.5.5 0 0 0-.707 0l-.5.5A.5.5 0 0 0 13 4v3c0 .71.38 1.096.636 1.357l.007.008c.253.258.357.377.357.635v3.5a.5.5 0 1 1-1 0V12a2 2 0 0 0-2-2V2a2 2 0 0 0-2-2H3Zm7 2v13H2V2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1Z"/>
            </svg>
        </span> */}

      {tTruckIsPresent && <ElectricTruck truckSOC={truckSoc} />}
      <div className={`truck-charger-block ${offlineClass('offline-border')}`}>
        <div className='battery-soc'>
          {getBatteryCharge(online)}
          <div className='readings'>{getReadingsColor()}</div>
        </div>
      </div>
      <Arrow
        location={arrowLocation}
        direction={arrowDirection}
        component='truck'
        online={online}
      />
    </div>
  );
};

TruckCharger.propTypes = {
  ulid: PropTypes.number,
  name: PropTypes.string,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  truckIsPresent: PropTypes.bool,
  truckSOC: PropTypes.number,
  vin: PropTypes.string,
  arrowLocation: PropTypes.string,
  dataSource: PropTypes.object,
  timezone: PropTypes.string,
  isTabletOrMobile: PropTypes.bool
};
