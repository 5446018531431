import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const OverlapConnector = ({ online, xaxis, yaxis, deg }) => {
  const [bOnline] = useState(online === undefined ? true : online);
  const green = '#32CD32';
  const gray = '#474747';

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`,
    transform: `rotate(${deg}deg)`,
    borderColor: `${bOnline ? green : gray}`
  };

  return (
    // eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers
    <div className='overlap-connector' style={customStyle} />
  );
};

OverlapConnector.propTypes = {
  online: PropTypes.bool,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  deg: PropTypes.number
};
