import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'luxon';
import 'chartjs-adapter-luxon';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement
} from 'chart.js';

import {
  intervalMap,
  TIME_FRAME_BILLING,
  TIME_FRAME_MAX,
  TIME_FRAME_MONTH,
  TIME_FRAME_YEAR
} from './constants';
import { Bar } from 'react-chartjs-2';
import Annotation from 'chartjs-plugin-annotation';
import { debounce } from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  Annotation
);

export const ProjectedGridChart = ({
  dataSource,
  chartRef,
  selectedTimeFrame,
  updateTouState,
  minXState,
  maxXState,
  annotations,
  style,
  timezone,
  energyStream = false,
  siteId = false,
  costKWH = 0.28
}) => {
  const [updateTOU, setUpdateTOU] = updateTouState;
  const [minX, setMinX] = minXState;
  const [maxX, setMaxX] = maxXState;
  const [projectedGrid, setProjectedGrid] = useState([]);
  const [projectedGridTotal, setProjectedGridTotal] = useState(0);
  const [loadFactor, setLoadFactor] = useState(0);
  const [projectedGridCost, setProjectedGridCost] = useState(0);

  const responseHandler = (data) => {
    if (data['projectedGrid']) {
      setProjectedGrid(data['projectedGrid']);
    }
    if (data['projectedGridTotal']) {
      setProjectedGridTotal(data['projectedGridTotal']);
    }
    if (data['loadFactor']) {
      setLoadFactor(data['loadFactor']);
    }
    if (data['projectedGridCost']) {
      setProjectedGridCost(data['projectedGridCost']);
    }
  };

  const getSuggestedMax = () => {
    return 0.05;
  };

  const fetchData = () => {
    if (selectedTimeFrame == TIME_FRAME_YEAR) {
      dataSource.fetchProjectedGridYearly(
        minX,
        maxX,
        siteId,
        responseHandler,
        costKWH
      );
    } else if (siteId) {
      console.log(costKWH);
      dataSource.fetchProjectedGridInfo(
        minX,
        maxX,
        intervalMap[selectedTimeFrame],
        siteId,
        responseHandler,
        costKWH
      );
    }
  };

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchData();
    }, 200);
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, [minX, maxX]);

  const kwhData = {
    datasets: [
      {
        label: 'Projected Grid',
        data: projectedGrid,
        borderColor: 'rgb(192,192,192)',
        backgroundColor: 'rgb(192,192,192, 0.5)',
        borderWidth: style.borderWidth,
        borderSkipped: false,
        barPercentage: 1,
        stack: 'y-axis-1',
        yAxisID: 'y',
        order: 2
      }
    ]
  };

  const kwhOptions = {
    maintainAspectRatio: false,
    plugins: {
      annotation: {
        annotations: annotations
          ? [
              ...annotations.now,
              ...annotations.month,
              ...annotations.touRegions,
              ...annotations.startOfMonth
            ]
          : []
      },
      legend: {
        title: {
          display: true,
          padding: energyStream ? { top: -80, bottom: 100 } : 80,
          position: 'center',
          text: () => {
            if (chartRef && chartRef.current) {
              return [
                'Projected Values',
                'without Solar',
                'and/or Battery',
                ' ',
                'Load Factor: ' +
                  new Number((loadFactor * 100).toFixed(2)).toLocaleString(
                    'en-US'
                  ) +
                  '%',
                'Total: ' +
                  new Number(projectedGridTotal.toFixed(2)).toLocaleString(
                    'en-US'
                  ) +
                  ' kWh',
                'Cost: ' +
                  '$' +
                  new Number(projectedGridCost.toFixed(2)).toLocaleString(
                    'en-US'
                  ),
                ' ',
                ' ',
                ' ',
                '**This assumes a fixed',
                'rate of $' + costKWH.toFixed(2) + ' per kWh'
              ];
            }
          },
          color: style.color,
          font: {
            size: 13
          }
        },
        position: 'right',
        labels: {
          padding: energyStream ? 5 : 0,
          font: { size: 13 },
          color: style.color
        }
      },
      customBackgroundColor: {
        color: style.backgroundColor
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          onPanComplete({ chart }) {
            setMinX(new Date(chart.scales.x.min));
            setMaxX(new Date(chart.scales.x.max));
          }
        },
        zoom: {
          wheel: {
            enabled: true
          },
          mode: 'x',
          async onZoomComplete({ chart }) {
            setMinX(new Date(chart.scales.x.min));
            setMaxX(new Date(chart.scales.x.max));
          }
        }
      },
      tooltip: {
        interaction: {
          mode: 'index'
        },
        titleAlign: 'center',
        callbacks: {
          title: (item) => {
            if (selectedTimeFrame == TIME_FRAME_YEAR) {
              let date = item[0].parsed['x'];
              return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long'
              });
            }
            if (
              selectedTimeFrame == TIME_FRAME_BILLING ||
              selectedTimeFrame == TIME_FRAME_MONTH ||
              selectedTimeFrame == TIME_FRAME_MAX
            ) {
              let date = item[0].parsed['x'];
              return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              });
            }
            return item[0].label;
          },
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              if (context.dataset.label === 'State of Charge') {
                label +=
                  new Number(context.parsed.y.toFixed(3)).toLocaleString(
                    'en-US'
                  ) + '%';
              } else {
                label +=
                  new Number(context.parsed.y.toFixed(3)).toLocaleString(
                    'en-US'
                  ) + ' kWh';
              }
            }
            return label;
          }
        }
      }
    },
    onResize: () => {
      setTimeout(() => {
        setUpdateTOU(!updateTOU);
      }, 100);
    },
    responsive: true,
    transitions: {
      zoom: {
        animation: {
          duration: 0
        }
      }
    },
    animation: false,
    scales: {
      x: {
        stacked: true,
        offset: false,
        min: minX,
        max: maxX,
        type: 'time',
        time: {
          unit: () => {
            if (maxX.getTime() - minX.getTime() > 24 * 60 * 60 * 1000) {
              if (selectedTimeFrame == TIME_FRAME_YEAR) {
                return 'month';
              } else {
                return 'day';
              }
            } else {
              return 'hour';
            }
          },
          displayFormats: {
            month: 'MMM, y',
            day: 'EEE - d',
            hour: 'h a'
          },
          stepSize: 1
        },
        adapters: {
          date: {
            zone: timezone
          }
        },
        grid: {
          display: false,
          drawTicks: false,
          offset: false,
          drawOnChartArea: true,
          color: style.gridColor
        },
        ticks: {
          // https://stackoverflow.com/questions/71733581/how-to-introduce-newline-character-in-xaxes-label-with-chart-js
          callback: (tick) => {
            return tick.includes('-')
              ? tick.split('-')
              : tick == '12 PM'
              ? 'Noon'
              : tick;
          },
          maxTicksLimit: selectedTimeFrame == TIME_FRAME_YEAR ? 13 : 8,
          font: { size: 12 },
          color: style.color,
          includeBounds: false,
          align: 'center'
        }
      },
      y: {
        stacked: true,
        suggestedMax: getSuggestedMax(),
        grid: {
          color: style.gridColor,
          drawTicks: false
        },
        display: true,
        position: 'left',
        ticks: {
          callback: (value) => {
            return (
              new Number(value.toFixed(2)).toLocaleString('en-US') + ' kWh'
            );
          },
          font: {
            size: 12
          },
          color: style.color
        },
        type: 'linear'
      }
    }
  };

  return <Bar ref={chartRef} options={kwhOptions} data={kwhData} />;
};

ProjectedGridChart.propTypes = {
  dataSource: PropTypes.object.isRequired,
  chartRef: PropTypes.object.isRequired,
  selectedTimeFrame: PropTypes.string.isRequired,
  updateTouState: PropTypes.array.isRequired,
  minXState: PropTypes.array.isRequired,
  maxXState: PropTypes.array.isRequired,
  annotations: PropTypes.object,
  style: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  siteId: PropTypes.string,
  energyStream: PropTypes.bool,
  costKWH: PropTypes.number
};
