/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import 'chartjs-adapter-luxon';
import { Form } from 'react-bootstrap';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

import ZoomPlugin from 'chartjs-plugin-zoom';
import ChartStreaming from 'chartjs-plugin-streaming';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ZoomPlugin,
  ChartStreaming
);

import { Line } from 'react-chartjs-2';

import {
  meterReadingsEventName,
  subscribe,
  unsubscribe,
  publish,
  weatherReadingsEventName
} from '../helpers/events.js';
import { useInterval } from '../helpers/useInterval.js';
import { teal, white, gray, orange, colorMap } from '../helpers/colors.js';
import { PerformanceView } from './performanceView.js';

ChartJS.defaults.color = white;
ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.weight = 600;

const labels = {
  kw_reading: 'kW',
  kvar_reading: 'kVAR',
  kva_reading: 'kVA',
  volts_reading_ln: 'Volts LN',
  volts_reading: 'Volts',
  amps_reading: 'Amps',
  kw_reading_a: 'kW A',
  kw_reading_b: 'kW B',
  kw_reading_c: 'kW C',
  kvar_reading_a: 'kVAR A',
  kvar_reading_b: 'kVAR B',
  kvar_reading_c: 'kVAR C',
  kva_reading_a: 'kVA A',
  kva_reading_b: 'kVA B',
  kva_reading_c: 'kVA C',
  volts_reading_an: 'Volts AN',
  volts_reading_bn: 'Volts BN',
  volts_reading_cn: 'Volts CN',
  volts_reading_ab: 'Volts AB',
  volts_reading_bc: 'Volts BC',
  volts_reading_ac: 'Volts AC',
  amps_reading_a: 'Amps A',
  amps_reading_b: 'Amps B',
  amps_reading_c: 'Amps C'
};

const getReadingType = (reading) => {
  const kW = ['kw_reading', 'kw_reading_a', 'kw_reading_b', 'kw_reading_c'];
  const kVAR = [
    'kvar_reading',
    'kvar_reading_a',
    'kvar_reading_b',
    'kvar_reading_c'
  ];
  const kVA = [
    'kva_reading',
    'kva_reading_a',
    'kva_reading_b',
    'kva_reading_c'
  ];
  const VLN = [
    'volts_reading_ln',
    'volts_reading_an',
    'volts_reading_bn',
    'volts_reading_cn'
  ];
  const VLL = [
    'volts_reading',
    'volts_reading_ab',
    'volts_reading_bc',
    'volts_reading_ac'
  ];
  const A = [
    'amps_reading',
    'amps_reading_a',
    'amps_reading_b',
    'amps_reading_c'
  ];
  if (kW.includes(reading)) {
    return 'kW';
  } else if (kVAR.includes(reading)) {
    return 'kVAR';
  } else if (kVA.includes(reading)) {
    return 'kVA';
  } else if (VLN.includes(reading)) {
    return 'VLN';
  } else if (VLL.includes(reading)) {
    return 'VLL';
  } else if (A.includes(reading)) {
    return 'A';
  }
};

const phaseALabels = [
  'kw_reading_a',
  'kvar_reading_a',
  'kva_reading_a',
  'volts_reading_an',
  'volts_reading_ab',
  'amps_reading_a'
];
const phaseBLabels = [
  'kw_reading_b',
  'kvar_reading_b',
  'kva_reading_b',
  'volts_reading_bn',
  'volts_reading_bc',
  'amps_reading_b'
];
const phaseCLabels = [
  'kw_reading_c',
  'kvar_reading_c',
  'kva_reading_c',
  'volts_reading_cn',
  'volts_reading_ac',
  'amps_reading_c'
];

/* The ChartView encapsulates a chart rendered using chartjs (encapsulated
 * by react-chartjs-2).
 * re-renders that occur if you make use of any state variables.
 */
export const MobileLiveChartView = ({
  dataSource,
  selectedLayout,
  onlineState
}) => {
  const chartRef = useRef();
  const [online, setOnline] = onlineState;
  // eslint-disable-next-line no-unused-vars
  const [selectedUlids, setSelectedUlids] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedReadings, setSelectedReadings] = useState(['kw_reading']);

  const [selectedMeter, setSelectedMeter] = useState('');
  const [jsonLayout, setJsonLayout] = useState({});
  const [ulidMap, setUlidMap] = useState([]);
  const [chartState, setChartState] = useState('live');
  const [weatherUlids, setWeatherUlids] = useState([]);

  const detail = useRef({});
  const weatherDetail = useRef({});
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState({ datasets: [] });
  const [irradianceData, setIrradianceData] = useState([]);

  const onReceiveMobile = (e) => (detail.current = e.detail);
  const onReceiveWeather = (e) => (weatherDetail.current = e.detail);

  useEffect(() => {
    subscribe(meterReadingsEventName, onReceiveMobile);
    subscribe(weatherReadingsEventName, onReceiveWeather);
    return () => {
      unsubscribe(meterReadingsEventName);
      unsubscribe(weatherReadingsEventName);
    };
  }, []);

  useInterval(() => {
    if (selectedUlids.length !== 0) {
      dataSource.fetch(selectedUlids, (response) => {
        if (response.status === 'success') {
          setOnline(true);
          publish(meterReadingsEventName, response.data);
        } else {
          setOnline(false);
          if (response.status === 'logout') {
            dataSource?.auth?.setToken(null);
          }
        }
      });
    }
  }, dataSource.interval);

  useInterval(() => {
    if (weatherUlids.length !== 0) {
      dataSource.fetchWeather(weatherUlids, (response) => {
        if (response.status === 'success') {
          publish(weatherReadingsEventName, response.data);
        } else {
          console.log(response.data);
          if (response.status === 'logout') {
            dataSource?.auth?.setToken(null);
          }
        }
      });
    }
  }, dataSource.weatherInterval);

  const layoutHandler = (_jsonLayout) => {
    setJsonLayout(_jsonLayout);
    const _ulids = _jsonLayout.layout
      .filter((c) => c.props.ulid !== undefined && c.props.name !== undefined)
      .map((c) => c.props);
    setUlidMap(_ulids);
    setSelectedUlids([_ulids[0].ulid]);

    const _weatherUlids = _jsonLayout.layout
      .filter((c) => c.component === 'Irradiance')
      .map((c) => c.props.ulid);
    setWeatherUlids(_weatherUlids);
  };

  useEffect(() => {
    dataSource.fetchLayout(selectedLayout, layoutHandler);
    setSelectedUlids([]);
  }, [selectedLayout]);

  const getName = (component) => {
    return component.props.overrideName
      ? component.props.overrideName
      : component.props.name;
  };

  useEffect(() => {
    if (Object.keys(jsonLayout).length) {
      const chart = chartRef.current;
      // filter for datasets that have been selected by the user
      chart.data.datasets = chart.data.datasets.filter((dataset) =>
        selectedUlids.includes(dataset.ulid)
      );
      chart.data.datasets = chart.data.datasets.filter(
        (dataset) =>
          selectedReadings.includes(dataset.readingName) ||
          dataset.readingName === 'solar_regulate'
      );
      chart.update();
      const dataUlids = chart.data.datasets.map((dataset) => dataset.ulid);
      const dataReadings = chart.data.datasets.map(
        (dataset) => dataset.readingName
      );
      const addUlids = selectedUlids.filter(
        (ulid) => !dataUlids.includes(ulid)
      );
      const addReadings = selectedReadings.filter(
        (reading) => !dataReadings.includes(reading)
      );

      selectedUlids
        .filter((x) => !addUlids.includes(x))
        .forEach((ulid) => {
          const component = jsonLayout.layout.find(
            (c) => c.props.ulid === ulid
          );
          const type = component.component.toLowerCase();
          addReadings.forEach((reading) => {
            const readingType = getReadingType(reading);
            let color = colorMap[type][readingType].color;
            let fillColor = colorMap[type][readingType].fill;
            let dataSet = {
              label: getName(component).concat(' ' + labels[reading]),
              fill: {
                target: 'origin',
                above: fillColor,
                below: fillColor
              },
              pointRadius: 0,
              yAxisID: 'y',
              ulid: ulid,
              readingName: reading,
              cubicInterpolationMode: 'monotone',
              tension: 0.5,
              backgroundColor: color,
              borderColor: color,
              borderWidth: 2,
              data: [],
              read_at: 0,
              delay: 0
            };
            if (phaseALabels.includes(reading)) {
              dataSet['borderDash'] = [10, 3, 3];
            } else if (phaseBLabels.includes(reading)) {
              dataSet['borderDash'] = [10, 10];
            } else if (phaseCLabels.includes(reading)) {
              dataSet['borderDash'] = [5, 2];
            }
            chart.data.datasets.push(dataSet);
          });
        });

      addUlids.forEach((ulid) => {
        const component = jsonLayout.layout.find((c) => c.props.ulid === ulid);
        const type = component.component.toLowerCase();

        if (type == 'irradiance') {
          // dataSource.fetchIrradianceData(ulid, responseHandler);
          let color = colorMap['irradiance'].color;
          let dataSet = {
            label: getName(component).concat(' ' + 'W/m2'),
            pointRadius: 0,
            yAxisID: 'y2',
            ulid: ulid,
            readingName: 'irradiance',
            cubicInterpolationMode: 'monotone',
            tension: 0.5,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 2,
            data: irradianceData
          };
          chart.data.datasets.push(dataSet);
        } else {
          selectedReadings.forEach((reading) => {
            const readingType = getReadingType(reading);
            let color = colorMap[type][readingType].color;
            let fillColor = colorMap[type][readingType].fill;
            let dataSet = {
              label: getName(component).concat(' ' + labels[reading]),
              fill: {
                target: 'origin',
                above: fillColor,
                below: fillColor
              },
              pointRadius: 0,
              yAxisID: 'y',
              ulid: ulid,
              readingName: reading,
              cubicInterpolationMode: 'monotone',
              tension: 0.5,
              backgroundColor: color,
              borderColor: color,
              borderWidth: 2,
              data: [],
              read_at: 0,
              delay: 0
            };
            if (phaseALabels.includes(reading)) {
              dataSet['borderDash'] = [10, 3, 3];
            } else if (phaseBLabels.includes(reading)) {
              dataSet['borderDash'] = [10, 10];
            } else if (phaseCLabels.includes(reading)) {
              dataSet['borderDash'] = [5, 2];
            }
            chart.data.datasets.push(dataSet);
          });

          if (type === 'solar') {
            let color = colorMap['regulate'].color;
            // eslint-disable-next-line no-unused-vars
            let fillColor = colorMap['regulate'].fillColor;
            const dataSet2 = {
              label: 'Solar Regulation',
              fill: false,
              pointRadius: 0,
              yAxisID: 'y1',
              ulid: ulid,
              readingName: 'solar_regulate',
              cubicInterpolationMode: 'monotone',
              tension: 0.5,
              backgroundColor: color,
              borderColor: color,
              borderWidth: 2,
              data: [],
              read_at: 0,
              delay: 0
            };
            chart.data.datasets.push(dataSet2);
          }
        }
      });
      chart.update();
    }
  }, [selectedUlids, selectedReadings]);

  const options = {
    type: 'line',
    responsive: true,
    maintainAspectRatio: false,
    stacked: true,
    scales: {
      x: {
        type: 'realtime',
        label: 'kW',
        maxTicksLimit: 2,
        realtime: {
          ttl: 60000 * 60,
          duration: 60000,
          delay: 1000,
          onRefresh: (chart) => {
            chart.data.datasets.forEach((dataset) => {
              if (dataset.readingName != 'irradiance') {
                // get reading
                const readings = detail.current?.[dataset.ulid];
                let value = readings?.[dataset.readingName];

                // derive timestamp
                const read_at = readings?.read_at;
                const now = new Date().getTime() / 1000;
                let timestamp = parseInt(read_at) * 1000;

                // special handling for solar regulate
                if (dataset.readingName === 'solar_regulate') {
                  value = Math.round(value);

                  // ensure regulation gets plotted for inverter meters where readings
                  // update far less frequently than regulation values. this requires
                  // deriving a timestamp based on delay of original reading.
                  if (read_at === dataset.read_at) {
                    timestamp = (now - dataset.delay) * 1000;
                  }
                  const data = { x: timestamp, y: value };
                  dataset.data.push(data);
                } else {
                  // all other readings

                  // only plot readings when timestamp changes
                  if (read_at !== dataset.read_at) {
                    const data = { x: timestamp, y: value };

                    dataset.data.push(data);
                  }
                }

                // only save timestamp and delay when timestamp changes
                if (read_at !== dataset.read_at) {
                  dataset.read_at = read_at;
                  dataset.delay = now - read_at;
                }
              } else {
                const weatherReadings = weatherDetail.current?.[dataset.ulid];
                let value = weatherReadings?.[dataset.readingName];
                let timestamp = parseInt(weatherReadings?.['timestamp']) * 1000;
                const data = { x: timestamp, y: value };
                let data_included = dataset.data.some((element) => {
                  return JSON.stringify(data) === JSON.stringify(element);
                });
                if (!data_included) {
                  dataset.data.push(data);
                }
              }
            });
          }
        },
        ticks: {
          font: { size: 12 },
          major: { enabled: true },
          callback: (value, index, ticks) => {
            if (ticks[index].value) {
              return moment(Number(ticks[index].value))
                .tz(
                  jsonLayout.timezone
                    ? jsonLayout.timezone
                    : 'America/Los_Angeles'
                )
                .format(' h:mm:ss A ');
            }
            return value;
          },
          color: white
        },
        grid: {
          lineWidth: 2,
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          color: gray
        }
      },
      y: {
        grace: '20%',
        position: 'right',
        ticks: {
          callback: (value) => `${value > 0 ? '+' : ''}${+value.toFixed(2)} kW`,
          color: white,
          font: { size: 12 }
        },
        grid: {
          lineWidth: 2,
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          color: (o) => (o.tick.value === 0 ? '#ffffffaa' : '#474747')
        }
      },
      y1: {
        position: 'left',
        title: {
          display: 'auto',
          align: 'middle',
          text: 'Solar Regulation',
          color: teal,
          font: { size: 12 }
        },
        ticks: {
          color: teal,
          beginAtZero: true,
          callback: (value) => `${value}%`,
          stepSize: 1,
          font: { size: 12 },
          align: 'right'
        },
        max: 100,
        suggestedMin: 90
      },
      y2: {
        position: 'right',
        title: {
          display: 'auto',
          align: 'middle',
          text: 'Irradiance',
          color: orange,
          font: { size: 12 }
        },
        ticks: {
          color: orange,
          beginAtZero: true,
          callback: (value) => `${value}W/m2`,
          font: { size: 12 },
          align: 'right'
        }
      }
    },
    pan: {
      enabled: true,
      mode: 'x',
      rangeMax: {
        x: 4000
      },
      rangeMin: {
        x: 0
      }
    },
    plugins: {
      title: {
        display: true,
        text: '+export  / -import',
        color: white
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x'
        },
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x'
        }
      },
      legend: {
        position: 'left',
        labels: {
          usePointStyle: true,
          pointStyle: 'line',
          font: { size: 13 }
        }
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            return moment(tooltipItems[0].xLabel)
              .tz(
                jsonLayout.timezone
                  ? jsonLayout.timezone
                  : 'America/Los_Angeles'
              )
              .format('MMM D, YYYY, hh:mm:ss a');
          }
        }
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', height: '83vh' }}>
      <div
        style={{
          marginLeft: 'auto',
          height: '5vh',
          marginTop: '2vh',
          marginRight: '2vw'
        }}
      >
        <Form.Select
          onChange={(e) => {
            setSelectedMeter(e.target.value);
            setSelectedUlids(
              ulidMap.filter((c) => c.name === e.target.value)[0].ulid
            );
            setSelectedUlids([
              ulidMap.filter((c) => c.name === e.target.value)[0].ulid
            ]);
          }}
        >
          {ulidMap
            .filter(function (value) {
              if (value.ulid != 0) {
                return value;
              }
            })
            .map((value) => (
              <option key={value.ulid} value={value.name}>
                {value.name}
              </option>
            ))}
        </Form.Select>
      </div>
      <div style={{ width: '100%', height: '75vh', marginTop: '3vh' }}>
        <Line
          ref={chartRef}
          options={options}
          data={data}
          hidden={chartState === 'historic'}
        />
        {/* <Line ref={chartRef} options={options} data={data} /> */}
      </div>
    </div>
  );
};

MobileLiveChartView.propTypes = {
  dataSource: PropTypes.object,
  selectedLayout: PropTypes.string,
  onlineState: PropTypes.array
};
