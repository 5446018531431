/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { EssRackContext } from './essRackContext.js';

export const EssRackMap = ({ name, numRacks }) => {
  // display half the racks on top and other half on bottom
  let numRackHalfwayPoint = Math.ceil(numRacks / 2);
  const isNumRacksGreaterThan5 = numRacks > 5;

  if (!isNumRacksGreaterThan5) {
    numRackHalfwayPoint = numRacks;
  }

  const { selectedRack, setSelectedRack } = useContext(EssRackContext);
  const selectedEssClass = (essRackNumber) =>
    selectedRack === essRackNumber ? 'ess-container-map-selected' : '';

  return (
    <div className='ess-container'>
      <div className='ess-container-door' title='Container Door' />
      <div className='ess-container-map'>
        <table className='ess-container-map-table'>
          <tbody>
            <tr className='ess-container-map-row ess-container-map-top-row'>
              {Array.from(Array(numRackHalfwayPoint + 1).keys())
                .slice(1)
                .map((rackNum) => {
                  return (
                    <td
                      key={rackNum}
                      className='ess-container-map-table-td'
                      title={`Rack ${rackNum}`}
                      style={{ width: `${100 / numRackHalfwayPoint}%` }}
                    >
                      <div
                        className={`ess-container-map-cell ${selectedEssClass(
                          rackNum
                        )}`}
                        onClick={() => setSelectedRack(rackNum)}
                      >
                        <span className='text-align-middle'>{rackNum}</span>
                      </div>
                    </td>
                  );
                })}
            </tr>
            <tr className='ess-container-map-row ess-container-map-middle-row'>
              <td colSpan={numRackHalfwayPoint}>
                <div className='ess-container-map-title'>
                  {name ? name : 'ESS Container'}
                </div>
              </td>
            </tr>
            {
              isNumRacksGreaterThan5 ? (
                <tr className='ess-container-map-row ess-container-map-bottom-row'>
                  {Array.from(Array(numRacks + 1).keys())
                    .slice(numRackHalfwayPoint + 1)
                    .sort((a, b) => {
                      return b - a;
                    })
                    .map((rackNum) => {
                      return (
                        <td
                          key={rackNum}
                          className='ess-container-map-table-td'
                          title={`Rack ${rackNum}`}
                          style={{
                            width: `${100 / (numRacks - numRackHalfwayPoint)}%`
                          }}
                        >
                          <div
                            className={`ess-container-map-cell ${selectedEssClass(
                              rackNum
                            )}`}
                            onClick={() => setSelectedRack(rackNum)}
                          >
                            <span className='text-align-middle'>{rackNum}</span>
                          </div>
                        </td>
                      );
                    })}
                </tr>
              ) : null // Don't put racks on the bottom row if there are less than 5 ess racks on this ess unit.
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

EssRackMap.propTypes = {
  name: PropTypes.string,
  numRacks: PropTypes.number
};
