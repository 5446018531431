import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Line } from 'react-chartjs-2';

export const TouChart = ({ tou, chartRef, minX, maxX, style }) => {
  const touRef = useRef(null);

  const getSizeOfTOUWidth = () => {
    let touWidth = '95%';
    if (chartRef && chartRef.current) {
      touWidth = chartRef.current.chartArea.width + 50;
    }
    return touWidth;
  };

  const getSizeOfTOULeft = () => {
    let touLeft = 30;
    if (chartRef && chartRef.current) {
      touLeft = chartRef.current.chartArea.left - 5;
    }
    return touLeft;
  };

  const touOptions = {
    maintainAspectRatio: false,
    plugins: {
      customBackgroundColor: {
        color: style.backgroundColor
      },
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += '$' + context.parsed.y.toFixed(2);
            }
            return label;
          }
        }
      }
    },
    animation: false,
    responsive: true,
    clip: false,
    scales: {
      x: {
        min: minX,
        max: maxX,
        offset: false,
        grid: {
          drawTicks: false,
          drawOnChartArea: false
        },
        type: 'time',
        time: { unit: 'hour' },
        ticks: {
          display: false,
          autoSkip: true,
          font: { size: 12 },
          color: style.color
        }
      },
      y: {
        grid: {
          color: style.color,
          drawTicks: false,
          drawOnChartArea: true,
          display: true
        },
        display: true,
        position: 'right',
        ticks: {
          callback: (value) => {
            return '$' + value.toFixed(2);
          },
          font: { size: 13 },
          minTicksLimit: 1,
          maxTicksLimit: 2,
          color: style.color
        },
        type: 'linear'
      }
    }
  };

  const touData = {
    datasets: [
      {
        data: tou,
        clip: true,
        borderColor: 'red',
        borderWidth: 1,
        lineTension: 0.1,
        pointBorderWidth: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: 'red',
        pointHoverBorderColor: 'red',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        stepped: true
      }
    ]
  };

  return (
    <div
      style={{
        backgroundColor: style.backgroundColor,
        marginLeft: '30px',
        marginBottom: '10px',
        marginTop: '-5px',
        position: 'relative',
        height: '10vh',
        width: '95%'
      }}
    >
      <div
        style={{
          display: 'inline-block',
          marginLeft: getSizeOfTOULeft(),
          width: getSizeOfTOUWidth(),
          height: '100%'
        }}
      >
        <Line ref={touRef} options={touOptions} data={touData} />
      </div>
    </div>
  );
};

TouChart.propTypes = {
  tou: PropTypes.array.isRequired,
  chartRef: PropTypes.object.isRequired,
  minX: PropTypes.object.isRequired,
  maxX: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired
};
