/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { EssRackContext } from './essRackContext.js';

export const SdsSelect = ({
  sdss,
  sds_id,
  rack_column_name,
  // eslint-disable-next-line no-unused-vars
  mac_address,
  rack_column_db_table_id,
  handleSdsReload
}) => {
  const [selectedSdsId, setSelectedSdsId] = useState(sds_id);
  const { selectedRack, essConfig } = useContext(EssRackContext);

  // Make sure the selected SDS is updated when switching from rack to rack in configure mode.
  useEffect(() => {
    setSelectedSdsId(sds_id);
  }, [sds_id]);

  // Handle the update of changing the selected SDS for an ESS's rack column.
  // Send the ESS unit ID, ESS rack number, ESS rack column name, and SDS ID associated with that column.
  const handleSdsSubmit = (e) => {
    e.preventDefault();
    const sdsSelected = {
      ess_unit_id: essConfig.essId,
      rack_column_db_table_id: rack_column_db_table_id,
      old_sds_id: sds_id,
      new_sds_id: selectedSdsId
    };

    fetch('/control/set_sds_rack_info.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sdsSelected)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((responseData) => {
        if (
          responseData.errors !== '' &&
          responseData.errors !== undefined &&
          responseData.errors != null
        ) {
          alert(
            "Failed to update rack column's SDS. Errors: " + responseData.errors
          );
        } else {
          handleSdsReload(); // Reload SDSs to re-render selectors.
        }
      });
  };

  // Update state of currently selected SDS for this rack column every time it changes.
  const handleSdsChange = (e) => {
    setSelectedSdsId(e.target.value);
  };

  function displayManufacturer(manufacturer) {
    if (manufacturer !== '' && manufacturer != null) {
      if (manufacturer === 'USRIOT') {
        return 'USR';
      } else {
        return manufacturer;
      }
    } else {
      return 'Model Unknown';
    }
  }

  return (
    <div className='sds-select-div'>
      <div
        className={`sds-block-select-inner sds-block-${rack_column_name}-select`}
      >
        <select
          className='sds-selector'
          id={
            `sds-for-ess-${essConfig.essId}-rack-` +
            selectedRack +
            rack_column_name
          }
          name={`sds-for-ess-${essConfig.essId}-rack-${selectedRack}${rack_column_name}`}
          onChange={handleSdsChange}
          value={selectedSdsId}
        >
          <option value=''>Select SDS</option>
          {Object.entries(sdss).map(([key, sds]) => {
            // Only display SDS options that aren't assigned to an ESS rack column yet and the currently selected SDS (if one is already selected).
            if (
              sds.rack_column_db_table_id === rack_column_db_table_id ||
              sds.rack_column_db_table_id === '' ||
              sds.rack_column_db_table_id == null
            ) {
              return (
                <option key={key} value={sds.sds_id}>
                  {sds.mac_address !== '' && sds.mac_address != null
                    ? sds.mac_address
                    : 'MAC Unknown'}
                  {sds.modbus_line !== '' && sds.modbus_line != null
                    ? ` - line ${sds.modbus_line}`
                    : null}{' '}
                  | {displayManufacturer(sds.manufacturer)} | ID: {sds.sds_id}
                </option>
              );
            }
          })}
        </select>
      </div>
      <div className={`sds-wire-select sds-wire-${rack_column_name}`} />
      <button
        type='button'
        className={`sds-set-button sds-set-button-${rack_column_name}`}
        onClick={handleSdsSubmit}
        disabled={sds_id === selectedSdsId}
      >
        Set
      </button>
    </div>
  );
};

SdsSelect.propTypes = {
  sdss: PropTypes.array,
  sds_id: PropTypes.string,
  rack_column_name: PropTypes.string,
  mac_address: PropTypes.string,
  rack_column_db_table_id: PropTypes.string,
  handleSdsReload: PropTypes.func
};
