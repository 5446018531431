/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React from 'react';
import PropTypes from 'prop-types';

export const Sds = ({
  sds_id,
  rack_column_name,
  mac_address,
  modbus_line,
  sdsUnassigned,
  currentColNum
}) => {
  if (sdsUnassigned) {
    // No current SDS assigned to this rack column.
    // derive rack column If no sds, determine what
    // this column's rack character name would be depending on the rack column number.
    const rack_column_name = String.fromCharCode(
      currentColNum + 'A'.charCodeAt()
    );
    return (
      <div className='sds' key={currentColNum} title='Serial Device Server'>
        <div className={`sds-block sds-block-${rack_column_name}`}>
          <div className='sds-block-text'>No SDS Assigned</div>
        </div>
        <div className={`sds-wire sds-wire-${rack_column_name}`} />
      </div>
    );
  } else {
    // SDS is assigned to this rack column.
    return (
      <div className='sds' key={sds_id} title='Serial Device Server'>
        <div className={`sds-block sds-block-${rack_column_name}`}>
          <div className='sds-block-text'>
            {mac_address !== '' && mac_address != null
              ? mac_address
              : 'MAC Unknown'}
            {modbus_line !== '' && modbus_line != null
              ? ` - line ${modbus_line}`
              : null}
          </div>
        </div>
        <div className={`sds-wire sds-wire-${rack_column_name}`} />
      </div>
    );
  }
};

Sds.propTypes = {
  sds_id: PropTypes.string,
  rack_column_name: PropTypes.string,
  mac_address: PropTypes.string,
  modbus_line: PropTypes.string,
  sdsUnassigned: PropTypes.bool,
  currentColNum: PropTypes.number
};
