/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { EssRackContext } from './essRackContext.js';

const Fan = React.memo(function Fan({ fanState }) {
  const { selectedRack } = useContext(EssRackContext);
  const [fanIconStyle, setFanIconStyle] = useState(
    fanState === 'on' ? 'spin' : ''
  );

  useEffect(() => {
    setFanIconStyle(fanState === 'on' ? 'spin' : '');
  }, [fanState]);

  return (
    <div
      className='fan-state'
      title={`Rack ${selectedRack} Fan State: ${fanState}`}
    >
      <img
        className={fanIconStyle}
        id={`fan-${selectedRack}`}
        src='/images/fan.png'
        style={{ width: '100%' }}
        alt='fan'
      />

      {
        // Show a red exclamation mark indicating that the fan is off.
        fanState !== 'on' && <div className='fan-off-exclamation'>!</div>
      }
    </div>
  );
});

Fan.propTypes = {
  fanState: PropTypes.string
};

export default Fan;
