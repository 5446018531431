import React, { createContext } from 'react';
import PropTypes from 'prop-types';

// Whenever the user wants to import this context they can import EssRackContext.
// Example useContext:
//   const { selectedRack, essConfig } = useContext(EssRackContext)
// In this case, the user does not need setSelectedRack, just selectedRack and essConfig
// so they just need to add the context values they need in the {} to get their values.
export const EssRackContext = createContext();

// Create the provider that will provide the selected rack context for its children.
// Any time child of the provider wants to use the selectedRack, setSelectedRack, or essConfig,
// they must import the EssRackContext and then call useContext with it.
// This provider below is currently used in the EssRackBlock component.
export const EssRackProvider = ({
  selectedRack,
  setSelectedRack,
  essConfig,
  children
}) => {
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    selectedRack: selectedRack,
    setSelectedRack: setSelectedRack,
    essConfig: essConfig
  };
  return (
    <EssRackContext.Provider value={value}>{children}</EssRackContext.Provider>
  );
};

EssRackProvider.propTypes = {
  selectedRack: PropTypes.string,
  setSelectedRack: PropTypes.func,
  essConfig: PropTypes.object,
  children: PropTypes.array
};
