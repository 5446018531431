import isEqual from 'lodash.isequal'; // A deep value comparison method

// Change the dict of bmu/inverter dicts to a list of bmu/inverter dicts.
export const dictionaryToListOfDictionaries = (json_dict) => {
  const result = [];
  for (const i in json_dict) {
    result.push(json_dict[i]);
    // result.push([i, json_dict[i]]) // Use this line if we want to keep the topic in the array for bmus.
  }
  return result;
};

// Set BMU data received from BMU data handler if data has changed.
// We do a replace of new data into the original bmus object so that only
// the parts of the object that have changed data actually get updated and so
// a whole new object is not created every time.
export const bmuDataUpdate = (data, bmusRef, setBmus) => {
  // Only handle BMU data if it exists.
  if (data !== undefined && data !== null) {
    // Change new JSON bmu data into list of dictionaries of bmus.
    const newItems = dictionaryToListOfDictionaries(JSON.parse(data));
    // Only set bmus state if the bmus have changed.
    // The "lodash" library contains a deep comparison method isEqual that will
    // return true if the two objects or variables passed to it contain the same data.
    // https://docs-lodash.com/v4/is-equal/
    if (!isEqual(bmusRef.current, newItems)) {
      // If the bmus have not been set yet, set them.
      // Or if the selected rack has changed and the first bmu in the list is different, set them.
      if (
        bmusRef.current.length < 1 ||
        (bmusRef.current.length >= 1 &&
          bmusRef.current[0].bmu_id !== newItems[0].bmu_id)
      ) {
        bmusRef.current = newItems;
        setBmus(newItems);
      } else {
        // If the bmus have already been set, only update the bmus that have changed.
        // Make a shallow copy of the items.
        const items = [...bmusRef.current];

        // Loop through each new bmu dictionary in the list to see if there are updates.
        for (let i = 0; i < newItems.length; i++) {
          // If the bmus dict in this place has changed, updated it.
          if (
            items[i].bmu_id !== newItems[i].bmu_id || // It is a different BMU entirely, so update.
            Object.entries(items[i]).length === 0 || // There are no entries in item to begin with, item is empty, so update.
            !isEqual(items[i], newItems[i])
          ) {
            // Objects have different data, so update.
            // Replace the property you're intested in changing.
            if (items[i].bmu_id !== newItems[i].bmu_id) {
              items[i].bmu_id = newItems[i].bmu_id;
            }
            if (items[i].column_name !== newItems[i].column_name) {
              items[i].column_name = newItems[i].column_name;
            }
            if (items[i].rack_slot_number !== newItems[i].rack_slot_number) {
              items[i].rack_slot_number = newItems[i].rack_slot_number;
            }
            if (!isEqual(items[i].readings, newItems[i].readings)) {
              items[i].readings = newItems[i].readings;
            }
          }
        }

        // Set the state to our new copy.
        bmusRef.current = items;
        setBmus(items);
      }
    }
  }
};

// We don't need inverter data for the overview
export const inverterDataUpdate = (data, invertersRef, setInverters) => {
  // Only handle inverter data if it exists.
  if (data !== undefined && data !== null) {
    // Change new JSON inverter data into list of dictionaries of inverters.
    const newItems = dictionaryToListOfDictionaries(JSON.parse(data));

    // Only set inverters state if the inverters have changed.
    // The "lodash" library contains a deep comparison method isEqual that will
    // return true if the two objects or variables passed to it contain the same data.
    // https://docs-lodash.com/v4/is-equal/
    if (!isEqual(invertersRef.current, newItems)) {
      // If the inverters have not been set yet, set them.
      // Or if the selected rack has changed and the first inverter in the list is different, set them.
      if (
        invertersRef.current.length < 1 ||
        (invertersRef.current.length >= 1 &&
          invertersRef.current[0].info.inverter_id !==
            newItems[0].info.inverter_id)
      ) {
        invertersRef.current = newItems;
        setInverters(newItems);
      } else {
        // If the inverters have already been set, only update the inverters that have changed.
        // Make a shallow copy of the items.
        const items = [...invertersRef.current];

        // Loop through each new bmu dictionary in the list to see if there are updates.
        for (let i = 0; i < newItems.length; i++) {
          // If the inverters dict in this place has changed, updated it.
          if (
            items[i].info.inverter_id !== newItems[i].info.inverter_id || // It is a different inverter entirely, so update.
            Object.entries(items[i]).length === 0 || // There are no entries in item to begin with, item is empty, so update.
            !isEqual(items[i], newItems[i])
          ) {
            // Objects have different data, so update.
            // Replace the property you're interested in changing.
            if (!isEqual(items[i].info, newItems[i].info)) {
              items[i].info = newItems[i].info;
            }
            if (!isEqual(items[i].status, newItems[i].status)) {
              items[i].status = newItems[i].status;
            }
          }
        }

        // Set the state to our new copy.
        invertersRef.current = items;
        setInverters(items);
      }
    }
  }
};

export const rackDataUpdate = (
  data,
  setFanState,
  setHasFanRelay,
  setRackState
) => {
  const rack_data = JSON.parse(data);

  // Set the different rack data that was pushed from the ess rack event source.
  if (rack_data.fan_state === 'on' || rack_data.fan_state === 'off')
    setFanState(rack_data.fan_state);
  if (rack_data.has_fan_relay === 'yes' || rack_data.has_fan_relay === 'no')
    setHasFanRelay(rack_data.has_fan_relay === 'yes');
  if (
    rack_data.rack_state === 'operational' ||
    rack_data.rack_state === 'maintenance' ||
    rack_data.rack_state === 'disabled' ||
    rack_data.rack_state === 'scanning'
  )
    setRackState(rack_data.rack_state);
};
