import React from 'react';

export const readingsMap = {
  kw_reading: { unitExp: 3, unit: 'W', phase: '' },
  kw_reading_a: { unitExp: 3, unit: 'W', phase: 'A' },
  kw_reading_b: { unitExp: 3, unit: 'W', phase: 'B' },
  kw_reading_c: { unitExp: 3, unit: 'W', phase: 'C' },
  kvar_reading: { unitExp: 3, unit: 'VAR', phase: '' },
  kvar_reading_a: { unitExp: 3, unit: 'VAR', phase: 'A' },
  kvar_reading_b: { unitExp: 3, unit: 'VAR', phase: 'B' },
  kvar_reading_c: { unitExp: 3, unit: 'VAR', phase: 'C' },
  kva_reading: { unitExp: 3, unit: 'VA', phase: '' },
  kva_reading_a: { unitExp: 3, unit: 'VA', phase: 'A' },
  kva_reading_b: { unitExp: 3, unit: 'VA', phase: 'B' },
  kva_reading_c: { unitExp: 3, unit: 'VA', phase: 'C' },
  volts_reading: { unitExp: 0, unit: 'V', phase: 'LL' },
  volts_reading_ab: { unitExp: 0, unit: 'V', phase: 'AB' },
  volts_reading_bc: { unitExp: 0, unit: 'V', phase: 'BC' },
  volts_reading_ac: { unitExp: 0, unit: 'V', phase: 'AC' },
  volts_reading_ln: { unitExp: 0, unit: 'V', phase: 'LN' },
  volts_reading_an: { unitExp: 0, unit: 'V', phase: 'AN' },
  volts_reading_bn: { unitExp: 0, unit: 'V', phase: 'BN' },
  volts_reading_cn: { unitExp: 0, unit: 'V', phase: 'CN' },
  amps_reading: { unitExp: 0, unit: 'A', phase: '' },
  amps_reading_a: { unitExp: 0, unit: 'A', phase: 'A' },
  amps_reading_b: { unitExp: 0, unit: 'A', phase: 'B' },
  amps_reading_c: { unitExp: 0, unit: 'A', phase: 'C' },
  power_factor: { unitExp: 0, unit: '', phase: '' },
  power_factor_a: { unitExp: 0, unit: '', phase: 'A' },
  power_factor_b: { unitExp: 0, unit: '', phase: 'B' },
  power_factor_c: { unitExp: 0, unit: '', phase: 'C' },
  frequency: { unitExp: 0, unit: 'Hz', phase: '' },
  read_at: { timestamp: true }
};

//  10^exp gives unit prefix
const unitExp2Prefix = {
  '-6': 'u', // micro
  '-3': 'm', // milli
  0: '',
  3: 'k', // kilo
  6: 'M' // mega
};

export const formatValue = (reading, readingMap) => {
  let value = Number.parseFloat(reading);
  const magnitude = Math.abs(value);
  let unitExp = readingMap.unitExp;

  if (magnitude > 0.0 && magnitude < 100.0) {
    value = (Math.round(value * 1000) / 1000).toFixed(3);
  } else if (magnitude >= 10.0 && magnitude < 100.0) {
    value = (Math.round(value * 100) / 100).toFixed(2);
  } else if (magnitude >= 100.0 && magnitude < 1000.0) {
    value = (Math.round(value * 10) / 10).toFixed(1);
  } else if (magnitude >= 1000.0) {
    value = Math.round(value) / 1000;
    value = (Math.round(value * 1000) / 1000).toFixed(3);
    unitExp += 3;
  }
  let unit = unitExp2Prefix[unitExp] + readingMap.unit;

  return [value, unit];
};

export const formatReading = (reading, readingMap, online = true, timezone) => {
  if ('timestamp' in readingMap) {
    return {
      className: online ? 'readings positive-readings' : 'readings offline',
      value: formatEpochTime(reading, timezone),
      unit: '',
      fontSize: { fontSize: '16px' }
    };
  }

  let [value, unit] = formatValue(reading, readingMap);
  if (readingMap.phase !== '') unit += ' ' + readingMap.phase;

  const renderedReading = value + ' ' + unit;
  let fontSize = { fontSize: '18px' };
  if (renderedReading.length >= 13) {
    fontSize = { fontSize: '16px' };
    value = (Math.round(value * 100) / 100).toFixed(2);
  } else if (renderedReading.length >= 12) {
    fontSize = { fontSize: '16px' };
  } else if (renderedReading.length >= 11) {
    fontSize = { fontSize: '17px' };
  }
  let className = 'readings';
  if (online) {
    className = value >= 0 ? ' positive-readings' : ' negative-readings';
  } else {
    className = ' offline';
  }

  return {
    className: className,
    value: value > 0 ? '+' + value : value,
    unit: unit,
    fontSize: fontSize
  };
};

const formatEpochTime = (epochTime, timezone) => {
  const m = new Date(epochTime * 1000);
  let options = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timezone
  };
  return m.toLocaleString('en-us', options);
};

export const renderReading = (
  selectedReading,
  setSelectedReading,
  readings,
  online = true,
  timezone = 'America/Los_Angeles',
  isTabletOrMobile
) => {
  const options = Object.keys(readingsMap).map((k) => {
    const r = formatReading(readings[k], readingsMap[k], online, timezone);
    if (k in readings && readings[k] !== null) {
      return (
        <option
          className={r.className}
          style={{ fontSize: '15px', fontWeight: 'bold', background: 'black' }}
          value={k}
          key={k}
        >
          {r.value}&thinsp;{r.unit}
        </option>
      );
    } else {
      return '';
    }
  });

  const r = formatReading(
    readings[selectedReading],
    readingsMap[selectedReading],
    online,
    timezone
  );
  const className = 'readings ' + r.className + ' select-readings';
  return (
    // eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers
    <div>
      <select
        className={className}
        style={r.fontSize}
        value={selectedReading}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setSelectedReading(e.target.value)}
        disabled={isTabletOrMobile}
      >
        {options}
      </select>
    </div>
  );
};
