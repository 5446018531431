/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { isReadingZero } from '../helpers/isReadingZero.js';

/*

 Inverter JSON data is provided in the following format:
  {
    "info": {
      "inverter_id": 375,
      "serial_number": "512036001083",
      "mac_address": "e0:1f:0a:01:64:6d",
      "ip_address": "10.252.8.238",
      "device_id": 3,
      "pcs_unit_id": 7,
      "group_id": 3,
      "rack_slot_number": 1,
      "rack_column_name": "A",
    },
    "status": {
      "updatedTime": 1620629612,
      "DcVsolar": 51.668,
      "DcIsolar": 14.124,
      "DcWatt": 732,
      "AcVout": 212.666,
      "AcIout": 3.16464,
      "phase": 0,
      "var": 0,
      "temp": 34.4175,
      "wattsCons": 0,
      "AcFrequency": 59.9977,
      "AcIlineRms": 3.027,
      "operational_status": "enabled|disabled, ... uninit,diag_fail,temp_stby,bob,dcovp,ocp,trov2,
                             no_ign,high_temp,low_temp,dcin_p,dcin_v,dcin_i,acov_1,acov_2,acov_3,
                             pll_lol,pll_nolok,pll_island,bad_profile"
      "power_status": "mpp,max,limited,gw_curt",
      "admin_status": "unlocked | locked | locked_lp"
    }
  }

*/

export const InverterReadingsSelector = ({
  status,
  info,
  onlineStatus,
  selectedInverterReadingName,
  setSelectedInverterReadingName,
  selectedInverterReadingIndex,
  setSelectedInverterReadingIndex
}) => {
  // Uncomment this if we want inverter readings colors again.
  // const [readingSignClass, setReadingSignClass] = useState([])

  // Reference to inverter's selector for object access
  const readingsSelectField = useRef(0);

  // reading font size and position
  const maxReadingLength = 9;
  const defaultFontSize = 13;
  const minimumFontSize = 10;
  const defaultTopPx = 4;
  const minTopPx = 0;

  const readingUnit = {
    AcIout: ' A AC',
    AcVout: ' V AC',
    DcVsolar: ' V DC',
    DcIsolar: ' A DC',
    DcWatt: ' W DC',
    phase: ' Phase',
    var: ' Var',
    temp: ' \u00B0C',
    wattsCons: ' W Cons',
    AcFrequency: ' Freq AC',
    AcIlineRms: ' A line RMS AC',
    operational_status: ' Op Stat',
    power_status: ' Pwr Stat',
    admin_status: ' Admn Stat',
    updatedTime: '',
    serial_number: ' SN',
    mac_address: '',
    ip_address: '',
    string_position: ' Str Pos',
    group_id: ' Grp ID'
  };

  const info_params = [
    'serial_number',
    'ip_address',
    'mac_address',
    'string_position',
    'group_id'
  ];

  // display order for inverter readings
  const status_params = [
    'AcIout',
    'AcVout',
    'DcVsolar',
    'DcIsolar',
    'DcWatt',
    'phase',
    'var',
    'temp',
    'wattsCons',
    'AcFrequency',
    'AcIlineRms',
    'operational_status',
    'power_status',
    'admin_status',
    'updatedTime'
  ];

  // The inverter's updatedTime is in UTC seconds/seconds since the epoch.
  // Return a translated version of the timestamp in YYYY-MM-DD h:mm:ss A format using JS Date in local time.
  // For the date, we decided not to use localDate() because it returns the date with forward-slashes and we want dashes.
  // So instead we get the date parts individually and construct the date in the format we want.
  const getReadableUpdatedTime = (updatedTime) => {
    if (updatedTime != null) {
      const millisecondsTime = parseInt(updatedTime) * 1000; // Convert seconds to milliseconds.
      const this_date = new Date(millisecondsTime);
      const year = this_date.getFullYear();
      const month = this_date.getMonth() + 1; // Need to add 1 to the month because getMonth returns a val 0-11 instead of 1-12.
      const date = this_date.getDate(); // Returns the date of the month, 1-31.
      const localTime = this_date.toLocaleTimeString(); // Returns the time in h:mm:ss A format. Ex: 1:45:17 PM
      updatedTime = year + '-' + month + '-' + date + ' ' + localTime;
      return updatedTime;
    } else {
      // Do not return and formatted date if updatedTime is null.
    }
  };

  // formats specific inverter reading
  const displayOptionText = (readingValue, readingName, unit) => {
    if (readingValue !== '' && readingValue !== null) {
      if (readingName === 'updatedTime') {
        // For the timestamp, we need to make sure to translate it into a readable format before displaying.
        readingValue = getReadableUpdatedTime(readingValue);
      }
      return displayValue(readingValue, readingName) + unit;
    } else {
      // Display -- for empty or null values along with the reading unit.
      if (
        readingName === 'serial_number' ||
        readingName === 'ip_address' ||
        readingName === 'mac_address' ||
        readingName === 'string_position' ||
        readingName === 'group_id'
      ) {
        // Display "Unknown" for these parameters if they are not known so it is clear to the user that the system does not know these values yet.
        return (
          'Unknown' +
          (readingName === 'ip_address' ? ' IP' : '') +
          (readingName === 'mac_address' ? ' MAC' : '')
        ); // Add in IP or MAC unit when unknown so we know which value is unknown.
      } else {
        // For all the readings not in the if above, display -- when they are not known.
        return '--' + unit;
      }
    }
  };

  // Calculate the inverter reading positivaron and font size based on the length of the selected text being displayed.
  // The IP Address reading is currently the only one with reduced font size so that we can see the entire IP on one line.
  const [readingPos, setReadingPos] = useState(
    displayOptionText(
      readingsSelectField.current.value,
      selectedInverterReadingName,
      readingUnit[selectedInverterReadingName]
    ).length > maxReadingLength && selectedInverterReadingName !== 'ip_address'
      ? minTopPx
      : defaultTopPx
  );
  const [readingFontSize, setReadingFontSize] = useState(
    selectedInverterReadingName === 'ip_address'
      ? minimumFontSize
      : defaultFontSize
  );
  const selectedReadingStyle = {
    top: `${readingPos}px`,
    fontSize: `${readingFontSize}px`
  };

  // Handle the render or change of the selected reading in the readings dropdown selector.
  const handleReadingsSelector = (selectValue) => {
    // Get selected reading's value and name.
    // New name will be the readingName if an option is selected, and "" otherwise. This "otherwise" case may be hit around the first render.
    // eslint-disable-next-line no-unused-vars
    const currentReadingSelectedValue = selectValue.value;
    const newSelectedInverterReadingName =
      selectValue.options[selectValue.selectedIndex] !== undefined
        ? selectValue.options[selectValue.selectedIndex].attributes.readingname
            .nodeValue
        : selectedInverterReadingName;

    // Set the selected inverter readings' name and set all inverters to the newly selected reading type.
    setSelectedInverterReadingName(newSelectedInverterReadingName); // Update the selected reading name so that it can be displayed on hover of the reading.
    setSelectedInverterReadingIndex(selectValue.selectedIndex); // Change all inverter readings on the rack to the same selected reading.

    // Handle the font size and position change of the selected inverter reading.
    // The IP Address reading is currently the only one with reduced font size so that we can see the entire IP on one line.
    setReadingPos(
      selectValue.options[selectValue.selectedIndex].text.length >
        maxReadingLength && newSelectedInverterReadingName !== 'ip_address'
        ? minTopPx
        : defaultTopPx
    );
    setReadingFontSize(
      newSelectedInverterReadingName === 'ip_address'
        ? minimumFontSize
        : defaultFontSize
    );
  };

  // Ensure readings selector renders to selected reading for all inverters.
  useEffect(() => {
    handleReadingsSelector(readingsSelectField.current);
    readingsSelectField.current.selectedIndex = selectedInverterReadingIndex;
  }, [handleReadingsSelector, selectedInverterReadingIndex]);

  // Update all inverter readings whenever a single one changes
  useEffect(() => {
    readingsSelectField.current.selectedIndex = selectedInverterReadingIndex;
    handleReadingsSelector(readingsSelectField.current);
  }, [
    handleReadingsSelector,
    selectedInverterReadingIndex,
    readingsSelectField.current.value
  ]);

  const handleReadingsSelectChange = (e) => handleReadingsSelector(e.target);

  // sort the inverter readings into a preferred display order.
  const sortReadingsForDisplay = (readings) => {
    // Sort the inverter's currently applied values into the order specified by the AIF params arrays.
    let sorted_readings = {};
    for (let i = 0; i < status_params.length; i++) {
      sorted_readings[status_params[i]] = readings[status_params[i]];
    }
    return sorted_readings;
  };

  // display proper number of decimal places
  function displayValue(value, reading) {
    if (
      reading === 'DcVsolar' ||
      reading === 'DcIsolar' ||
      reading === 'DcWatt' ||
      reading === 'AcVout' ||
      reading === 'AcIout' ||
      reading === 'var' ||
      reading === 'temp' ||
      reading === 'wattsCons' ||
      reading === 'AcFrequency' ||
      reading === 'AcIlineRms'
    ) {
      value = parseFloat(value).toFixed(1);
    } else if (reading === 'updatedTime') {
      if (typeof value !== 'undefined') {
        if (onlineStatus === 'online') {
          const dateAndTimePieces = value.split(' ');
          value = dateAndTimePieces[1] + ' ' + dateAndTimePieces[2];
        }
      }
    } else if (
      reading === 'operational_status' ||
      reading === 'power_status' ||
      reading === 'admin_status'
    ) {
      if (typeof value !== 'undefined') {
        if (value.length === 0) {
          value = '--';
        }
      }
    }
    return value;
  }

  // Helper function for isReadingZero that determines whether the argument
  // for isReadingZero comes from status or info, depending on
  // which data set the selected reading is apart of.
  const isReadingZeroHelper = (readingName) => {
    return isReadingZero(
      status_params.includes(readingName)
        ? status[readingName]
        : info_params.includes(readingName)
        ? info[readingName]
        : ''
    );
  };

  return (
    <div className='inverter-reading-selector-container'>
      <div className='selected-inverter-reading' style={selectedReadingStyle}>
        <div
          className={`selected-inverter-reading-value ${onlineStatus} ${
            isReadingZeroHelper(selectedInverterReadingName) ? 'grayText' : ''
          }`}
        >
          {status_params.includes(selectedInverterReadingName)
            ? displayOptionText(
                status[selectedInverterReadingName],
                selectedInverterReadingName,
                readingUnit[selectedInverterReadingName]
              )
            : info_params.includes(selectedInverterReadingName)
            ? displayOptionText(
                info[selectedInverterReadingName],
                selectedInverterReadingName,
                readingUnit[selectedInverterReadingName]
              )
            : ''}
        </div>
      </div>

      <select
        ref={readingsSelectField}
        className='inverter-reading-selector' // for inverter readings color, add ${readingSignClass}
        onChange={handleReadingsSelectChange}
        title={selectedInverterReadingName}
      >
        {/* Display the inverter readings from Status. */}
        {Object.keys(sortReadingsForDisplay(status)).map((reading, index) => {
          // Display an option in the dropdown for each inverter reading.
          // This is needed so that the process of selecting one reading and setting all the other inverters' selected reading to that same reading works.
          return (
            <option
              key={index}
              value={
                /* If the ready is empty, set value to empty string. Otherwise set the value to the value of the reading.
                  In the case of operational_status, power_status, and admin_status, the value is set to the first item
                  of the array since that is how their values are stored. */
                reading !== 'operational_status' &&
                reading !== 'power_status' &&
                reading !== 'admin_status'
                  ? status[reading] !== '' && status[reading] !== null
                    ? status[reading]
                    : '' // For all status readings that aren't operational_status, power_status, or admin_status.
                  : status[reading].length !== 0
                  ? status[reading][0]
                  : '' // For operational_status, power_status, and admin_status since they are stored in an array.
              }
              title={reading}
              readingname={reading}
              className={`${onlineStatus} ${
                isReadingZero(status[reading]) ? 'grayText' : ''
              }`}
            >
              {displayOptionText(
                status[reading],
                reading,
                readingUnit[reading]
              )}
            </option>
          );
        })}

        {/* Display the inverter info from Info. */}
        {Object.keys(info).map((reading, index) => {
          // Display some of the info in info in the inverter readings dropdown.
          if (
            reading === 'serial_number' ||
            reading === 'ip_address' ||
            reading === 'mac_address' ||
            reading === 'string_position' ||
            reading === 'group_id'
          ) {
            return (
              <option
                key={index}
                value={
                  info[reading] !== '' && info[reading] !== null
                    ? info[reading]
                    : ''
                }
                title={reading}
                readingname={reading}
                className={`${onlineStatus} ${
                  isReadingZero(info[reading]) ? 'grayText' : ''
                }`}
              >
                {displayOptionText(
                  info[reading],
                  reading,
                  readingUnit[reading]
                )}
              </option>
            );
          }
        })}
      </select>
    </div>
  );
};

InverterReadingsSelector.propTypes = {
  status: PropTypes.object,
  info: PropTypes.object,
  onlineStatus: PropTypes.string,
  selectedInverterReadingName: PropTypes.string,
  setSelectedInverterReadingName: PropTypes.func,
  selectedInverterReadingIndex: PropTypes.number,
  setSelectedInverterReadingIndex: PropTypes.func
};
