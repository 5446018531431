import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { useInterval } from '../helpers/useInterval.js';
import { meterReadingsEventName, publish } from '../helpers/events.js';
import { MeterView } from './meterView.js';

const viewMap = {
  meter: MeterView
};

export const MobileMeterView = ({
  dataSource,
  selectedLayout,
  onlineState
}) => {
  // eslint-disable-next-line no-unused-vars
  const [online, setOnline] = onlineState;
  const [selectedUlid, setSelectedUlid] = useState(0);
  const selectedUlidsState = useState([]);
  const setSelectedUlids = selectedUlidsState[1];
  const viewsState = useState([MeterView]);
  const viewModeState = useState('Monitor');
  const [ulids, setUlids] = useState([]);
  const [ulidMap, setUlidMap] = useState([]);
  const [jsonLayout, setJsonLayout] = useState({});
  const selectedReadingsState = useState(['kw_reading']);

  const layoutHandler = (_jsonLayout) => {
    setJsonLayout(_jsonLayout);
    const _ulids = _jsonLayout.layout
      .filter(
        (c) =>
          c.props.ulid !== undefined &&
          c.props.name !== undefined &&
          c.component != 'Irradiance'
      )
      .map((c) => c.props);
    setUlids(_ulids.map((c) => c.ulid));
    setUlidMap(_ulids);
    setSelectedUlid(_ulids[0].ulid);
    setSelectedUlids([_ulids[0].ulid]);
  };

  useEffect(() => {
    dataSource.fetchLayout(selectedLayout, layoutHandler);
    setSelectedUlids([]);
  }, [selectedLayout]);

  useInterval(() => {
    if (ulids.length !== 0) {
      dataSource.fetch(ulids, (response) => {
        if (response.status === 'success') {
          setOnline(true);
          publish(meterReadingsEventName, response.data);
        } else {
          setOnline(false);
          if (response.status === 'logout') {
            dataSource?.auth?.setToken(null);
          }
        }
      });
    }
  }, dataSource.interval);

  const viewProps = {
    viewMap: viewMap,
    viewsState: viewsState,
    viewModeState: viewModeState,
    selectedUlidsState: selectedUlidsState,
    selectedReadingsState: selectedReadingsState,
    timezone: jsonLayout.timezone ? jsonLayout.timezone : 'America/Los_Angeles'
  };

  const checkMeterView = () => {
    return selectedUlid ? (
      <MeterView jsonLayout={jsonLayout} viewProps={viewProps} />
    ) : (
      <div />
    );
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', height: '83vh' }}>
      <div
        style={{
          marginLeft: 'auto',
          height: '5vh',
          marginTop: '2vh',
          marginRight: '2vw'
        }}
      >
        <Form.Select
          onChange={(e) => {
            setSelectedUlid(
              ulidMap.filter((c) => c.name === e.target.value)[0].ulid
            );
            setSelectedUlids([
              ulidMap.filter((c) => c.name === e.target.value)[0].ulid
            ]);
          }}
        >
          {ulidMap
            .filter(function (value) {
              if (value.ulid != 0 && value.name != 'Irradiance Meter') {
                return value;
              }
            })
            .map((value) => (
              <option key={value.ulid} value={value.name}>
                {value.name}
              </option>
            ))}
        </Form.Select>
      </div>
      {checkMeterView()}
    </div>
  );
};

MobileMeterView.propTypes = {
  dataSource: PropTypes.object,
  selectedLayout: PropTypes.string,
  onlineState: PropTypes.array
};
