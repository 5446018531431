/* eslint-disable @shopify/jsx-no-hardcoded-content */
/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useInterval } from '../helpers/useInterval.js';

import {
  subscribe,
  unsubscribe,
  weatherReadingsEventName
} from '../helpers/events.js';

export const Irradiance = ({
  ulid,
  name,
  xaxis,
  yaxis,
  titlePosition,
  dataSource,
  viewProps,
  isTabletOrMobile
}) => {
  const [readings, setReadings] = useState({});
  const [color, setColor] = useState('yellow');
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  // eslint-disable-next-line no-unused-vars
  const [views, setViews] = viewProps.viewsState;
  const viewMap = viewProps.viewMap;

  const positionWithStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  useEffect(() => {
    subscribe(weatherReadingsEventName, (e) =>
      setReadings(e.detail?.[ulid] || {})
    );
    return () => unsubscribe(weatherReadingsEventName);
  }, [ulid]);

  const onClick = (e, ulid) => {
    if (!isTabletOrMobile) {
      if (selectedUlids.includes(ulid)) {
        const ulids = selectedUlids.filter((u) => u !== ulid);
        setSelectedUlids(ulids);
        if (ulids.length === 0) {
          setViews([viewMap.site]);
        }
      } else {
        setViews([viewMap.site, viewMap.meter, viewMap.chart]);
        setSelectedUlids([ulid, ...selectedUlids]);
      }
    }
    e.stopPropagation();
  };

  return (
    <div
      className='apparent-module meter'
      onClick={(e) => onClick(e, ulid)}
      style={positionWithStyle}
    >
      {(titlePosition === undefined || titlePosition === 'top') && (
        <div
          className='meter-name'
          style={{ marginTop: '13px', color: `${color}` }}
        >
          {name}
        </div>
      )}
      <i
        style={{ color: 'yellow', position: 'relative', fontSize: '110px' }}
        className='fa fa-sun-o'
      />
      <div
        className='meter-square meter-readings'
        style={{ borderColor: `${color}` }}
      >
        <div
          style={{
            position: 'absolute',
            textAlign: 'center',
            top: '10%',
            left: '15px'
          }}
        >
          Irradiance:{' '}
          {isNaN(readings['irradiance'])
            ? ''
            : readings['irradiance'].toFixed(2)}{' '}
          W/m&sup2; <br />
          Temperature:{' '}
          {isNaN(readings['ambientTemp'])
            ? ''
            : readings['ambientTemp'].toFixed(2)}{' '}
          &deg;C
          <br />
        </div>
      </div>
      {titlePosition === 'bottom' && (
        <div className='meter-name' style={{ color: `${color}` }}>
          {name}
        </div>
      )}
    </div>
  );
};

Irradiance.propTypes = {
  ulid: PropTypes.number,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  titlePosition: PropTypes.string,
  viewProps: PropTypes.object,
  isTabletOrMobile: PropTypes.bool,
  name: PropTypes.string,
  dataSource: PropTypes.object
};
