/* eslint-disable @shopify/jsx-no-complex-expressions */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Sds } from './sds.js';
import { SdsSelect } from './sdsSelect.js';
import { EssRackContext } from './essRackContext.js';

const SdsRow = React.memo(function SdsRow({
  viewMode,
  currentRacksSDSs,
  setCurrentRacksSDSs
}) {
  const [sdss, setSdss] = useState({});
  const [rackColumnNameId, setRackColumnNameId] = useState({});
  const { selectedRack, essConfig } = useContext(EssRackContext);

  useEffect(() => {
    fetchCurrentSdsInfo();
  }, []);

  useEffect(() => {
    // Add SDSs to state that are already associated with the selected ESS Rack.
    // Result will come back in order of Rack Column Name.
    // Then Fetch the Rack Column ID associated with each column name.
    fetchThisRackSds();
    fetchRackColumnIds();
  }, [essConfig.essId, selectedRack]);

  const fetchCurrentSdsInfo = () => {
    fetchAllSds();
    fetchThisRackSds();
    fetchRackColumnIds();
  };

  const fetchAllSds = () => {
    // Fetch all SDSs that don't have a meter ID or ESS ID associated with them,
    // or are associated to the current ESS ID.
    // This is so we can both know SDSs already associated with ESS rack columns
    // as well as those who are yet to be assigned to any other device.
    fetch('/control/get_sds_rack_info.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ess_id: essConfig.essId })
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((sds_json) => {
        if (sds_json !== undefined) {
          setSdss(sds_json);
        }
      });
  };

  const fetchThisRackSds = () => {
    // Add SDSs to state that are already associated with the selected ESS Rack.
    // Result will come back in order of Rack Column Name.
    fetch('/control/get_sds_rack_info.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ess_id: essConfig.essId,
        rack_num: selectedRack,
        get_rack_column_ids: 'no'
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((sds_json) => {
        if (sds_json !== undefined) {
          setCurrentRacksSDSs(sds_json);
        }
      });
  };

  const fetchRackColumnIds = () => {
    fetch('/control/get_sds_rack_info.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ess_id: essConfig.essId,
        rack_num: selectedRack,
        get_rack_column_ids: 'yes'
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((json) => {
        if (json !== undefined) {
          setRackColumnNameId(json);
        }
      });
  };

  const handleSdsReload = () => {
    fetchThisRackSds();
    fetchAllSds();
  };

  // Check to see if currentRacksSDSs has am entry for the specified column name.
  const SDSAssignedToRackColumn = (col_name) => {
    let hasMatch = false;
    for (let index = 0; index < currentRacksSDSs.length; index++) {
      if (currentRacksSDSs[index].rack_column_name === col_name) {
        hasMatch = true;
        break;
      }
    }
    return hasMatch;
  };

  const getRackColumnTableId = (col_num) => {
    const thisColName = String.fromCharCode(col_num + 'A'.charCodeAt());
    let thisID = 0;
    Object.keys(rackColumnNameId).map((key) => {
      if (rackColumnNameId[key].column_name === thisColName) {
        thisID = rackColumnNameId[key].col_id;
      }
    });
    return thisID;
  };

  // pixel offsets for SDSs relative to .ess-rack parent
  const rowHeight = 84;
  const sdsTopOffset = 34;

  // SDS position based on BMU row height and total number of rows.
  const sdsTopPx = essConfig.numRows * rowHeight + sdsTopOffset;
  const sdsTopPxPosition = {
    top: `${sdsTopPx}px`
  };

  return (
    <div className='sds-container' style={sdsTopPxPosition}>
      {Array.from(Array(essConfig.numCols).keys()).map((col) => {
        const this_col_name = String.fromCharCode(col + 'A'.charCodeAt());
        let currentSDSObject = {};
        let itemKey = 0;

        // If an SDS is assigned to this rack column. Make sure col matches up with rack column name when applicable.
        if (SDSAssignedToRackColumn(this_col_name)) {
          // Filter the object so that the only item is the current column's SDS object.
          currentSDSObject = Object.keys(currentRacksSDSs).reduce(
            (returnObj, e) => {
              if (currentRacksSDSs[e].rack_column_name === this_col_name) {
                returnObj[e] = currentRacksSDSs[e];
              }
              return returnObj;
            },
            {}
          );

          itemKey = Object.entries(currentSDSObject).map(([key, sdsItem]) => {
            if (sdsItem.rack_column_name === this_col_name) {
              return key;
            }
          });
        }

        if (viewMode === 'Monitor') {
          return (
            <Sds
              sds_id={
                SDSAssignedToRackColumn(this_col_name)
                  ? currentSDSObject[itemKey].sds_id
                  : ''
              }
              rack_column_name={
                SDSAssignedToRackColumn(this_col_name)
                  ? currentSDSObject[itemKey].rack_column_name
                  : ''
              }
              mac_address={
                SDSAssignedToRackColumn(this_col_name)
                  ? currentSDSObject[itemKey].mac_address
                  : ''
              }
              modbus_line={
                SDSAssignedToRackColumn(this_col_name)
                  ? currentSDSObject[itemKey].modbus_line
                  : ''
              }
              sdsUnassigned={!SDSAssignedToRackColumn(this_col_name)}
              currentColNum={col} // Starts at 0.
              key={col}
            />
          );
        } else if (viewMode === 'Configure') {
          return (
            <SdsSelect
              sdss={sdss}
              sds_id={
                SDSAssignedToRackColumn(this_col_name)
                  ? currentSDSObject[itemKey].sds_id
                  : ''
              }
              rack_column_name={this_col_name}
              mac_address={
                SDSAssignedToRackColumn(this_col_name)
                  ? currentSDSObject[itemKey].mac_address
                  : ''
              }
              rack_column_db_table_id={getRackColumnTableId(col)}
              handleSdsReload={handleSdsReload}
              key={col}
            />
          );
        }
      })}
    </div>
  );
});

SdsRow.propTypes = {
  viewMode: PropTypes.string,
  currentRacksSDSs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setCurrentRacksSDSs: PropTypes.func
};

export default SdsRow;
