import React from 'react';
import PropTypes from 'prop-types';

export const StatusCircle = ({ className, title }) => (
  <div className={className} title={title} />
);

StatusCircle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};
