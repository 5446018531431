/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const BmuCell = React.memo(function BmuCell({
  cell_voltage,
  cellNum,
  column_name,
  onlineStatus
}) {
  const [columnPositionClass, setColumnPositionClass] =
    useState('cell-voltage-left');

  // For Apparent systems, estimated min/max cell voltages:
  const cellvoltageGettingTooHigh = 3.6; // Alarm value.
  const maxcellvoltage = 3.55; // For Apparent batteries only. When we should stop charging.
  const mincellvoltage = 3.0; // TODO: Get this value from the aess_bmu_data table instead.
  const cellvoltageGettingTooLow = 2.9; // Value when the cell voltage bar turns Red.
  const cellvoltageLowerBounds = 2.7;

  // Determine cellBarPercent. Bar minimum represents the cellvoltageLowerBounds,
  // upper bounds represents maxcellvoltage.
  let cellBarPercent =
    (Math.abs(cellvoltageLowerBounds - cell_voltage) * 100) /
    (maxcellvoltage - cellvoltageLowerBounds);

  // Don't make height longer than 100% if the cell voltage goes past the max cell voltage.
  cellBarPercent = Math.min(cellBarPercent, 100);
  if (cell_voltage <= cellvoltageLowerBounds) {
    cellBarPercent = 3;
  } // Give some bar height so that the near-empty bar is visible.

  // Determine what the bar percentage background color should be.
  // Green. Indicates the cell voltage is in a good range.
  let cellPercentBackground = '#00FF00';
  if (
    (cell_voltage <= mincellvoltage &&
      cell_voltage > cellvoltageGettingTooLow) ||
    (cell_voltage > maxcellvoltage && cell_voltage <= cellvoltageGettingTooHigh)
  ) {
    cellPercentBackground = 'yellow';
  } else if (
    cell_voltage <= cellvoltageGettingTooLow ||
    cell_voltage > cellvoltageGettingTooHigh
  ) {
    cellPercentBackground = '#FF1A1A'; // Red.
  }

  // Set the column position class for the cell voltage bars
  // to set their left position offset value via class.
  // This css value would not work in the custom css style
  // object below so I made a separate class for it.
  useEffect(() => {
    if (column_name == 'A') {
      setColumnPositionClass('cell-voltage-left');
    } else if (column_name == 'B') {
      setColumnPositionClass('cell-voltage-right');
    }
  }, [column_name]);

  const cellVoltageCustomStyle = {
    height: `${cellBarPercent}%`,
    backgroundColor: cellPercentBackground
  };

  function isBurst() {
    if (cell_voltage > maxcellvoltage) {
      return (
        <div className='bar-burst-outer'>
          <div className='bar-burst-inner'>
            <div
              className={`bar-burst ${onlineStatus}`}
              style={{ backgroundColor: cellPercentBackground }}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <td
      className={`bmu-cell-table-cell bmu-cell bmu-cell-${cellNum}`}
      title={`${cell_voltage} V`}
    >
      <div
        className={`bmu-cell-voltage-bar cell-voltage cell-voltage-${cellNum}`}
      >
        <div className='cell-voltage-percent-shape'>
          <div
            className={`bmu-cell-voltage-bar cell-voltage-percent cell-voltage-percent-${cellNum} ${columnPositionClass} ${onlineStatus}`}
            style={cellVoltageCustomStyle}
          />
        </div>
      </div>

      {isBurst()}
    </td>
  );
});

BmuCell.propTypes = {
  cell_voltage: PropTypes.string,
  cellNum: PropTypes.string,
  column_name: PropTypes.string,
  onlineStatus: PropTypes.string
};

export default BmuCell;
