import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'luxon';
import 'chartjs-adapter-luxon';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import Annotation from 'chartjs-plugin-annotation';
import { debounce } from 'lodash';

import {
  TIME_FRAME_BILLING,
  TIME_FRAME_MAX,
  TIME_FRAME_MONTH,
  TIME_FRAME_YEAR,
  intervalMap
} from '../charts/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  Annotation
);

export const CostChart = ({
  dataSource,
  chartRef,
  selectedTimeFrame,
  updateTouState,
  minXState,
  maxXState,
  annotations,
  style,
  perspective,
  meterIds = false,
  siteId = false,
  timezone,
  energyStream = false,
  costKWH = 0.28
}) => {
  const [updateTOU, setUpdateTOU] = updateTouState;
  const [minX, setMinX] = minXState;
  const [maxX, setMaxX] = maxXState;
  const [moneySpent, setMoneySpent] = useState([]);
  const [moneySavedBattery, setMoneySavedBattery] = useState([]);
  const [moneySavedSolar, setMoneySavedSolar] = useState([]);
  const [totalSpent, setTotalSpent] = useState(0);
  const [totalSavedBattery, setTotalSavedBattery] = useState(0);
  const [totalSavedSolar, setTotalSavedSolar] = useState(0);
  const [incomingCost, setIncomingCost] = useState(0);

  const getSuggestedMax = () => {
    // let max = 1;
    // if (moneySavedBattery && moneySavedSolar && moneySpent) {
    //   for (
    //     let i = 0;
    //     i < moneySpent.length &&
    //     i < moneySavedBattery.length &&
    //     i < moneySavedSolar.length;
    //     i += 1
    //   ) {
    //     let sum =
    //       moneySavedBattery[i]['y'] +
    //       moneySavedSolar[i]['y'] +
    //       moneySpent[i]['y'];
    //     max = sum > max ? sum : max;
    //   }
    // }
    // return max * 1.05;
    return 0.05;
  };

  const responseHandler = (data) => {
    setMoneySpent(data['spent']);
    setMoneySavedBattery(data['savedBattery']);
    setMoneySavedSolar(data['savedSolar']);
    setTotalSpent(data['totalSpent']);
    setTotalSavedBattery(data['totalSavedBattery']);
    setTotalSavedSolar(data['totalSavedSolar']);
    !energyStream ? setIncomingCost(data['incomingCost']) : setIncomingCost(0);
  };

  const fetchData = () => {
    if (energyStream) {
      if (selectedTimeFrame == TIME_FRAME_YEAR) {
        dataSource.fetchMoneyInfoYearly(
          minX,
          maxX,
          siteId,
          perspective,
          timezone,
          costKWH,
          responseHandler
        );
      } else {
        dataSource.fetchMoneyInfo(
          minX,
          maxX,
          intervalMap[selectedTimeFrame],
          siteId,
          perspective,
          timezone,
          costKWH,
          responseHandler
        );
      }
    } else {
      dataSource.fetchMoneyInfo(
        minX,
        maxX,
        intervalMap[selectedTimeFrame],
        meterIds,
        perspective,
        responseHandler
      );
    }
  };

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchData();
    }, 200);
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, [minX, maxX]);

  const costOptions = {
    maintainAspectRatio: false,
    plugins: {
      annotation: {
        annotations: annotations
          ? [
              ...annotations.now,
              ...annotations.month,
              ...annotations.touRegions,
              ...annotations.startOfMonth
            ]
          : []
      },
      legend: {
        title: {
          display: true,
          padding: { top: -40, bottom: energyStream ? 50 : 60 },
          position: 'center',
          text: () => {
            if (
              chartRef &&
              chartRef.current &&
              totalSpent != null &&
              totalSavedBattery != null &&
              totalSavedSolar != null
            ) {
              return [
                'Totals',
                'Imported: $' + totalSpent.toFixed(2),
                'Battery Produced: $' + totalSavedBattery.toFixed(2),
                'Solar Produced: $' + totalSavedSolar.toFixed(2),
                !energyStream
                  ? 'Incoming Cost (24h): $' + incomingCost.toFixed(2)
                  : '',
                ' ',
                ' ',
                ' ',
                ' ',
                ' ',
                '**This assumes a fixed',
                'rate of $' + costKWH.toFixed(2) + ' per kWh'
              ];
            } else {
              return 'No data.';
            }
          },
          color: style.color,
          font: { size: 13 }
        },
        position: 'right',
        labels: {
          font: { size: 13 },
          color: style.color
        }
      },
      customBackgroundColor: {
        color: style.backgroundColor
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          onPanComplete({ chart }) {
            setMinX(new Date(chart.scales.x.min));
            setMaxX(new Date(chart.scales.x.max));
          }
        },
        zoom: {
          wheel: {
            enabled: true
          },
          mode: 'x',
          async onZoomComplete({ chart }) {
            setMinX(new Date(chart.scales.x.min));
            setMaxX(new Date(chart.scales.x.max));
          }
        }
      },
      tooltip: {
        interaction: {
          mode: 'nearest'
        },
        titleAlign: 'center',
        callbacks: {
          title: (item) => {
            if (selectedTimeFrame == TIME_FRAME_YEAR) {
              let date = item[0].parsed['x'];
              return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long'
              });
            }
            if (
              selectedTimeFrame == TIME_FRAME_BILLING ||
              selectedTimeFrame == TIME_FRAME_MONTH ||
              selectedTimeFrame == TIME_FRAME_MAX
            ) {
              let date = item[0].parsed['x'];
              return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              });
            }
            return item[0].label;
          },
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              if (context.dataset.label === 'State of Charge') {
                label += context.parsed.y.toFixed(2) + '%';
              } else {
                label += '$' + context.parsed.y.toFixed(2);
              }
            }
            return label;
          }
        }
      }
    },
    onResize: () => {
      setTimeout(() => {
        setUpdateTOU(!updateTOU);
      }, 100);
    },
    responsive: true,
    transitions: {
      zoom: {
        animation: {
          duration: 0
        }
      }
    },
    animation: false,
    scales: {
      x: {
        stacked: true,
        offset: false,
        min: minX,
        max: maxX,
        type: 'time',
        time: {
          unit: () => {
            if (maxX.getTime() - minX.getTime() > 24 * 60 * 60 * 1000) {
              if (selectedTimeFrame == TIME_FRAME_YEAR) {
                return 'month';
              } else {
                return 'day';
              }
            } else {
              return 'hour';
            }
          },
          displayFormats: {
            day: 'EEE - d',
            hour: 'h a'
          },
          stepSize: 1
        },
        adapters: {
          date: {
            zone: timezone
          }
        },
        grid: {
          display: false,
          drawTicks: false,
          offset: false,
          drawOnChartArea: true,
          color: style.gridColor
        },
        ticks: {
          // https://stackoverflow.com/questions/71733581/how-to-introduce-newline-character-in-xaxes-label-with-chart-js
          callback: (tick) => {
            return tick.includes('-')
              ? tick.split('-')
              : tick == '12 PM'
              ? 'Noon'
              : tick;
          },
          maxTicksLimit: selectedTimeFrame == TIME_FRAME_YEAR ? 13 : 8,
          font: { size: 12 },
          color: style.color,
          includeBounds: false,
          align: 'center'
        }
      },
      y: {
        stacked: true,
        min: 0,
        suggestedMax: getSuggestedMax(),
        grid: {
          color: style.gridColor,
          drawTicks: false
        },
        display: true,
        position: 'left',
        ticks: {
          callback: (value) => '      $' + value.toFixed(2),
          font: { size: 12 },
          color: style.color
        },
        type: 'linear'
      }
    }
  };

  const costData = {
    datasets: [
      {
        label: 'Import From Grid',
        data: moneySpent,
        borderColor: 'rgb(192,192,192)',
        backgroundColor: 'rgb(192,192,192, 0.5)',
        borderWidth: style.borderWidth,
        borderSkipped: false,
        barPercentage: 0.9,
        yAxisID: 'y'
      },
      {
        label: 'Battery Production',
        data: moneySavedBattery,
        borderColor: 'rgb(124,252,0)',
        backgroundColor: 'rgb(124,252,0,0.5)',
        borderWidth: style.borderWidth,
        borderSkipped: false,
        barPercentage: 0.9,
        yAxisID: 'y'
      },
      {
        label: 'Solar Production',
        data: moneySavedSolar,
        borderColor: 'rgb(255,255,0)',
        backgroundColor: 'rgb(255,255,0,0.5)',
        borderWidth: style.borderWidth,
        borderSkipped: false,
        barPercentage: 0.9,
        yAxisID: 'y'
      }
    ]
  };

  return <Bar ref={chartRef} options={costOptions} data={costData} />;
};

CostChart.propTypes = {
  dataSource: PropTypes.object.isRequired,
  chartRef: PropTypes.object.isRequired,
  selectedTimeFrame: PropTypes.string.isRequired,
  updateTouState: PropTypes.array.isRequired,
  minXState: PropTypes.array.isRequired,
  maxXState: PropTypes.array.isRequired,
  annotations: PropTypes.object,
  style: PropTypes.object.isRequired,
  perspective: PropTypes.number.isRequired,
  meterIds: PropTypes.array,
  siteId: PropTypes.string,
  timezone: PropTypes.string,
  energyStream: PropTypes.bool,
  costKWH: PropTypes.number
};
