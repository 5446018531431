import axios from 'axios';

export function EnergyReviewDataSource(service) {
  this.interval = 30000;
  this.host = service.host;
  this.fetch = (ulids, responseHandler) => {
    if (ulids.length !== 0) {
      const url = this.host + '/rest/data/get-diagram-data';
      const data = JSON.stringify({ ulids: ulids });
      axios
        .post(url, data, {
          headers: { ContentType: 'application/json; charset=utf-8' },
          timeout: 2000
        })
        .then((response) => {
          if (response.status === 200) {
            responseHandler({ status: 'success', data: response.data.results });
          } else {
            responseHandler({ status: 'logout', data: response.message });
          }
        })
        .catch((response) => {
          responseHandler({ status: 'failed', data: response.message });
        });
    }
  };

  this.fetchLayout = (selectedLayout, layoutHandler) => {
    const url = [this.host, 'rest/data/get-layout-data'].join('/');
    const data = JSON.stringify({ filename: selectedLayout });
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          console.log('layout retrieval success');
        } else {
          console.log('layout retrieval failure');
          return;
        }

        const jsonLayout = JSON.parse(response.data.data);
        layoutHandler(jsonLayout);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  };
}
