/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable @shopify/jsx-prefer-fragment-wrappers */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement
} from 'chart.js';

import {
  addDays,
  subDays,
  addMonths,
  subMonths,
  addYears,
  subYears
} from 'date-fns';
import Annotation from 'chartjs-plugin-annotation';
import { debounce } from 'lodash';

import { TimeFrameSelector, ChartSelector } from '../helpers/selectors';

import { PowerChart } from '../charts/powerChart';
import { CostChart } from '../charts/costChart';
import { TouChart } from '../charts/touChart';
import { EnergyChart } from '../charts/energyChart';
import { TotalUsageChart } from '../charts/totalUsageChart';
import { ProjectedGridChart } from '../charts/projectedGridChart';
import {
  TIME_FRAME_DAY,
  TIME_FRAME_WEEK,
  TIME_FRAME_MONTH,
  TIME_FRAME_MAX,
  TIME_FRAME_YEAR,
  TIME_FRAME_BILLING,
  ENERGY_CHART,
  POWER_CHART,
  COST_CHART,
  TOTAL_USAGE_CHART,
  PROJECTED_GRID_CHART
} from '../charts/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  Annotation
);

// React Component that graphs the energy usage over a 24 hour span

export const PerformanceView = ({
  dataSource,
  darkMode,
  meter_ids,
  site_id,
  energyStream = false,
  timezone,
  mobile = false,
  costKWH
}) => {
  const PRODUCTION = 0;
  const borderWidth = 2;
  let dateTime = DateTime.fromObject(
    { hour: 0, minute: 0, second: 0 },
    { zone: timezone }
  );
  let today = new Date(dateTime);

  const selectedTimeFrameState = useState(TIME_FRAME_WEEK);
  const [selectedTimeFrame, setSelectedTimeFrame] = selectedTimeFrameState;
  const selectedChartState = useState(
    energyStream ? ENERGY_CHART : POWER_CHART
  );
  const [selectedChart, setSelectedChart] = selectedChartState;
  const [totalSelected, setTotalSelected] = false;
  const [startDate, setStartDate] = useState(today);
  const [setDate, setSetDate] = useState(today);

  const [color, setColor] = useState(darkMode ? 'white' : 'black');
  const [backgroundColor, setBackgroundColor] = useState(
    darkMode ? 'black' : 'white'
  );
  const [gridColor, setGridColor] = useState(darkMode ? 'black' : 'white');

  const [perspective, setPerspective] = useState(PRODUCTION);
  const [meterIds, setMeterIds] = useState(meter_ids);
  const [checkedForForecast, setCheckedForForecast] = useState(false);

  const minXState = useState(subDays(startDate, 7));
  const maxXState = useState(addDays(startDate, 3));
  const [minX, setMinX] = minXState;
  const [maxX, setMaxX] = maxXState;
  const [billingPeriodStart, setBillingPeriodStart] = minXState;
  const [billingPeriodEnd, setBillingPeriodEnd] = maxXState;
  const [endOfData, setEndOfData] = useState(false);
  const [showActual, setShowActual] = useState(
    localStorage.getItem('showActual') === 'true' ||
      localStorage.getItem('showActual') === null
  );
  const [showProjected, setShowProjected] = useState(
    localStorage.getItem('showProjected') === 'true'
  );

  const [tou, setTOU] = useState([]);
  const [touRegions, setTouRegions] = useState([]);
  const updateTouState = useState(false);
  const [updateTOU, setUpdateTOU] = updateTouState;
  const chartRef = useRef(null);

  const getMonthAnnotations = () => {
    let timeDiff = maxX.getTime() - minX.getTime();
    let multiDay = timeDiff > 24 * 60 * 60 * 1000;
    let chartMax =
      chartRef && chartRef.current ? chartRef.current.scales.y.max : 1;
    let yValue = chartMax * 0.9;
    let annotation = {
      type: 'label',
      xScaleID: 'x',
      yMin: yValue,
      yMax: chartMax * 0.8,
      textAlign: 'left',
      backgroundColor: backgroundColor
    };

    let currentDate = new Date(minX);
    let annotations = [];
    while (currentDate < maxX) {
      let shortMonth = currentDate.toLocaleString('default', {
        month: 'short'
      });
      let content = multiDay
        ? shortMonth
        : `${shortMonth} ${currentDate.getDate()}`;
      let xMax = new Date(currentDate.getTime() + timeDiff * 0.05);
      annotations.push({
        ...annotation,
        content: [content],
        font: { size: 12 },
        color: color,
        xMin: new Date(currentDate),
        xMax: xMax
      });
      currentDate.setDate(1);
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setHours(0, 0, 0, 0);
    }

    return annotations;
  };

  const [monthAnnotations, setMonthAnnotations] = useState(
    getMonthAnnotations()
  );

  const getStartOfMonthAnnotations = () => {
    let currentDate = new Date(minX);
    let annotations = [];
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setHours(0, 0, 0, 0);
    while (currentDate < maxX) {
      annotations.push({
        type: 'line',
        xMin: new Date(currentDate),
        xMax: new Date(currentDate),
        xScaleID: 'x',
        borderColor: color,
        borderWidth: 1
      });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return annotations;
  };

  const [startOfMonthAnnotations, setStartOfMonthAnnotations] = useState(
    getStartOfMonthAnnotations()
  );

  const getNowAnnotation = () => {
    let now = new Date();
    let line = {
      type: 'line',
      xMin: now,
      xMax: now,
      xScaleID: 'x',
      borderColor: color,
      borderWidth: 1,
      borderDash: [10, 1]
    };

    let minutes =
      chartRef && chartRef.current
        ? (chartRef.current.scales.x.max - chartRef.current.scales.x.min) /
          (60 * 1000)
        : 7 * 24 * 60;
    const labelNow = new Date(now);
    let chartYMax =
      chartRef && chartRef.current ? chartRef.current.scales.y.max : 1;
    let yValue = chartYMax * 0.85;
    let label = {
      type: 'label',
      xValue: labelNow.setMinutes(labelNow.getMinutes() - minutes * 0.025),
      yValue: yValue,
      content: 'Now',
      color: color
    };
    return [line, label];
  };

  useEffect(() => {
    setNowAnnotation(getNowAnnotation());
  }, [minX, maxX]);

  const [nowAnnotations, setNowAnnotation] = useState(getNowAnnotation());

  const touHandler = (data) => {
    if (!energyStream) {
      setTOU(data['touChart']);
    }

    let peaks = data['peaks'];
    let peakStartWeekday = peaks[0];
    let peakEndWeekday = peaks[1];
    let peakStartWeekend = peaks[2];
    let peakEndWeekend = peaks[3];
    let regions = [];

    let annotation = {
      type: 'box',
      xScaleID: 'x',
      backgroundColor: 'rgb(211,211,211,0.3)',
      borderColor: 'rgb(211,211,211,0.3)'
    };

    for (let i = new Date(minX); i <= maxX; i = addDays(i, 1)) {
      let dayOfWeek = i.getDay();
      let xMin = 0;
      let xMax = 0;

      // use weekend peaks for weekends
      i.setMinutes(0);
      if (dayOfWeek == 0 || dayOfWeek == 6) {
        if (peakStartWeekend > peakEndWeekend) {
          xMin = i.setHours(peakStartWeekend);
          xMax = addDays(i.setHours(peakEndWeekend), 1);
        } else {
          xMin = i.setHours(peakStartWeekend);
          xMax = i.setHours(peakEndWeekend);
        }
      } else {
        if (peakStartWeekday > peakEndWeekday) {
          xMin = i.setHours(peakStartWeekday);
          xMax = addDays(i.setHours(peakEndWeekday), 1);
        } else {
          xMin = i.setHours(peakStartWeekday);
          xMax = i.setHours(peakEndWeekday);
        }
      }
      if (xMin != xMax) {
        regions.push({ ...annotation, xMin: xMin, xMax: xMax });
      }
    }
    setTouRegions(regions);
  };

  const fetchData = () => {
    if (energyStream) {
      // dataSource.fetchTOU(minX, maxX, site_id, touHandler);
    } else {
      dataSource.fetchTOU(minX, maxX, touHandler);
    }
    setMonthAnnotations(getMonthAnnotations());
    setStartOfMonthAnnotations(getStartOfMonthAnnotations());
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem('showActual', showActual);
  }, [showActual]);

  useEffect(() => {
    localStorage.setItem('showProjected', showProjected);
  }, [showProjected]);

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchData();
      setNowAnnotation(getNowAnnotation());
    }, 200);
    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
    };
  }, [minX, maxX]);

  const triggerTOURender = () => {
    setTimeout(() => {
      setUpdateTOU(!updateTOU);
    }, 100);
  };

  const dark = '#3e3e3e';
  const light = 'white';
  const grey = '#dfdfdf';

  useEffect(() => {
    setBackgroundColor(darkMode ? 'black' : light);
    setColor(darkMode ? light : dark);
    setGridColor(darkMode ? light : grey);
  }, [darkMode]);

  useEffect(() => {
    if (selectedTimeFrame == TIME_FRAME_BILLING) {
      if (site_id) {
        dataSource.fetchBillingPeriod(setDate, site_id, billingPeriodHandler);
      } else {
        dataSource.fetchBillingPeriod(setDate, billingPeriodHandler);
      }
    } else {
      let start, end;
      if (selectedTimeFrame == TIME_FRAME_DAY) {
        start = startDate;
        end = addDays(startDate, 1);
      } else if (selectedTimeFrame == TIME_FRAME_WEEK) {
        start =
          startDate < subDays(setDate, 6) ? startDate : subDays(setDate, 6);
        end =
          startDate < subDays(setDate, 6)
            ? addDays(startDate, 7)
            : addDays(setDate, 1);
      } else if (selectedTimeFrame == TIME_FRAME_MONTH) {
        start =
          startDate < subMonths(setDate, 1) ? startDate : subMonths(setDate, 1);
        end =
          startDate < subMonths(setDate, 1)
            ? addMonths(startDate, 1)
            : addDays(setDate, 1);
      } else if (selectedTimeFrame == TIME_FRAME_MAX) {
        start = subDays(setDate, 59);
        end = addDays(setDate, 1);
      } else if (selectedTimeFrame == TIME_FRAME_YEAR) {
        start = subYears(setDate, 1);
        end = addDays(setDate, 1);
      }
      setMinX(start);
      setMaxX(end);
    }
  }, [selectedTimeFrame, startDate]);

  const billingPeriodHandler = (data) => {
    if (data['start'] !== -1 && data['end'] !== -1) {
      const tempStart = new Date(data['start']);
      const tempEnd = new Date(data['end']);
      const offset = tempStart.getTimezoneOffset() * 60000;
      setMinX(new Date(tempStart.getTime() + offset));
      setMaxX(new Date(tempEnd.getTime() + offset));
      setBillingPeriodStart(new Date(tempStart.getTime() + offset));
      setBillingPeriodEnd(new Date(tempEnd.getTime() + offset));
      setEndOfData(false);
    } else {
      setMinX(billingPeriodStart);
      setMaxX(billingPeriodEnd);
      setEndOfData(true);
    }
  };

  const perspectiveHandler = (data) => {
    setPerspective(data['perspective']);
  };

  useEffect(() => {
    if (!energyStream) {
      dataSource.fetchPerspective(perspectiveHandler);
    }
  }, []);

  const plugin = {
    id: 'customBackgroundColor',
    beforeDraw: (chart, args, options) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = options.color;
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };

  ChartJS.register(plugin);
  const style = {
    color: color,
    gridColor: gridColor,
    backgroundColor: backgroundColor,
    borderWidth: borderWidth
  };

  const selectedChartHandler = () => {
    let annotations;
    if (selectedTimeFrame != TIME_FRAME_YEAR) {
      annotations = {
        month: monthAnnotations,
        now: nowAnnotations,
        touRegions: touRegions,
        startOfMonth: startOfMonthAnnotations
      };
    }

    if (selectedChart == POWER_CHART) {
      if (
        energyStream &&
        (selectedTimeFrame == TIME_FRAME_YEAR ||
          selectedTimeFrame == TIME_FRAME_MONTH)
      ) {
        setSelectedTimeFrame(TIME_FRAME_WEEK);
      }
      return (
        <PowerChart
          dataSource={dataSource}
          chartRef={chartRef}
          selectedTimeFrame={selectedTimeFrame}
          startDate={startDate}
          setDate={setDate}
          updateTouState={updateTouState}
          minXState={minXState}
          maxXState={maxXState}
          annotations={annotations}
          style={style}
          perspective={perspective}
          meterIds={meterIds}
          siteId={site_id}
          timezone={timezone}
          energyStream={energyStream}
          showProjected={showProjected}
          showActual={showActual}
        />
      );
    } else if (selectedChart == COST_CHART) {
      return (
        <CostChart
          dataSource={dataSource}
          chartRef={chartRef}
          selectedTimeFrame={selectedTimeFrame}
          startDate={startDate}
          setDate={setDate}
          updateTouState={updateTouState}
          minXState={minXState}
          maxXState={maxXState}
          annotations={annotations}
          borderWidth={borderWidth}
          style={style}
          perspective={perspective}
          meterIds={meterIds}
          siteId={site_id}
          timezone={timezone}
          energyStream={energyStream}
          costKWH={costKWH}
        />
      );
    } else if (selectedChart == ENERGY_CHART) {
      return (
        <EnergyChart
          dataSource={dataSource}
          chartRef={chartRef}
          selectedTimeFrame={selectedTimeFrame}
          startDate={startDate}
          setDate={setDate}
          updateTouState={updateTouState}
          minXState={minXState}
          maxXState={maxXState}
          annotations={annotations}
          borderWidth={borderWidth}
          style={style}
          perspective={perspective}
          meterIds={meterIds}
          siteId={site_id}
          timezone={timezone}
          energyStream={energyStream}
        />
      );
    } else if (selectedChart == TOTAL_USAGE_CHART) {
      if (
        selectedTimeFrame == TIME_FRAME_DAY ||
        selectedTimeFrame == TIME_FRAME_WEEK
      ) {
        setSelectedTimeFrame(TIME_FRAME_YEAR);
      }
      return (
        <TotalUsageChart
          dataSource={dataSource}
          chartRef={chartRef}
          selectedTimeFrame={selectedTimeFrame}
          startDate={startDate}
          setDate={setDate}
          updateTouState={updateTouState}
          minXState={minXState}
          maxXState={maxXState}
          annotations={annotations}
          borderWidth={borderWidth}
          style={style}
          perspective={perspective}
          meterIds={meterIds}
          siteId={site_id}
          timezone={timezone}
          energyStream={energyStream}
        />
      );
    } else if (selectedChart == PROJECTED_GRID_CHART) {
      return (
        <ProjectedGridChart
          dataSource={dataSource}
          chartRef={chartRef}
          selectedTimeFrame={selectedTimeFrame}
          startDate={startDate}
          setDate={setDate}
          updateTouState={updateTouState}
          minXState={minXState}
          maxXState={maxXState}
          annotations={annotations}
          borderWidth={borderWidth}
          style={style}
          perspective={perspective}
          siteId={site_id}
          timezone={timezone}
          energyStream={energyStream}
          costKWH={costKWH}
        />
      );
    }
  };

  const getPerspective = () => {
    return perspective == PRODUCTION ? '+Export/-Import' : '+Import/-Export';
  };

  const getChartHeight = () => {
    if (darkMode) {
      if (mobile) {
        return { height: '65vh' };
      }
      return { height: '32vh' };
    }
  };

  const getHeaderHeight = () => {
    if (darkMode) {
      if (mobile) {
        return '15vh';
      }
      return '7vh';
    }
    return null;
  };

  const getTOUChart = () => {
    if (!darkMode) {
      return (
        <TouChart
          tou={tou}
          chartRef={chartRef}
          minX={minX}
          maxX={maxX}
          style={style}
          backgroundColor={backgroundColor}
        />
      );
    }
  };

  const setBillingTimeFrame = (direction) => {
    let current = direction
      ? subDays(billingPeriodStart, 1)
      : addDays(billingPeriodEnd, 1);
    let now = new Date();
    if (current < now) {
      if (site_id) {
        dataSource.fetchBillingPeriod(current, site_id, billingPeriodHandler);
      } else {
        dataSource.fetchBillingPeriod(current, billingPeriodHandler);
      }
    } else {
      setMinX(billingPeriodStart);
      setMaxX(billingPeriodEnd);
    }
  };

  const checkTimeframeSelected = () => {
    if (selectedTimeFrame === TIME_FRAME_BILLING) {
      return (
        <div className='billing-timeframe-body'>
          <Button
            className='base-style'
            style={{
              backgroundColor: backgroundColor,
              color: color
            }}
            disabled={endOfData}
            onClick={() => setBillingTimeFrame(true)}
          >
            <i style={{ color: color }} className='fa fa-arrow-left' />
          </Button>
          <div
            className='base-style'
            style={{
              backgroundColor: backgroundColor,
              color: color
            }}
          >
            {billingPeriodStart.toLocaleString('en-us', {
              month: 'numeric',
              day: 'numeric'
            })}
            {'  '}-{'  '}
            {billingPeriodEnd.toLocaleString('en-us', {
              month: 'numeric',
              day: 'numeric'
            })}
          </div>
          <Button
            className='base-style'
            style={{
              backgroundColor: backgroundColor,
              color: color
            }}
            disabled={billingPeriodEnd >= new Date()}
            onClick={() => setBillingTimeFrame(false)}
          >
            <i style={{ color: color }} className='fa fa-arrow-right' />
          </Button>
        </div>
      );
    }
  };

  return (
    <div
      className='performance-view'
      style={{
        marginLeft: mobile ? '0px' : '20px',
        marginTop: mobile ? '0' : '-1vh'
      }}
    >
      <div
        className='performance-view-body'
        style={{
          backgroundColor: backgroundColor,
          height: getHeaderHeight(),
          marginLeft: mobile ? '0px' : '20px',
          width: mobile ? '100%' : '95%'
        }}
      >
        <div
          className='timeframe-selector'
          style={{
            backgroundColor: backgroundColor,
            width: mobile ? '35%' : '25%'
          }}
        >
          {selectedChart !== TOTAL_USAGE_CHART ? (
            <TimeFrameSelector
              timeFrame={TIME_FRAME_DAY}
              selectedTimeFrameState={selectedTimeFrameState}
              backgroundColor={backgroundColor}
              color={color}
            />
          ) : (
            <div />
          )}
          {selectedChart !== TOTAL_USAGE_CHART ? (
            <TimeFrameSelector
              timeFrame={TIME_FRAME_WEEK}
              selectedTimeFrameState={selectedTimeFrameState}
              backgroundColor={backgroundColor}
              color={color}
            />
          ) : (
            <div />
          )}
          {selectedChart !== POWER_CHART || !energyStream ? (
            <TimeFrameSelector
              timeFrame={TIME_FRAME_MONTH}
              selectedTimeFrameState={selectedTimeFrameState}
              backgroundColor={backgroundColor}
              color={color}
            />
          ) : (
            <div />
          )}
          {energyStream && selectedChart != POWER_CHART ? (
            <TimeFrameSelector
              timeFrame={TIME_FRAME_YEAR}
              selectedTimeFrameState={selectedTimeFrameState}
              backgroundColor={backgroundColor}
              color={color}
            />
          ) : (
            <div />
          )}
          {!energyStream ? (
            <TimeFrameSelector
              timeFrame={TIME_FRAME_MAX}
              selectedTimeFrameState={selectedTimeFrameState}
              backgroundColor={backgroundColor}
              color={color}
            />
          ) : (
            <div />
          )}
          {!energyStream ? (
            <TimeFrameSelector
              timeFrame={TIME_FRAME_BILLING}
              selectedTimeFrameState={selectedTimeFrameState}
              backgroundColor={backgroundColor}
              color={color}
            />
          ) : (
            <div />
          )}
        </div>
        <h3
          className='center-header'
          style={{ color: color, width: energyStream ? '0%' : '20%' }}
        >
          <div>{checkTimeframeSelected()}</div>
        </h3>
        <div
          className='chart-selector'
          style={{
            backgroundColor: backgroundColor,
            width: !mobile ? '70%' : '60%',
            marginTop: mobile ? '15px' : '1vh'
          }}
        >
          <div>
            <div
              className='perspective-body'
              style={{
                backgroundColor: backgroundColor,
                color: color
              }}
            >
              <div
                className='perspective'
                style={{
                  fontWeight: 'bold',
                  fontSize: mobile ? 'small' : 'large',
                  marginTop: mobile ? '1.5vh' : '0'
                }}
              >
                {getPerspective()}
              </div>
            </div>
          </div>
          {!mobile ? (
            <div className='date-selector'>
              {energyStream ? (
                <DatePicker.default
                  selected={startDate}
                  showPopperArrow={false}
                  maxDate={setDate}
                  fixedHeight
                  onChange={(date) => setStartDate(date)}
                  portalId='root-portal'
                  withPortal
                />
              ) : (
                <DatePicker.default
                  selected={startDate}
                  showPopperArrow={false}
                  fixedHeight
                  includeDateIntervals={[
                    { start: subDays(setDate, 60), end: setDate }
                  ]}
                  onChange={(date) => setStartDate(date)}
                  portalId='root-portal'
                  withPortal
                />
              )}
            </div>
          ) : (
            <div />
          )}
          {/* {!energyStream ? ( */}
          <ChartSelector
            chart={COST_CHART}
            selectedChartState={selectedChartState}
            backgroundColor={backgroundColor}
            color={color}
            mobile={mobile}
          />
          {/* ) : (
            <div />
          )} */}
          <ChartSelector
            chart={ENERGY_CHART}
            selectedChartState={selectedChartState}
            backgroundColor={backgroundColor}
            color={color}
            mobile={mobile}
          />
          <ChartSelector
            chart={POWER_CHART}
            selectedChartState={selectedChartState}
            backgroundColor={backgroundColor}
            color={color}
            mobile={mobile}
          />
          {energyStream ? (
            <ChartSelector
              chart={TOTAL_USAGE_CHART}
              selectedChartState={selectedChartState}
              backgroundColor={backgroundColor}
              color={color}
              mobile={mobile}
            />
          ) : (
            <div />
          )}
          {energyStream ? (
            <ChartSelector
              chart={PROJECTED_GRID_CHART}
              selectedChartState={selectedChartState}
              backgroundColor={backgroundColor}
              color={color}
              mobile={mobile}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
      {!energyStream && selectedChart == POWER_CHART ? (
        <div
          style={{
            float: 'right',
            marginBottom: '-5vh',
            zIndex: '10',
            position: 'relative'
          }}
        >
          <div style={{ float: 'right', marginRight: '10px' }}>
            <strong style={{ marginBottom: '-2px', color: 'black' }}>
              Show Actual Values:{' '}
            </strong>
            <input
              type='checkbox'
              id='actual'
              name='actual'
              aria-label='actual'
              checked={showActual}
              onChange={() => setShowActual(!showActual)}
            />
          </div>
          <br />
          <div style={{ float: 'right', marginRight: '10px' }}>
            <strong style={{ marginBottom: '-2px', color: 'black' }}>
              Show Projected Values:{' '}
            </strong>
            <input
              type='checkbox'
              id='projected'
              name='projected'
              aria-label='projected'
              checked={showProjected}
              onChange={() => setShowProjected(!showProjected)}
            />
          </div>
        </div>
      ) : (
        <div />
      )}
      <div style={getChartHeight()} className='chart-body'>
        {selectedChartHandler()}
      </div>
      {getTOUChart()}
    </div>
  );
};

PerformanceView.propTypes = {
  dataSource: PropTypes.object.isRequired,
  darkMode: PropTypes.bool.isRequired,
  meter_ids: PropTypes.any,
  site_id: PropTypes.string,
  energyStream: PropTypes.bool,
  timezone: PropTypes.string,
  mobile: PropTypes.bool,
  costKWH: PropTypes.number
};
