import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { renderReading } from '../readings.js';
import { ElectricBus } from './electricBus.js';
import { isOnline } from '../helpers/isOnline.js';
import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';
import { Arrow } from './arrow.js';

export const BusDispenser = ({
  ulid,
  name,
  xaxis,
  yaxis,
  busIsPresent,
  busSOC,
  arrowLocation,
  timezone,
  isTabletOrMobile
}) => {
  const [readings, setReadings] = useState({});
  const [busDispenserName] = useState(name);
  const [busDispenserReading, setDispenserReading] = useState('');
  const [batterySOC, setBatterySOC] = useState(null);
  const [selectedReading, setSelectedReading] = useState(() => {
    if (localStorage.getItem('selectedReading'.concat(ulid)) != null) {
      return localStorage.getItem('selectedReading'.concat(ulid));
    }
    return 'kw_reading';
  });
  const [online, setOnline] = useState(false);
  const [bBusIsPresent] = useState(
    busIsPresent === undefined ? false : busIsPresent === 'true'
  );
  const [arrowDirection, setArrowDirection] = useState('away');

  const directionReading = 'kw_reading';

  useEffect(() => {
    subscribe(meterReadingsEventName, (e) => {
      setReadings(e.detail?.[ulid] || {});
    });
    return () => unsubscribe(meterReadingsEventName);
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedReading'.concat(ulid), selectedReading);
    let _online = false;
    if (Object.keys(readings).length !== 0) {
      _online =
        'read_at' in readings
          ? isOnline(parseInt(readings.read_at) * 1000)
          : true;
      if (selectedReading in readings)
        setDispenserReading(
          renderReading(
            selectedReading,
            setSelectedReading,
            readings,
            _online,
            timezone,
            isTabletOrMobile
          )
        );
      if (directionReading in readings) {
        if (readings[directionReading] > 0) {
          setArrowDirection('away');
        } else {
          setArrowDirection('into');
        }
      }
      if ('battery_soc' in readings) setBatterySOC(readings.battery_soc);
    }
    setOnline(_online);
  }, [readings, selectedReading]);

  const getBatteryCharge = () => {
    const cells = [];
    let className = 'bus-dispenser-soc-cell';
    if (!online) className += ' battery-soc-offline';

    for (let cell = 0; cell < 10; cell++) {
      if (batterySOC === null || batterySOC < cell * 10 + 1) {
        cells.push(
          <div className={`${className} battery-soc-cell-grey`} key={cell} />
        );
      } else {
        if (batterySOC <= 10) {
          cells.push(
            <div className={`${className} battery-soc-cell-red`} key={cell} />
          );
        } else if (batterySOC <= 20) {
          cells.push(
            <div
              className={`${className} battery-soc-cell-yellow`}
              key={cell}
            />
          );
        } else {
          cells.push(
            <div className={`${className} battery-soc-cell-full`} key={cell} />
          );
        }
      }
    }

    return <div>{cells}</div>;
  };

  function getReadingsColor() {
    if (Number.parseFloat(busDispenserReading) >= 0) {
      return <div className='positive-readings'>{busDispenserReading}</div>;
    } else {
      return <div className='negative-readings'>{busDispenserReading}</div>;
    }
  }

  const customStyle = {
    left: `${xaxis * 40}px`,
    top: `${yaxis * 40}px`
  };

  const offlineClass = (className) => {
    return !online ? className : '';
  };

  const chargingClass = (className) => {
    return bBusIsPresent ? className : '';
  };

  return (
    <div
      className='apparent-module-bus-dispenser'
      id={ulid}
      style={customStyle}
    >
      <div className={`bus-dispenser-name ${offlineClass('offline')}`}>
        {busDispenserName}
      </div>
      <div
        className={`bus-dispenser-hbar ${offlineClass(
          'offline-border-background'
        )} ${chargingClass('charging-background')}`}
      />
      <div
        className={`bus-dispenser-charger ${offlineClass(
          'offline-border-background'
        )} ${chargingClass('charging-background')}`}
      />
      <div
        className={`bus-dispenser-vbar ${offlineClass(
          'offline-border-background'
        )} ${chargingClass('charging-background')}`}
      />

      {bBusIsPresent && <ElectricBus busSOC={busSOC} />}
      <div className={`bus-dispenser-block ${offlineClass('offline-border')}`}>
        <div className='battery-soc'>
          {getBatteryCharge(online)}
          <div className='readings'>{getReadingsColor()}</div>
        </div>
      </div>
      <Arrow
        location={arrowLocation}
        direction={arrowDirection}
        component='busDispenser'
        online={online}
      />
    </div>
  );
};

BusDispenser.propTypes = {
  ulid: PropTypes.number,
  name: PropTypes.string,
  xaxis: PropTypes.number,
  yaxis: PropTypes.number,
  busIsPresent: PropTypes.bool,
  busSOC: PropTypes.number,
  arrowLocation: PropTypes.string,
  timezone: PropTypes.string,
  isTabletOrMobile: PropTypes.bool
};
