export const defaultLayout = {
  title: 'Default Layout',
  layout: [
    { component: 'Solar', props: { xaxis: 2, yaxis: 2, name: 'Solar' } },
    {
      component: 'HorizontalConnector',
      props: { xaxis: 5, yaxis: 3, length: 10 }
    },
    {
      component: 'VerticalConnector',
      props: { xaxis: 7, yaxis: 3.1, length: 4 }
    },
    { component: 'Battery', props: { xaxis: 3, yaxis: 5, name: 'Battery' } },
    {
      component: 'HorizontalConnector',
      props: { xaxis: 5, yaxis: 7, length: 2.25 }
    },
    { component: 'Meter', props: { xaxis: 10, yaxis: 1, name: 'PCC' } },
    { component: 'PowerGrid', props: { xaxis: 15, yaxis: 1 } }
  ]
};
export const batteryOnlyLayout = {
  title: 'Battery Only Layout',
  layout: [
    {
      component: 'HorizontalConnector',
      props: { xaxis: 5, yaxis: 3, length: 10 }
    },
    { component: 'Battery', props: { xaxis: 3, yaxis: 1, name: 'Battery' } },
    { component: 'Meter', props: { xaxis: 10, yaxis: 1, name: 'PCC' } },
    { component: 'PowerGrid', props: { xaxis: 15, yaxis: 1 } }
  ]
};
export const solarOnlyLayout = {
  title: 'Solar Only Layout',
  layout: [
    { component: 'Solar', props: { xaxis: 2, yaxis: 2, name: 'Solar' } },
    {
      component: 'HorizontalConnector',
      props: { xaxis: 5, yaxis: 3, length: 10 }
    },
    // {
    //   component: 'VerticalConnector',
    //   props: { xaxis: 7, yaxis: 3.1, length: 4 }
    // },
    // {
    //   component: 'HorizontalConnector',
    //   props: { xaxis: 5, yaxis: 7, length: 2.25 }
    // },
    { component: 'Meter', props: { xaxis: 10, yaxis: 1, name: 'PCC' } },
    { component: 'PowerGrid', props: { xaxis: 15, yaxis: 1 } }
  ]
};
export const gridOnlyLayout = {
  title: 'Grid Only Layout',
  layout: [
    {
      component: 'HorizontalConnector',
      props: { xaxis: 3, yaxis: 3, length: 7 }
    },
    { component: 'Meter', props: { xaxis: 1, yaxis: 1, name: 'PCC' } },
    { component: 'PowerGrid', props: { xaxis: 10, yaxis: 1 } }
  ]
};
